// import { View, Text } from 'react-native'
// import React from 'react'

// const ClientFacility = () => {
//   return (
//     <View>
//       <Text>ClientFacility</Text>
//     </View>
//   )
// }

// export default ClientFacility
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ShowToast } from "../../../../helpers/ToastService";
import {
  Clinicrequestlist,
  FetchParactice,
  PatientAddTeam,
  PatientgetTeam,
} from "../../../../api/helper";
import { useSelector } from "react-redux";
import { DropDownManuCustom } from "../../../Components/DropDownButton";

const validationSchema = Yup.object().shape({
  facility: Yup.string().required("Facility is required"),
    practice: Yup.string().required("Practice is required"),
  
  fromDate: Yup.date()
    .typeError("Please select a valid From Date")
    .required("From Date is required"),
  toDate: Yup.date()
    .typeError("Please select a valid To Date")
    .min(Yup.ref("fromDate"), "To Date cannot be before From Date")
    .required("To Date is required"),
  lastSeenDate: Yup.date()
    .typeError("Please select a valid Last Seen Date")
    .required("Last Seen Date is required"),
});

const ClientFacility = ({ info, closeModal, Fetchteammember }) => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [clinicdata, setClinicData] = useState([]);
    const [paracticedata, setparacticedata] = useState([]);
  
  const getClinician = async () => {
    try {
      const response = await Clinicrequestlist();
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.companyName} / ${e.uniqueId}`,
          ...e,
        };
      });
      setClinicData(data || []);
    } catch (error) {
      console.log(error);
    }
  };
    const GetPractice = async () => {
      const response = await FetchParactice();
      // console.log(response?.data?.data?.data, "data=====");
      //
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.facilityName}`,
          ...e,
        };
      });
      setparacticedata(data || []);
    };
  useEffect(() => {
    GetPractice()
    getClinician();
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      facility: profiledata?.firstName + " " + profiledata?.lastName || "", // Set the default value here
    },
  });

  const onSubmit = async (data) => {
    data.agencyId = info?._id;
    data.memberType = "facility";
    console.log("Submitted Data:", data);
    try {
      const response = await PatientAddTeam(data);
      // console.log(response?.data, "response======facility---");
      ShowToast(response?.data?.message);
      reset();
      Fetchteammember();
      closeModal();
    } catch (error) {
      console.log(error);
      closeModal();
    }
    closeModal();
  };

  return (
    <div id="facility" className="tab-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className="form-group">
          <label htmlFor="facility-search-facility">Facility</label>
          <input
            type="search"
            id="facility-search-facility"
            placeholder="Search Facility"
            {...register("facility")}
            disabled
          />
          {errors.facility && (
            <p className="error-message">{errors.facility.message}</p>
          )}
        </div> */}
        <div className="form-group">
              
               <DropDownManuCustom
                 label="Practice"
                 placeholder="Select option"
                 options={paracticedata}
                 name="practice"
                 dropdownicon
                 register={register}
                 id="facilityName"
                 clearErrors={clearErrors}
                 divstyle={{ marginTop: -5 }}
               />
               {errors.practice && (
                 <p className="error-message">{errors.practice.message}</p>
               )}
               </div>
        <div className="form-group">
          <label htmlFor="from-date-facility">From Date</label>
          <input
            type="date"
            id="from-date-facility"
            {...register("fromDate")}
          />
          {errors.fromDate && (
            <p className="error-message">{errors.fromDate.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="to-date-facility">To Date</label>
          <input type="date" id="to-date-facility" {...register("toDate")} />
          {errors.toDate && (
            <p className="error-message">{errors.toDate.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="lastseen-date-facility">Last Seen Date</label>
          <input
            type="date"
            id="lastseen-date-facility"
            {...register("lastSeenDate")}
          />
          {errors.lastSeenDate && (
            <p className="error-message">{errors.lastSeenDate.message}</p>
          )}
        </div>
        <div className="form-buttons">
          <button className="cancel" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="save" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientFacility;
