import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  DeleteAuthorizations,
  GETAuthorizations,
  PostAuthorizations,
} from "../../../../../api/helper";
import { ShowToast } from "../../../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  Auth: Yup.string().required("Authorization number is required."),
  ServiceCode: Yup.string().required("Service code is required."),
  ServiceDescription: Yup.string().required("Service description is required."),
  AuthorizationStatus: Yup.string().required(
    "Authorization status is required."
  ),
  DateApproved: Yup.string().required("Date approved is required."),
  EffectiveStart: Yup.string().required("Effective start date is required."),
  EffectiveEnd: Yup.date()
    .nullable()
    .required("Effective end date is required."),
  UnitsPer: Yup.number()
    .positive("Units per must be a positive number.")
    .required("Units per is required."),
  UnitOfMeasure: Yup.string().required("Unit of measure is required."),
  UnitRate: Yup.string().required("Unit rate is required."),
  MaxUnits: Yup.number()
    .positive("Max units must be a positive number.")
    .required("Max units is required."),
  AmountApproved: Yup.string().required("Amount approved is required."),
});

const AuthorizationsScreen = ({ info }) => {
  const [authorization, setauthorization] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [modalopen, setmodalopen] = useState(false);

  // console.log(authorization,"authorization===")
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    // console.log("Form Data:", data);
    data.agencyId = profiledata?._id;
    data.patientId = info?.patientId?._id;
    try {
      const response = await PostAuthorizations(data);
      ShowToast(response?.data?.message);
      console.log(response?.data?.data);
      getinsurencedetails();
      setmodalopen(false);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const getinsurencedetails = async () => {
    try {
      const response = await GETAuthorizations(profiledata?._id);
      // console.log(response?.data?.data?.data, "hello=====");
      setauthorization(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getinsurencedetails();
  }, []);
  const handleRemove = async (id) => {
    if (window.confirm("Are you sure you want to remove?")) {
      console.log("Removed insurance with ID:", id);
      try {
        const response = await DeleteAuthorizations(id);
        ShowToast(response?.data?.message);
        getinsurencedetails();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {/* <div id="authorizations" className="tabcontent">
      <h3>Authorization Details</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group">
          <label htmlFor="Auth">Auth #[Box 23]</label>
          <input
            type="text"
            id="Auth"
            name="Auth"
            placeholder="Enter authorization number"
            {...register("Auth")}
          />
          {errors.Auth && <p className="error">{errors.Auth.message}</p>}
        </div>

        <div className="input-group">
          <label htmlFor="ServiceCode">Service Code</label>
          <input
            type="text"
            id="ServiceCode"
            name="ServiceCode"
            placeholder="Enter service code"
            {...register("ServiceCode")}
          />
          {errors.ServiceCode && (
            <p className="error">{errors.ServiceCode.message}</p>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="ServiceDescription">Service Description</label>
          <input
            type="text"
            id="ServiceDescription"
            name="ServiceDescription"
            placeholder="Enter service description"
            {...register("ServiceDescription")}
          />
          {errors.ServiceDescription && (
            <p className="error">{errors.ServiceDescription.message}</p>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="AuthorizationStatus">Authorization Status</label>
          <select
            id="AuthorizationStatus"
            name="AuthorizationStatus"
            {...register("AuthorizationStatus")}
          >
            <option value="" disabled>
              Select status
            </option>
            <option value="approved">Approved</option>
            <option value="pending">Pending</option>
            <option value="denied">Denied</option>
          </select>
          {errors.AuthorizationStatus && (
            <p className="error">{errors.AuthorizationStatus.message}</p>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="DateApproved">Date Approved</label>
          <input
            type="date"
            id="DateApproved"
            name="DateApproved"
            {...register("DateApproved")}
          />
          {errors.DateApproved && (
            <p className="error">{errors.DateApproved.message}</p>
          )}
        </div>

        <div className="row">
          <div className="input-group">
            <label htmlFor="EffectiveStart">Effective Start</label>
            <input
              type="date"
              id="EffectiveStart"
              name="EffectiveStart"
              {...register("EffectiveStart")}
            />
            {errors.EffectiveStart && (
              <p className="error">{errors.EffectiveStart.message}</p>
            )}
          </div>

          <div className="input-group">
            <label htmlFor="EffectiveEnd">Effective End</label>
            <input
              type="date"
              id="EffectiveEnd"
              name="EffectiveEnd"
              {...register("EffectiveEnd")}
            />
            {errors.EffectiveEnd && (
              <p className="error">{errors.EffectiveEnd.message}</p>
            )}
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="UnitsPer">
            Units Per
            <span className="tooltip">
              ?
              <span className="tooltip-text">
                Number of units allocated per time period (e.g., 1 unit per
                hour).
              </span>
            </span>
          </label>
          <input
            type="number"
            id="UnitsPer"
            name="UnitsPer"
            placeholder="e.g., 1.00"
            {...register("UnitsPer")}
          />
          {errors.UnitsPer && (
            <p className="error">{errors.UnitsPer.message}</p>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="UnitOfMeasure">
            Unit of Measure
            <span className="tooltip">
              ?
              <span className="tooltip-text">
                Measurement type for units (e.g., Hour, Day, Month).
              </span>
            </span>
          </label>
          <select
            id="UnitOfMeasure"
            name="UnitOfMeasure"
            {...register("UnitOfMeasure")}
          >
            <option value="" disabled>
              Select unit of measure
            </option>
            <option value="quarter_hour">Quarter-Hour</option>
            <option value="hour">Hour</option>
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </select>
          {errors.UnitOfMeasure && (
            <p className="error">{errors.UnitOfMeasure.message}</p>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="UnitRate">Unit Rate</label>
          <input
            type="text"
            id="UnitRate"
            name="UnitRate"
            placeholder="e.g., $2,440.65"
            {...register("UnitRate")}
          />
          {errors.UnitRate && (
            <p className="error">{errors.UnitRate.message}</p>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="MaxUnits">Max Units</label>
          <input
            type="number"
            id="MaxUnits"
            name="MaxUnits"
            placeholder="e.g., 12"
            {...register("MaxUnits")}
          />
          {errors.MaxUnits && (
            <p className="error">{errors.MaxUnits.message}</p>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="AmountApproved">Amount Approved</label>
          <input
            type="text"
            id="AmountApproved"
            name="AmountApproved"
            placeholder="e.g., $29,287.80"
            {...register("AmountApproved")}
          />
          {errors.AmountApproved && (
            <p className="error">{errors.AmountApproved.message}</p>
          )}
        </div>

        <div className="buttons">
          <button type="submit" className="save-button">
            Save Authorization
          </button>
        </div>
      </form>
      <div style={styles.container}>
        {authorization.map((item) => (
          <div key={item._id} style={styles.card}>
            <div style={styles.content}>
              <h3 style={styles.title}>Authorization Number: {item.Auth}</h3>
              <p style={styles.text}>
                <strong>Service Code:</strong> {item.ServiceCode}
              </p>
              <p style={styles.text}>
                <strong>Service Description:</strong> {item.ServiceDescription}
              </p>
              <p style={styles.text}>
                <strong>Status:</strong> {item.AuthorizationStatus}
              </p>
              <p style={styles.text}>
                <strong>Date Approved:</strong>{" "}
                {new Date(item.DateApproved).toLocaleDateString()}
              </p>
              <p style={styles.text}>
                <strong>Effective Start:</strong>{" "}
                {new Date(item.EffectiveStart).toLocaleDateString()}
              </p>
              <p style={styles.text}>
                <strong>Effective End:</strong>{" "}
                {new Date(item.EffectiveEnd).toLocaleDateString()}
              </p>
              <p style={styles.text}>
                <strong>Units Per:</strong> {item.UnitsPer}
              </p>
              <p style={styles.text}>
                <strong>Unit of Measure:</strong> {item.UnitOfMeasure}
              </p>
              <p style={styles.text}>
                <strong>Unit Rate:</strong> ${item.UnitRate}
              </p>
              <p style={styles.text}>
                <strong>Max Units:</strong> {item.MaxUnits}
              </p>
              <p style={styles.text}>
                <strong>Amount Approved:</strong> ${item.AmountApproved}
              </p>
            </div>
            <button
              style={styles.removeButton}
              onClick={() => handleRemove(item._id)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div> */}
      <>
        <button
          className="add-button_insuranceDetails"
          onClick={() => setmodalopen(true)}
          onclick="showModal()"
        >
          Add New Authorization
        </button>
        {modalopen && (
          <div
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              position: "fixed",
              zIndex: 1000,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Add a transparent background
            }}
          >
            <div className="modal_insuranceDetails">
              <div
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  position: "absolute",
                  right: 20,
                }}
                onClick={() => setmodalopen(false)}
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                  style={{ height: 20, width: 20 }}
                />
              </div>
              <h2>Authorization Details</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row_insuranceDetails">
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="auth-number">Auth # [Box 23]</label>
                    <input
                      type="text"
                      id="auth-number"
                      placeholder="Enter authorization number"
                      {...register("Auth")}
                    />
                    {errors.Auth && (
                      <p className="error">{errors.Auth.message}</p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="service-code">Service Code</label>
                    <input
                      type="text"
                      id="service-code"
                      placeholder="Enter service code"
                      {...register("ServiceCode")}
                    />
                    {errors.ServiceCode && (
                      <p className="error">{errors.ServiceCode.message}</p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="service-description">
                      Service Description
                    </label>
                    <input
                      type="text"
                      id="service-description"
                      placeholder="Enter service description"
                      {...register("ServiceDescription")}
                    />
                    {errors.ServiceDescription && (
                      <p className="error">
                        {errors.ServiceDescription.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-row_insuranceDetails">
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="authorization-status">
                      Authorization Status
                    </label>
                    <select
                      id="authorization-status"
                      {...register("AuthorizationStatus")}
                    >
                      <option value="Approved">Approved</option>
                      <option value="Pending">Pending</option>
                      <option value="Denied">Denied</option>
                    </select>
                    {errors.AuthorizationStatus && (
                      <p className="error">
                        {errors.AuthorizationStatus.message}
                      </p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="date-approved">Date Approved</label>
                    <input
                      type="date"
                      id="date-approved"
                      {...register("DateApproved")}
                    />
                    {errors.DateApproved && (
                      <p className="error">{errors.DateApproved.message}</p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="effective-start">Effective Start</label>
                    <input
                      type="date"
                      id="effective-start"
                      {...register("EffectiveStart")}
                    />
                    {errors.EffectiveStart && (
                      <p className="error">{errors.EffectiveStart.message}</p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="effective-end">Effective End</label>
                    <input
                      type="date"
                      id="effective-end"
                      {...register("EffectiveEnd")}
                    />
                    {errors.EffectiveEnd && (
                      <p className="error">{errors.EffectiveEnd.message}</p>
                    )}
                  </div>
                </div>
                <div className="form-row_insuranceDetails">
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="units-per">Units Per</label>
                    <input
                      type="text"
                      id="units-per"
                      placeholder="e.g., 1.00"
                      {...register("UnitsPer")}
                    />
                    {errors.UnitsPer && (
                      <p className="error">{errors.UnitsPer.message}</p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="unit-of-measure">Unit of Measure</label>
                    <select id="unit-of-measure" {...register("UnitOfMeasure")}>
                      <option value="Quarter-Hour">Quarter-Hour</option>
                      <option value="Hour">Hour</option>
                      <option value="Session">Session</option>
                    </select>
                    {errors.UnitOfMeasure && (
                      <p className="error">{errors.UnitOfMeasure.message}</p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="unit-rate">Unit Rate</label>
                    <input
                      type="text"
                      id="unit-rate"
                      placeholder="e.g., $2,440.65"
                      {...register("UnitRate")}
                    />
                    {errors.UnitRate && (
                      <p className="error">{errors.UnitRate.message}</p>
                    )}
                  </div>
                </div>
                <div className="form-row_insuranceDetails">
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="max-units">Max Units</label>
                    <input
                      type="text"
                      id="max-units"
                      placeholder="e.g., 12"
                      {...register("MaxUnits")}
                    />
                    {errors.MaxUnits && (
                      <p className="error">{errors.MaxUnits.message}</p>
                    )}
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="amount-approved">Amount Approved</label>
                    <input
                      type="text"
                      id="amount-approved"
                      placeholder="e.g., $29,287.80"
                      {...register("AmountApproved")}
                    />
                    {errors.AmountApproved && (
                      <p className="error">{errors.AmountApproved.message}</p>
                    )}
                  </div>
                </div>
                <button type="submit" onclick="saveAuthorization()">
                  Save Authorization
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="authorization-list">
          <h2>Authorization List</h2>
          <table>
            <thead>
              <tr>
                <th>Auth #</th>
                <th>Service Code</th>
                <th>Status</th>
                <th>Effective Start</th>
                <th>Effective End</th>
                <th>Units Per</th>
                <th>Unit Rate</th>
                <th>Max Units</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="insurance-table-body">
              {authorization.map((item) => (
                <tr key={item._id}>
                  <td>{item.Auth}</td>
                  <td>{item.ServiceCode}</td>
                  <td>{item.AuthorizationStatus}</td>
                  <td>{item.EffectiveStart}</td>
                  <td>{item.EffectiveEnd}</td>
                  <td>{item.UnitsPer}</td>
                  <td>{item.UnitRate}</td>
                  <td>{item.MaxUnits}</td>
                  <td>
                    <button className="check-eligibility_insuranceDetails">
                      Check Eligibility
                    </button>
                    <button
                      style={{ marginLeft: 10 }}
                      onClick={() => handleRemove(item._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </>
  );
};
const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    fontFamily: "'Arial', sans-serif",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
    // maxWidth: "400px",
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
    color: "#555",
    marginBottom: "5px",
  },
  removeButton: {
    backgroundColor: "#ff4d4f",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "10px 15px",
    cursor: "pointer",
    fontWeight: "bold",
  },
};

export default AuthorizationsScreen;
