import React from 'react'

function SOAPPsychiatricNote({ setOpenModel }) {
    return (
        <div className="modal-container_Soap">
            <div className="modal-header_Soap">SOAP Psychiatric Note</div>
            <div className="section_Soap">
                <div className="section-header_Soap" style={{ textAlign: 'left' }}>Patient Information</div>
                <div className="section-content_Soap">
                    <label htmlFor="patient-name">Patient Name</label>
                    <input
                        type="text"
                        id="patient-name"
                        defaultValue="John Doe"
                        readOnly=""
                    />
                    <label htmlFor="dob">Date of Birth</label>
                    <input type="date" id="dob" defaultValue="1985-06-15" readOnly="" />
                    <label htmlFor="visit-date">Date of Visit</label>
                    <input
                        type="date"
                        id="visit-date"
                        defaultValue="2025-01-21"
                        readOnly=""
                    />
                    <label htmlFor="clinician-name">Clinician Name</label>
                    <input
                        type="text"
                        id="clinician-name"
                        defaultValue="Dr. Jane Smith"
                        readOnly=""
                    />
                </div>
            </div>
            <div className="section_Soap">
                <div className="section-header_Soap" style={{ textAlign: 'left' }}>Subjective</div>
                <textarea
                    id="subjective"
                    placeholder="Enter the patient's subjective complaints or experiences..."
                    defaultValue={""}
                />
            </div>
            <div className="section_Soap">
                <div className="section-header_Soap" style={{ textAlign: 'left' }}>Objective</div>
                <textarea
                    id="objective"
                    placeholder="Enter objective observations, physical exams, or test results..."
                    defaultValue={""}
                />
            </div>
            <div className="section_Soap">
                <div className="section-header_Soap" style={{ textAlign: 'left' }}>Assessment</div>
                <textarea
                    id="assessment"
                    placeholder="Enter the assessment of the patient's condition..."
                    defaultValue={""}
                />
            </div>
            <div className="section_Soap">
                <div className="section-header_Soap" style={{ textAlign: 'left' }}>Plan</div>
                <textarea
                    id="plan"
                    placeholder="Enter the treatment plan or next steps..."
                    defaultValue={""}
                />
            </div>
            <div className="section_Soap">
                <div className="section-header_Soap" style={{ textAlign: 'left' }}>Signature Confirmation</div>
                <div className="section-content_Soap">
                    <label>
                        <input type="checkbox" id="signature-confirmation" />
                        {" "}   By checking this box, I confirm that I have reviewed this document.
                    </label>
                </div>
            </div>
            <div className='divBtn'>
                <button className="button" onclick="saveNote()">
                    Save Note
                </button>

                <button className="buttonBack" onClick={() => setOpenModel(null)}>
                    Back
                </button>
            </div>
        </div>
    )
}

export default SOAPPsychiatricNote