import React, { useState } from 'react'

const PatientDocuments = () => {
      const [ismodal, setIsModal] = useState(false);
        const openModal = () => {
            setIsModal(true); 
          };
          const CloseModal = () => {
            setIsModal(false); 
          };
  return (
    <div className="container_PatientDocuments">
    <h1>Patient Documents</h1>
    <button className="add-button_PatientDocuments" onClick={openModal}>
      + Upload Document
    </button>
    {
        ismodal&&(
        <div className="modal_PatientDocuments" id="addDocumentModal">
      <h2>Add Patient Document</h2>
      <form >
        <div className="form-group_PatientDocuments">
          <label htmlFor="docName">Document Name</label>
          <input
            type="text"
            id="docName"
            placeholder="Enter document name"
            required=""
          />
        </div>
        <div className="form-group_PatientDocuments">
          <label htmlFor="docDate">Date</label>
          <input type="date" id="docDate" required="" />
        </div>
        <div className="form-group_PatientDocuments">
          <label htmlFor="docStatus">Status</label>
          <select id="docStatus" required="">
            <option value="New">New</option>
            <option value="Reviewed">Reviewed</option>
            <option value="Signed">Signed</option>
          </select>
        </div>
        <div className="form-group_PatientDocuments">
          <label htmlFor="docLabel">Label</label>
          <select id="docLabel" required="">
            <option value="Legal Document">Legal Document</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group_PatientDocuments">
          <label htmlFor="docNotes">Notes</label>
          <textarea
            id="docNotes"
            placeholder="Add any notes"
            rows={3}
            defaultValue={""}
          />
        </div>
        <div className="form-group_PatientDocuments">
          <label htmlFor="sentToPatient">Sent to Patient</label>
          <select id="sentToPatient" required="">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="form-group_PatientDocumentsv">
          <label htmlFor="docFile">Attach File</label>
          <input type="file" id="docFile" required="" />
        </div>
        <div className="modal-footer_PatientDocuments">
          <button type="button" className="cancel-button_PatientDocuments" onClick={CloseModal}>
            Cancel
          </button>
          <button type="submit" className="save-button_PatientDocuments" onClick={CloseModal}>
            Save
          </button>
        </div>
      </form>
    </div>
    )}
    <h2>System Generated Documents</h2>
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Status</th>
          <th>Label</th>
          <th>Notes</th>
          <th>Sent to Patient</th>
          <th>File</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody id="systemGeneratedTableBody">
      </tbody>
    </table>
    <h2>Uploaded Documents</h2>
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Status</th>
          <th>Label</th>
          <th>Notes</th>
          <th>Sent to Patient</th>
          <th>File</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody id="uploadedTableBody">{/* Uploaded Documents */}</tbody>
    </table>
    <h2>Patient Education Documents</h2>
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Status</th>
          <th>Label</th>
          <th>Notes</th>
          <th>Sent to Patient</th>
          <th>File</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody id="patientEducationTableBody">
        {/* Patient Education Documents */}
      </tbody>
    </table>
  </div>
  
  )
}

export default PatientDocuments