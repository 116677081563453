// import React from 'react'

// const ClientExternal = () => {
//   return (
//     <View>
//       <Text>ClientExternal</Text>
//     </View>
//   )
// }

// export default ClientExternal
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import {
  ClinicTeammembers,
  FacilityTeamembers,
  FetchParactice,
  GetProviderType,
  PatientAddTeam,
  PatientgetTeam,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { DropDownManuCustom } from "../../../Components/DropDownButton";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  practice: Yup.string().required("Practice is required"),
  providerType: Yup.string().required("Provider Type is required"),
  teamMember: Yup.string().required("Team Member is required"),
  fromDate: Yup.date()
    .typeError("Please select a valid From Date")
    .required("From Date is required"),
  toDate: Yup.date()
    .typeError("Please select a valid To Date")
    .min(Yup.ref("fromDate"), "To Date cannot be before From Date")
    .required("To Date is required"),
  lastSeenDate: Yup.date()
    .typeError("Please select a valid Last Seen Date")
    .required("Last Seen Date is required"),
});
const ClientExternal = ({ info, closeModal, Fetchteammember }) => {
  const [data, setdata] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [teammemberdata, setteammemberdata] = useState([]);
  const [paracticedata, setparacticedata] = useState([]);
  // console.log(paracticedata,"paracticedata=====")
   const GetTeamMember = async (facilityId) => {
     try {
       const response = await FacilityTeamembers();
//  console.log(response?.data?.data,"response?.data?.data=====")
       const data = response?.data?.data?.data?.map((e) => {
         return {
           name: `${e.memberName} `,
           ...e,
         };
       });
       // console.log(data,"========data=====")
       setteammemberdata(data || []);
     } catch (error) {
       console.log(error);
     }
   };

  const GetAppoientmenttype = async () => {
    const response = await GetProviderType(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
  const GetPractice = async () => {
    const response = await FetchParactice();
    // console.log(response?.data?.data?.data, "data=====");
    //
    const data = response?.data?.data?.data?.map((e) => {
      return {
        name: `${e.facilityName}`,
        ...e,
      };
    });
    setparacticedata(data || []);
  };
  useEffect(() => {
    GetPractice();
    GetAppoientmenttype();
    GetTeamMember();
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    data.agencyId = info?._id;
    data.memberType = "facility";
    console.log("Submitted Data:", data);
    try {
      const response = await PatientAddTeam(data);
      // console.log(response?.data, "response======facility---");
      ShowToast(response?.data?.message);
      reset();
      Fetchteammember();
      closeModal();
    } catch (error) {
      console.log(error);
      closeModal();
    }
    closeModal();
  };

  // const Fetchteammember = async () => {
  //   try {
  //     const response = await PatientgetTeam(info?._id);
  //     // console.log(response?.data?.data?.TeamMember, "get======facility---");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   Fetchteammember();
  // }, []);
  return (
    <div id="external" className="tab-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
         
          <DropDownManuCustom
            label="Practice"
            placeholder="Select option"
            options={paracticedata}
            name="practice"
            dropdownicon
            register={register}
            id="facilityName"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
          />
          {/*  */}
          {errors.practice && (
            <p className="error-message">{errors.practice.message}</p>
          )}
        </div>
        <div className="form-group">
          {/* <label htmlFor="provider-type-search-external">Provider Type</label>
          <input
            type="search"
            id="provider-type-search-external"
            placeholder="Search Provider Type"
            {...register("providerType")}
          />
          {errors.providerType && (
            <p className="error-message">{errors.providerType.message}</p>
          )} */}
          <DropDownManuCustom
            label="Provider Type"
            placeholder="Select option"
            options={data}
            name="providerType"
            dropdownicon
            register={register}
            id="name"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
          />
          {errors.providerType && (
            <p className="error-message">{errors.providerType.message}</p>
          )}
        </div>
        <div className="form-group">
          {/* <label htmlFor="team-member-search-external">Team Member</label>
          <input
            type="search"
            id="team-member-search-external"
            placeholder="Search Team Member"
            {...register("teamMember")}
          /> */}
          <DropDownManuCustom
            label="Team Member"
            placeholder="Select option"
            options={teammemberdata}
            name="teamMember"
            dropdownicon
            register={register}
            id="name"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
          />
          {errors.teamMember && (
            <p className="error-message">{errors.teamMember.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="from-date-external">From Date</label>
          <input
            type="date"
            id="from-date-external"
            {...register("fromDate")}
          />
          {errors.fromDate && (
            <p className="error-message">{errors.fromDate.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="to-date-external">To Date</label>
          <input type="date" id="to-date-external" {...register("toDate")} />
          {errors.toDate && (
            <p className="error-message">{errors.toDate.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="lastseen-date-external">Last Seen Date</label>
          <input
            type="date"
            id="lastseen-date-external"
            {...register("lastSeenDate")}
          />
          {errors.lastSeenDate && (
            <p className="error-message">{errors.lastSeenDate.message}</p>
          )}
        </div>
        <div className="form-buttons">
          <button className="cancel" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="save" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientExternal;
