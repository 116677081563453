import React from 'react'
import SOAPPsychiatricNote from './notes/SOAPPsychiatricNote'
import InitialPsychiatricIntakeNote from './notes/InitialPsychiatricIntakeNote'
import MedicationManagementNote from './notes/MedicationManagementNote'
import CrisisInterventionNote from './notes/CrisisInterventionNote'
import DischargeSummary from './notes/DischargeSummary'

const ProblemsScreen = () => {
  return (
    <>
      <InitialPsychiatricIntakeNote />
      <SOAPPsychiatricNote />
      <MedicationManagementNote />
      <CrisisInterventionNote />
      <DischargeSummary />

    </>

  )
}

export default ProblemsScreen