import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddInsurance,
  AddProviderType,
  DeleteInsurance,
  DeleteProviderType,
  EditInsurance,
  GetAdminInsurence,
  GetInsurance,
  uploadImage,
} from "../../../api/helper";
import { Box, Modal } from "@mui/material";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ShowToast } from "../../../helpers/ToastService";
import { DropDownManuCustom } from "../../Components/DropDownButton";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

const validationSchema = Yup.object().shape({
  // adminInsurenceId: Yup.string().required("Insurance Name is required"),
  InsuranceType: Yup.string().required("Insurance Type is required"),
  InsuranceAddress: Yup.string().required("Insurance Address is required"),
  ContactNumber: Yup.string()
    .required("Contact Number is required")
    .matches(/^[0-9]+$/, "Contact Number must be numeric"),
  Email: Yup.string()
    .required("Email is required")
    .email("Invalid Email format"),
  CPID: Yup.string().required("CPID is required"),
  PayerID: Yup.string().required("Payer ID is required"),
});

const InsuranceTypedata = [
  { id: "Medicare", name: "Medicare" },
  { id: "Medicaid", name: "Medicaid" },
  { id: "Tricare(Champus)", name: "Tricare(Champus)" },
  { id: "Champva", name: "Champva" },
  { id: "Group Health Plan", name: "Group Health Plan" },
  {
    id: "FCCA(Federal Employee Compensation Act)",
    name: "FCCA(Federal Employee Compensation Act)",
  },
  { id: "Other", name: "Other" },
];

const InsuranceScreen = () => {
  const [insurance, setInsurance] = useState([]);
  const [insuranceNameModal, setfirstInsuranceNameModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inNetwork, setInNetwork] = useState(false);
  const [claims, setClaims] = useState(false);
  const [eligibility, setEligibility] = useState(false);
  const [eras, setEras] = useState(false);
  const [defType, setDefType] = useState();
  const [insuranceName, setInsuranceName] = useState();
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [isEditing, setisEditing] = useState(false);
  const [addressAutocomplete, setAddressAutocomplete] = useState(null);
  const [rowid, setrowid] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [enrollmodal, setenrollmodal] = useState(false);
  const fileInputRef = useRef(null);
  const backfileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImages] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [ClaimStatus, setClaimStatus] = useState(false)
  const [attachments, setAttachments] = useState(false)

  const [adminInsurence, setAdminInsurence] = useState({
    data: [],
    pagination: { totalPages: 0 },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;
  // const [frontImage, setFrontImage] = useState(null);

  // console.log(frontImage,"frontImage=====")

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setFrontImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const clickbackImageData = () => {
    if (backfileInputRef.current) {
      backfileInputRef.current.click();
    }
  };
  // backfileInputRef
  const backimagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setBackImage(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };
  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM",
    libraries: ["places"],
  });
  const handleAddressSelection = () => {
    const selectedPlace = addressAutocomplete.getPlace();
    console.log(selectedPlace, "selectedPlace===========>");

    if (selectedPlace.formatted_address) {
      // Set the selected address to the form field using setValue
      setValue("InsuranceAddress", selectedPlace.formatted_address);
    }
  };

  const getAdminInsurence = async (search = "", page = 1) => {
    try {
      const response = await GetAdminInsurence({ search, page, pageSize });
      console.log(response.data?.data, "===>>> Response Data");
      if (response?.data?.data) {
        setAdminInsurence({
          data: response.data.data,
          pagination: response.data.data.pagination || { totalPages: 0 }, // Safeguard
        });
      } else {
        console.error("Unexpected API response structure");
        setAdminInsurence({ data: [], pagination: { totalPages: 0 } });
      }
    } catch (error) {
      console.error("Error fetching admin insurance data", error);
      setAdminInsurence({ data: [], pagination: { totalPages: 0 } });
    }
  };
  useEffect(() => {
    getAdminInsurence(searchTerm, currentPage);
  }, [searchTerm, currentPage]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    getAdminInsurence(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAdminInsurence(searchTerm, page);
  };

  const Getinsurancedata = async () => {
    const response = await GetInsurance(`?id=${profiledata?._id}`);
    setInsurance(response?.data?.data);
  };
  useEffect(() => {
    Getinsurancedata();
  }, []);

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    reset();
    setInsuranceName();
    setValue("CPID", "");
    setValue("PayerID", "");
  };

  const openinsuranceModal = () => setfirstInsuranceNameModal(true);
  const closeinsuranceModal = () => setfirstInsuranceNameModal(false);
  const openenrolleModal= (insuranceData) => {
console.log(insuranceData,"insuranceData=====>>")
    setSelectedInsurance(insuranceData);
    setClaims(insuranceData?.Claims === "true" || insuranceData?.adminInsurenceId?.Claims === "Yes");
    setEligibility(
      insuranceData?.Eligibility === "true" || insuranceData?.adminInsurenceId?.Eligibility === "Yes"
    );
    setClaimStatus(
      insuranceData?.ClaimStatus === "true" || insuranceData?.adminInsurenceId?.ClaimStatus === "Yes"
    );
    setEras(insuranceData?.ERAs === "true" || insuranceData?.adminInsurenceId?.ERAs === "Yes");
    setAttachments(
      insuranceData?.attachments === "true" || insuranceData?.adminInsurenceId?.attachments === "Yes"
    );
    setenrollmodal(true);
  };
  const closeenrollModal = () => setenrollmodal(false);

  const handelChange = (data) => {
    // console.log(data,"handelChange======")
    setInsuranceName(data);
    onSelect(data);
    closeinsuranceModal();
    setDefType();
    // setisEditing(false)
  };
  const handleEdit = (row) => {
    console.log(row, "hello======row=====");
    setSelectedInsurance(row);
    setrowid(row._id);
    setisEditing(true);
    setDefType(row.InsuranceType);
    setValue("InsuranceAddress", row.InsuranceAddress || "");
    setValue("ContactNumber", row.ContactNumber || "");
    setValue("Email", row.Email || "");
    setInNetwork(row.InNetwork === "true");
    setEras(row.ERAs === "true");
    setValue("CPID", row.CPID || "");
    setValue("PayerID", row.PayerID || "");
    setInsuranceName(row.adminInsurenceId);
    setShowModal(true);
  };
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  // const response = await EditInsurance(data);
    const submitEnrollment = async () => {
      const updatedData = {
        Claims:String(claims),
        Eligibility: String(eligibility),
        ClaimStatus:String(ClaimStatus),
        ERAs:String(eras),
        attachments:String(attachments),
        adminInsurenceId: insuranceName?._id,
        agencyId : profiledata?._id
      };
      console.log(updatedData,"updatedData===enrol===")
    // return
      try {
        // Call the API to edit insurance data
        const response = await EditInsurance(updatedData, selectedInsurance._id);
        console.log("Updated Insurance Data:", response?.data?.data);
        ShowToast(response?.data?.message);
        Getinsurancedata();
        closeenrollModal()
        // Optionally, refresh the insurance list here
      } catch (error) {
        console.error("Error updating insurance data:", error);
      }
    };
  const onSubmit = async (data) => {
    if (!insuranceName?._id) {
      ShowToast("Please Select Insurance Name");
      return;
    }
    // if (!image || !backImage) {
    //   ShowToast("Please upload both front and back images");
    //   return;
    // }
    // data.image = {
    //   front: image,
    //   back: backImage,
    // }
    data.agencyId = profiledata?._id;
    data.InNetwork = String(inNetwork);
    data.Claims = String(claims);
    data.Eligibility = String(eligibility);
    data.ERAs = String(eras);
    data.adminInsurenceId = insuranceName?._id;
    console.log(data, "submitdata-==========????")
    // return
    try {
      let response;
      if (isEditing) {
        // Check if editing
        response = await EditInsurance(data, rowid); // Call EditInsurance
      } else {
        response = await AddInsurance(data); // Call AddInsurance
      }

      console.log(response?.data?.data, "response====");
      ShowToast(response?.data?.message);
      setInsuranceName();
      setInNetwork(false);
      setEras(false);
      Getinsurancedata();
      setShowModal(false);
      setClaims(false);
      setDefType();
      reset();
      setValue("CPID", "");
      setValue("PayerID", "");
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    // console.log("Deleted ID:", id);

    const confirmed = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await DeleteInsurance(`?id=${id}`);
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        Getinsurancedata();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };

  const onSelect = (data) => {
    setDefType(data.type);
    reset({
      CPID: data.CHCPayerID || "",
      PayerID: data.PayerID || "",
      // data.Eligibility
    });
    setEligibility(data.Eligibility === "Yes");
    setClaims(data.Claims === "Yes");
  };
  // console.log(eligibility, "eligibility===");
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  // console.log(insurance, "insuranceNamee====");
  if (!adminInsurence || !adminInsurence.data) {
    return <div>Loading...</div>;
  }
  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Insurance</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
          }}
          onClick={openModal}
        >
          Add Insurance
        </div>
      </div>

      <div style={{ width: "100%", marginTop: 30 }}>
      <table className="table" id="member-table">
          <thead>
            <tr>
              <th>Insurance Name </th>
              <th>Insurance Type </th>
              <th>In Network </th>
              {/* <th>Contact Number </th> */}
              <th>Claims</th>

              <th>Eligibility</th>
              <th>ERAs</th>
              <th>CPID</th>
              <th>Payer ID </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {insurance?.length === 0 ? (
              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  No Insurance Found
                </td>
              </tr>
            ) : (
              insurance?.map((row) => (
                <tr key={row._id} style={tableRowStyle}>
                  <td style={tableCellStyle}>
                    {row.adminInsurenceId
                      ? row.adminInsurenceId.PayerName
                      : row?.PayerName}
                  </td>
                  <td style={tableCellStyle}>
                    {row?.InsuranceType ? row.InsuranceType : row.description}
                  </td>
                  <td style={tableCellStyle}>
                    {row?.InNetwork === "true"
                      ? "Yes"
                      : row?.InNetwork === "false"
                        ? "No"
                        : "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row?.Claims === "true"
                      ? "Yes"
                      : row?.Claims === "false"
                        ? "No"
                        : "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row?.Eligibility === "true"
                      ? "Yes"
                      : row?.Eligibility === "false"
                        ? "No"
                        : "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row?.ERAs === "true"
                      ? "Yes"
                      : row?.ERAs === "false"
                        ? "No"
                        : "Not Available"}
                  </td>

                  <td style={tableCellStyle}>
                    {row?.CPID ? row.CPID : "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row?.PayerID ? row?.PayerID : "Not Available"}
                  </td>

                  <td style={tableCellStyle}>
                    <button
                      style={editButtonStyle}
                      onClick={() => handleEdit(row)}
                    >
                      Edit
                    </button>

                    {row?.Eligibility === "true" && (
                      <button
                        style={EnorllButtonStyle}
                        onClick={() => openenrolleModal(row)}
                      >
                        Enroll
                      </button>
                    )}
                    <button
                      style={{
                        ...deleteButtonStyle,
                        marginTop: row?.Eligibility === "true" ? 10 : 0,
                      }}
                      onClick={() => handleDelete(row._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div
          style={{
            position: "absolute",
            top: "0.1%",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",

            height: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              width: "600px",
              maxHeight: "90vh", // Maximum height for the modal content
              overflowY: "auto", // Enable scrolling if content overflows
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: "100%",
                alignItems: "center",
                justifyItems: "center",
                marginBottom: 20,
              }}
            >
              <h1 style={{ textAlign: "left" }}>New Insurance</h1>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div onClick={openinsuranceModal}>
                  <label>{"Insurance Name"}</label>
                  <div
                    style={{
                      border: "1px solid grey",
                      width: "100%",
                      height: 43,
                      borderRadius: 5,
                      paddingLeft: 10,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {insuranceName?.PayerName || "Select Insurance Name"}
                  </div>
                </div>
                {errors.adminInsurenceId && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.adminInsurenceId.message}
                  </p>
                )}
                <DropDownManuCustom
                  label="Insurance Type"
                  placeholder="Select option"
                  options={InsuranceTypedata}
                  name="InsuranceType"
                  dropdownicon
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  defaultValue={defType}
                />
                {errors.InsuranceType && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.InsuranceType.message}
                  </p>
                )}
                <Autocomplete
                  onLoad={(autocompleteInstance) =>
                    setAddressAutocomplete(autocompleteInstance)
                  }
                  onPlaceChanged={handleAddressSelection}
                >
                  <Inputbox
                    label="Insurance Address"
                    type="text"
                    placeholder="Enter Insurance Address"
                    name="InsuranceAddress"
                    id="InsuranceAddress"
                    // You don't need `value` and `onChange` here since Formik will handle the state
                    register={register}
                  />
                </Autocomplete>

                {errors.InsuranceAddress && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.InsuranceAddress.message}
                  </p>
                )}

                <Inputbox
                  label="Contact Number"
                  type="text"
                  placeholder="Enter Contact Number"
                  name="ContactNumber"
                  className="Custom_input"
                  register={register}
                />
                {errors.ContactNumber && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.ContactNumber.message}
                  </p>
                )}

                <Inputbox
                  label="Email"
                  type="email"
                  placeholder="Enter Email"
                  name="Email"
                  className="Custom_input"
                  register={register}
                />
                {errors.Email && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.Email.message}
                  </p>
                )}

                <div
                  className="form-group_two"
                  style={{
                    marginTop: 20,
                    marginBottom: 0,
                    justifyContent: "space-between",
                  }}
                >
                  <label style={{ width: 450 }}>
                    In Network (Check if this agency is in-network with this
                    insurance)
                  </label>
                  <input
                    type="checkbox"
                    id="inNetwork"
                    checked={inNetwork}
                    onChange={(e) => setInNetwork(e.target.checked)}
                    className="form-checkbox"
                  />
                </div>

                {/* <Inputbox
                  label="CPID"
                  type="text"
                  placeholder="Enter CPID"
                  name="CPID"
                  className="Custom_input"
                  register={register}
                  disabled
                />
                {errors.CPID && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.CPID.message}
                  </p>
                )}

                <Inputbox
                  label="Payer ID"
                  type="text"
                  placeholder="Enter Payer ID"
                  name="PayerID"
                  className="Custom_input"
                  register={register}
                  disabled
                />
                {errors.PayerID && (
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.PayerID.message}
                  </p>
                )} */}

                {/* <div className="checkbox-form">
                  <div className="form-group_two">
                    <label style={{ width: 550 }}>
                      Claims Allowed Automatically
                    </label>
                    <input
                      type="checkbox"
                      id="claims"
                      className="form-checkbox"
                      checked={claims}
                      onChange={(e) => setClaims(e.target.checked)}
                      disabled
                    />
                  </div>
                  <div className="form-group_two">
                    <label style={{ width: 550 }}>
                      Eligibility Allowed Automatically
                    </label>
                    <input
                      type="checkbox"
                      id="eligibility"
                      className="form-checkbox"
                      checked={eligibility}
                      onChange={(e) => setEligibility(e.target.checked)}
                      disabled
                    />
                  </div>
                  <div className="form-group_two">
                    <label style={{ width: 550 }}>
                      ERAs Allowed Automatically
                    </label>
                    <input
                      type="checkbox"
                      id="eras"
                      className="form-checkbox"
                      checked={eras}
                      onChange={(e) => setEras(e.target.checked)}
                    />
                  </div>
                </div> */}

                {/* <div className="insurance-card-container">
                  <h3 className="insurance-card-title">Insurance Card</h3>
                  <div className="card-upload-container">
                    <div className="upload-box"

                      onClick={clickImageData}
                    >
                      <label

                        className="upload-label"
                      >
                        {image ? (
                          <img
                            src={image}
                            alt="Uploaded Front"
                            className="uploaded-image"
                          />
                        ) : (
                          <div className="upload-icon" style={{ flexDirection: 'column', width: 100, height: 100 }}>
                            <img
                              src="https://www.veryicon.com/download/png/miscellaneous/linear/camera-265?s=256"
                              alt="Upload"
                              className="icon"
                              style={{ height: 40, width: 40 }}
                            />
                            <div className="upload-text">Front Side</div>
                          </div>
                        )}
                      </label>
                      <input
                        id="file-upload-front"
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={imagePicker}
                        style={{ display: "none" }}
                      />
                    </div>

                    <div className="upload-box" onClick={clickbackImageData} >
                      <label

                        className="upload-label"
                      >
                        {backImage ? (
                          <img
                            src={backImage}
                            alt="Uploaded Back"
                            className="uploaded-image"
                          />
                        ) : (
                          <div className="upload-icon" style={{ flexDirection: 'column', width: 100, height: 100 }}>
                            <img
                              src="https://www.veryicon.com/download/png/miscellaneous/linear/camera-265?s=256"
                              alt="Upload"
                              className="icon"
                              style={{ height: 40, width: 40 }}
                            />
                            <div className="upload-text">Back Side</div>
                          </div>
                        )}
                      </label>
                      <input
                        id="file-upload-back"
                        type="file"
                        accept="image/*"
                        ref={backfileInputRef}
                        onChange={backimagePicker}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div> */}
                <button
                  type="submit"
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    alignSelf: "center",
                    width: "100%",
                    height: "50px",
                  }}
                  className="Save_buttonnn"
                >
                  Submit
                </button>
              </div>
            </form>

            {/* Close button */}
            <button
              type="button"
              onClick={closeModal}
              style={{
                marginBottom: 20,
                marginTop: 4,
                alignSelf: "center",
                width: "100%",
                height: "50px",
                // backgroundColor: "red",
                color: "black",
                borderRadius: "8px",
                border: "none",
                cursor: "pointer",
                border: "1px solid grey",
              }}
            >
              Cancel
            </button>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <Modal
        open={enrollmodal}
        onClose={closeenrollModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalenrollStyle}>
          <div>
            <div className="checkbox-form">
              <div className="form-group_two">
                <label style={{ width: 350 }}>Claims</label>
                <input
                  type="checkbox"
                  id="claims"
                  className="form-checkbox"
                  checked={claims}
                  onChange={(e) => setClaims(e.target.checked)}
                  disabled
                />
              </div>
              <div className="form-group_two">
                <label style={{ width: 350 }}>Eligibility</label>
                <input
                  type="checkbox"
                  id="eligibility"
                  className="form-checkbox"
                  checked={eligibility}
                  onChange={(e) => setEligibility(e.target.checked)}
                  disabled
                />
              </div>
              <div className="form-group_two">
                <label style={{ width: 350 }}>ERAs</label>
                <input
                  type="checkbox"
                  id="eras"
                  className="form-checkbox"
                  checked={eras}
                  onChange={(e) => setEras(e.target.checked)}
                />
              </div>
              <div className="form-group_two">
                <label style={{ width: 350 }}>Claim Status</label>
                <input
                  type="checkbox"
                  id="eligibility"
                  className="form-checkbox"
                  checked={ClaimStatus}
                  onChange={(e) => setClaimStatus(e.target.checked)}
                />
              </div>
              <div className="form-group_two">
                <label style={{ width: 350 }}>attachments</label>
                <input
                  type="checkbox"
                  id="eras"
                  className="form-checkbox"
                  checked={attachments}
                  onChange={(e) => setAttachments(e.target.checked)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <button
  onClick={submitEnrollment}
                    style={{
                    // marginBottom: 20,
                    // marginTop: 20,
                    alignSelf: "center",
                    // width: "100%",
                    padding: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    backgroundColor: "#011434",
                  }}
                // className="Save_buttonnn"
                >
                  Submit Enrollment
                </button>
                <button
                  onClick={() => closeenrollModal()}
                  // type="submit"
                  style={{
                    // marginBottom: 20,
                    // marginTop: 4,
                    alignSelf: "center",
                    padding: "10px",
                    height: "50px",
                    // backgroundColor: "red",
                    color: "black",
                    borderRadius: "8px",
                    border: "none",
                    cursor: "pointer",
                    border: "1px solid grey",
                  }}
                // className="Save_buttonnn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={insuranceNameModal}
        onClose={closeinsuranceModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalinsuranceStyle}>
          <div>
            <Inputbox
              label={"Search Insurance Name"}
              placeholder={"Type to search..."}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            {/* Insurance Data List */}
            <div style={{ marginTop: 20 }}>
              {adminInsurence?.data?.data?.length > 0 ? (
                adminInsurence?.data?.data?.map((insurance) => (
                  <div
                    key={insurance._id}
                    style={{
                      border: "1px solid #ccc",
                      marginBottom: "10px",
                      borderRadius: 10,
                      padding: "10px",
                      width: "100%",
                    }}
                  >
                    <div onClick={() => handelChange(insurance)}>
                      <div style={{ fontSize: 20 }}>{insurance.PayerName}</div>
                      <div>
                        <strong>Payer ID:</strong> {insurance.PayerID}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No insurance data available.</p>
              )}
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Previous Button */}
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: currentPage === 1 ? "#ccc" : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                }}
              >
                Previous
              </button>

              <span
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #007BFF",
                }}
              >
                {currentPage} of {adminInsurence?.pagination?.totalPages}
              </span>

              {/* Next Button */}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage === adminInsurence?.pagination?.totalPages
                }
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor:
                    currentPage === adminInsurence?.pagination?.totalPages
                      ? "#ccc"
                      : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor:
                    currentPage === adminInsurence?.pagination?.totalPages
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default InsuranceScreen;

const modalenrollStyle = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: "50%",
  // height: "80vh",
  // bgcolor: "background.paper",
  // boxShadow: 24,
  // p: 4,
  // borderRadius: 4,
  // outline: "none",
  // overflowY: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90vh",
  overflowY: "auto", // Set a fixed height for the modal
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
};
const modalinsuranceStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto",
};


const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};
const buttontableCellStyle = {
  // width:'25%',
  borderBottom: "none",
  padding: "8px",
  display: "flex",
};
const tableRowStyle = {
  borderBottom: "1px solid #ddd",
  // width:'60%'
};
const editButtonStyle = {
  backgroundColor: "darkblue",
  marginLeft: 10,
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  marginLeft: 10,
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
const EnorllButtonStyle = {
  backgroundColor: "darkblue",
  marginLeft: 10,
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
