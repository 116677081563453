import React from "react";

const ConditionsScreen = ({ info }) => {
  return (
    <div id="conditions" className="tabcontent">
      <h3>Conditions of Admission</h3>

      <div className="checkbox-group">
        <label htmlFor="service">
          The patient condition is related to[Box 10a-c]:
        </label>
        <br />
        <div className="checkbox-options">
          <label>
            <input type="checkbox" name="service" defaultValue="pregnancy" />{" "}
            None
          </label>
          <br />
          <label>
            <input type="checkbox" name="service" defaultValue="epsdt" /> Auto
            Accident
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="service"
              defaultValue="family-planning"
            />{" "}
            Employment
          </label>
          <br />
          <label>
            <input type="checkbox" name="service" defaultValue="homebound" />{" "}
            Other Accident
          </label>
        </div>
      </div>
      <div className="checkbox-group">
        <label htmlFor="service">The service provided is related to:</label>
        <br />
        <div className="checkbox-options">
          <label>
            <input type="checkbox" name="service" defaultValue="pregnancy" />{" "}
            Pregnancy
          </label>
          <br />
          <label>
            <input type="checkbox" name="service" defaultValue="epsdt" /> EPSDT
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="service"
              defaultValue="family-planning"
            />{" "}
            Family Planning
          </label>
          <br />
          <label>
            <input type="checkbox" name="service" defaultValue="homebound" />{" "}
            Homebound
          </label>
          <br />
          <label>
            <input type="checkbox" name="service" defaultValue="emergency" /> An
            Emergency
          </label>
          <br />
        </div>
      </div>
      <div className="events-container">
        <label>Add events related to condition:</label>
        <div
          className="input-group"
          style={{
            width: "75%",
            display: "flex",
            // border: "1px solid red",
            justifyContent: "space-between",
            alignItems:'center'
          }}
        >
          <select name="dateType" className="" style={{ width: "58%" }}>
            <option value="">Select date type</option>
            <option value="initial-treatment">Initial Treatment Date</option>
            <option value="onset-symptoms">
              Onset of Current Symptoms or Illness (Date of Injury)
            </option>
            <option value="similar-illness">
              Date of Same or Similar Illness
            </option>
            <option value="unable-work">
              Unable to Work in Current Occupation
            </option>
            <option value="disability-related">
              Disability Related to Condition
            </option>
            <option value="hospitalization-related">
              Hospitalization Related to Condition
            </option>
            <option value="last-menstrual">Last Menstrual Period</option>
            <option value="last-seen">Date Last Seen</option>
            <option value="acute-manifestation">
              Acute Manifestation Date
            </option>
            <option value="last-xray">Last X-ray Date</option>
            <option value="accident-date">Accident Date</option>
            <option value="transfer-care">Transfer of Care</option>
            <option value="prescription">Prescription</option>
            <option value="report-start">
              Report Start (Assumed Care Date)
            </option>
            <option value="report-end">
              Report End (Relinquished Care Date)
            </option>
            <option value="first-visit">First Visit or Consultation</option>
          </select>
          <input type="date" style={{ width: "28%" }} />
          <button type="button" className="add-event">
            Delete
          </button>
        </div>

        <button type="button" className="add-event" onclick="addEvent()">
          Add event
        </button>
      </div>
    </div>
  );
};

export default ConditionsScreen;
