import React, { useEffect, useState } from "react";
import { GetDoctorsRequest } from "../../../api/helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

const ClinicsDoctorsdata = () => {
  const navigation = useNavigate();
  const [requestdata, setrequestdata] = useState(null);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const { isLoading } = useSelector((store) => store.sliceReducer);

  const FetchDoctorrequest = async () => {
    // const data = `agencyId=${profiledata?._id}&status="pending"`;
    try {
      const response = await GetDoctorsRequest({ status: "approved" });
      console.log(response?.data.data.data, "heloo-----");
      setrequestdata(response?.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchDoctorrequest();
  }, []);     

  const [showActionsMenu, setShowActionsMenu] = useState(null);
  const handleActionsClick = (index) => {
    setShowActionsMenu(showActionsMenu === index ? null : index);
  };

  return (
    <div className="table-container">
      {isLoading && <Loader />}
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Full Name</th>
            <th>Specialization</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Address</th>
            <th>Years of Experience</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* {users.map((user, index) => (
            <tr key={index}> */}
          {requestdata && requestdata.length > 0 ? (
          requestdata.map((user, index) => (
            <tr key={index}>
              <td>
                {user?.image ? (
                  <img src={user?.image} className="userTable-image" alt="User" />
                ) : (
                  <img
                    src={
                      "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                    }
                    className="userTable-image"
                    alt="Default Avatar"
                  />
                )}
              </td>
              <td>{user?.firstName + " " + user?.lastName}</td>
              <td className="userTable-td">
                {user?.categoryId?.length === 1
                  ? user.categoryId[0]?.name
                  : user?.categoryId?.length === 2
                  ? `${user.categoryId[0]?.name}, ${user.categoryId[1]?.name}`
                  : `${user.categoryId[0]?.name}, ${user.categoryId[1]?.name}...`}
              </td>
              <td>{user?.phone}</td>
              <td>{user?.email}</td>
              <td>{`${user?.address?.street} ,${user?.address?.state}..`}</td>
              <td style={{ textAlign: "center" }}>{user?.experience}</td>
              <td>
                <button
                  className="view-button"
                  style={{ fontSize: 14 }}
                  onClick={() => handleActionsClick(index)}
                >
                  View Info
                </button>
                {showActionsMenu === index && (
                  <div
                    className="user-table-popup-menu"
                    // Attach ref for outside click detection
                  >
                    <ul>
                      <li
                        onClick={() =>
                          navigation("/DoctorDetailsclinic", {
                            state: { data: user },
                          })
                        }
                      >
                        View Info.
                      </li>
                      <li>Message</li>
                      <li>Call</li>
                      <li>Assign Patients</li>
                      <li>History</li>
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          ))
        )  : (
            <tr>
              <td colSpan="8" className="no-doctor-found">
                No Doctor found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="results-info">
        {/* Showing 1 to {users.length} of {users.length} results */}
      </div>
    </div>
  );
};

export default ClinicsDoctorsdata;
