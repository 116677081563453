// import React from 'react'

// function PatientPayment() {
//   return (
//     <div>PatientPayment</div>
//   )
// }

// export default PatientPayment
import React, { useEffect, useState } from "react";
import Inputbox from "../../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Patientrequestupdates } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

import ClientInsurence from "./ClientInsurence";
import ClientPrivatePay from "./ClientPrivatePay";
import ClientSubscriptionPlan from "./ClientSubscriptionPlan";

const validationSchema = Yup.object().shape({
  CardDetails: Yup.string().required(
    "Card Details is required"
  ),
  PaymentMethod: Yup.string().required(
    "Payment Method is required"
  ),
})
const PatientPayment = ({info,getprofile}) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [Insurance, setInsurance] = useState(false);
  const [PrivatePay, setPrivatePay] = useState(false);
  const [SubscriptionPlan, setSubscriptionPlan] = useState(false);


  // useEffect(() => {
  //   if (info) {
  //     setValue("PaymentMethod", info?.PaymentMethod?.PaymentMethod || "");
  //     setValue("CardDetails", info?.PaymentMethod?.CardDetails || "");
  //   }
  // }, [info, setValue]);

  const onSubmit = async (data) => {
    // data.id = info?._id; // Add the id from 'info'

    // Reorganize the data structure as needed
    const formattedData = {
      PaymentMethod: {
        PaymentMethod: data.PaymentMethod,
        CardDetails: data.CardDetails
      },
      id: data.id
    };

    try {
      const response = await Patientrequestupdates(formattedData)
      // console.log(response?.data?.data,"data======resp[o=========")
      ShowToast(response?.data?.message)
      // getDteails()
    } catch (error) {
      // console.log(error)
    }
  }

  const handlePaymentMethod = (method) => {
    setInsurance(method === "insurance");
    setPrivatePay(method === "privatePay");
    setSubscriptionPlan(method === "subscriptionPlan");
  };


  return (
    <div style={{ padding: 20 }}>
     <h2 className="notifications__title">Select Payment Method</h2>
      <p className="payment-description">
        Choose a method for payment. You can bill your insurance, pay privately, or subscribe to a plan for additional benefits.
      </p>
      <div className="payment-section">
        <div
          className={`payment-method ${Insurance ? "active" : ""}`}
          onClick={() => handlePaymentMethod("insurance")}
        >
          <input
            type="radio"
            id="insurance"
            name="payment_type"
            value="insurance"
            checked={Insurance}
            readOnly
          />
          <label htmlFor="insurance">Bill the Client's Insurance</label>
        </div>

        <div
          className={`payment-method ${PrivatePay ? "active" : ""}`}
          onClick={() => handlePaymentMethod("privatePay")}
        >
          <input
            type="radio"
            id="private_pay"
            name="payment_type"
            value="private_pay"
            checked={PrivatePay}
            readOnly
          />
          <label htmlFor="private_pay">Private Pay (Out-of-Pocket)</label>
        </div>

        <div
          className={`payment-method ${SubscriptionPlan ? "active" : ""}`}
          onClick={() => handlePaymentMethod("subscriptionPlan")}
        >
          <input
            type="radio"
            id="subscription"
            name="payment_type"
            value="subscription"
            checked={SubscriptionPlan}
            readOnly
          />
          <label htmlFor="subscription">Subscription Plan</label>
        </div>
      </div>

      {Insurance && <ClientInsurence />}
      {PrivatePay && <ClientPrivatePay getprofile={getprofile} info={info}/>}
      {SubscriptionPlan && <ClientSubscriptionPlan />}

    </div>
  );
};

export default PatientPayment;
