import React from 'react'
import './Notes.css'
function InitialPsychiatricIntakeNote({setOpenModel}) {
  return (
    <div className="modal-container">
      {/* Modal Header */}
      <div className="modal-header">Initial Psychiatric Intake Note</div>
      {/* Patient Information */}
      <div className="section">
        <div className="section-header">Patient Information</div>
        <div className="section-content">
          <label htmlFor="patient-name">Patient Name</label>
          <input
            type="text"
            id="patient-name"
            defaultValue="John Doe"
            readOnly=""
          />
          <label htmlFor="dob">Date of Birth</label>
          <input type="date" id="dob" defaultValue="1969-11-13" readOnly="" />
          <label htmlFor="visit-date">Visit Date</label>
          <input
            type="date"
            id="visit-date"
            defaultValue="2024-12-14"
            readOnly=""
          />
          <label htmlFor="clinician-name">Clinician Name</label>
          <input
            type="text"
            id="clinician-name"
            defaultValue="Dr. Nageley Michel, DNP"
            readOnly=""
          />
        </div>
      </div>
      {/* Chief Complaint */}
      <div className="section">
        <div className="section-header">Chief Complaint</div>
        <textarea
          id="chief-complaint"
          placeholder="Describe the patient's primary concerns..."
          defaultValue={""}
        />
      </div>
      {/* History of Present Illness */}
      <div className="section">
        <div className="section-header">History of Present Illness</div>
        <textarea
          id="hpi"
          placeholder="Document the patient's symptoms, history, and contributing factors..."
          defaultValue={""}
        />
      </div>
      {/* Psychiatric History */}
      <div className="section">
        <div className="section-header">Psychiatric History</div>
        <textarea
          id="psychiatric-history"
          placeholder="Detail past psychiatric diagnoses, treatments, and outcomes..."
          defaultValue={""}
        />
      </div>
      {/* Medical History */}
      <div className="section">
        <div className="section-header">Medical History</div>
        <textarea
          id="medical-history"
          placeholder="Document the patient's medical history, including chronic conditions..."
          defaultValue={""}
        />
      </div>
      {/* Past Surgical History */}
      <div className="section">
        <div className="section-header">Past Surgical History</div>
        <textarea
          id="surgical-history"
          placeholder="Document the patient's past surgeries..."
          defaultValue={""}
        />
      </div>
      {/* Psychiatric Family History */}
      <div className="section">
        <div className="section-header">Psychiatric Family History</div>
        <textarea
          id="family-history"
          placeholder="Describe family history of psychiatric conditions..."
          defaultValue={""}
        />
      </div>
      {/* Current Medications */}
      <div className="section">
        <div className="section-header">Current Medications</div>
        <textarea
          id="medications"
          placeholder="List current medications and dosages..."
          defaultValue={""}
        />
      </div>
      {/* Tests */}
      <div className="section">
        <div className="section-header">Tests</div>
        <textarea
          id="tests"
          placeholder="Document clinical assessments or test results..."
          defaultValue={""}
        />
      </div>
      {/* Mental Status Examination */}
      <div className="section">
        <div className="section-header">Mental Status Examination</div>
        <textarea
          id="mse"
          placeholder="Describe appearance, behavior, mood, thought process, and cognition..."
          defaultValue={""}
        />
      </div>
      {/* Psychosocial History */}
      <div className="section">
        <div className="section-header">Psychosocial History</div>
        <textarea
          id="psychosocial-history"
          placeholder="Document family structure, support systems, and social functioning..."
          defaultValue={""}
        />
      </div>
      {/* Psychosocial Stressors */}
      <div className="section">
        <div className="section-header">Psychosocial Stressors</div>
        <textarea
          id="stressors"
          placeholder="Describe current psychosocial stressors..."
          defaultValue={""}
        />
      </div>
      {/* Patient Education */}
      <div className="section">
        <div className="section-header">Patient Education</div>
        <textarea
          id="education"
          placeholder="Document education materials or instructions provided to the patient..."
          defaultValue={""}
        />
      </div>
      {/* Assessment and Diagnosis */}
      <div className="section">
        <div className="section-header">Assessment and Diagnosis</div>
        <textarea
          id="assessment"
          placeholder="Provide diagnostic impressions and clinical assessments..."
          defaultValue={""}
        />
      </div>
      {/* Plan */}
      <div className="section">
        <div className="section-header">Plan</div>
        <textarea
          id="plan"
          placeholder="Outline the treatment plan, including medication adjustments and follow-up..."
          defaultValue={""}
        />
      </div>
      {/* Signature Confirmation */}
      <div className="section">
        <div className="section-header">Signature Confirmation</div>
        <div className="section-content">
          <label>
            <input type="checkbox" id="signature-confirmation" />
            By checking this box, I confirm that I have reviewed this document.
          </label>
        </div>
      </div>
      {/* Save Button */}
      <div className='divBtn'>

        <button className="button" onclick="saveIntakeNote()">
          Save Psychiatric Intake Note
        </button>
        <button className="buttonBack" onClick={() => setOpenModel(null)}>
          Back
        </button>
      </div>



    </div>

  )
}

export default InitialPsychiatricIntakeNote