import React, { useEffect, useState } from 'react'
import { GETinsurencedetails } from '../../../../../api/helper';
import { useSelector } from 'react-redux';

const EligibilityChecks = () => {
    const [insurancedata, setinsurancedata] = useState([])
    const profiledata = useSelector((state) => state?.cookies?.userDetails);
      const getinsurencedetails = async () => {
        try {
          const response = await GETinsurencedetails(profiledata?._id);
          // console.log(response?.data?.data?.data, "hello=====");
          setinsurancedata(response?.data?.data?.data)
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        getinsurencedetails();
      },[]);
  return (

    <div id="EligibilityChecks" className="tabcontent">
    <h3>Eligibility Checks History</h3>
    {insurancedata.map((item) => (
    <table border={1} style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
            <tr>
                <th>Date Run</th>
                <th>Eligibility Status</th>
                <th>In-Network Status</th>
                <th>View</th>
            </tr>
        </thead>
        <tbody>
  
            <tr>
                <td>{new Date(item.createdAt).toLocaleDateString()}</td>
               
                <td>Eligible</td>
                <td>In-Network</td>
                <td>
                    <a
                        href="path/to/EligibilityCheck_09-01-2024.pdf"
                        download="EligibilityCheck_09-01-2024.pdf"
                    >
                        Download
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    ))}
</div>
  )
}

export default EligibilityChecks