import React, { useEffect, useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import Inputbox from "../../Components/Inputbox";
import Credentialdata from "./Credentialdata";
import Specialtiesdata from "./Specialtiesdata";
import TreatmentMethods from "./TreatmentMethods";
import Calendarcontant from "./Calendarcontant";
import PaymentAccepted from "./PaymentAccepted";
import PayRate from "./PayRate";
import Practicelocations from "./Practicelocations";

import { useLocation } from "react-router-dom";
import {
  DropDownManu,
  DropDownManuCustom,
  DropDownManuCustomMulti,
} from "../../Components/DropDownButton";
import {
  FetchLanguage,
  GetAgencyBooking,
  GetCategory,
  GetDeliveryMode,
  getDetailsAgencyDoctor,
  GetProviderType,
  updateAgencyDoctor,
} from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
import moment from "moment";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    // street: Yup.string().required('Street is required'),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string().required("Zip code is required"),
  }),
})
const DoctorDetailsclinic = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("info");
  const [info, setInfo] = useState(location.state.data);
  // console.log(info,'========>info')
  const [special, setSpecial] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [data, setdata] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [selectedValues, setSelectedValues] = useState([]);
  const [Treatment, setTreatment] = useState([]);
  const [deleveryMode, setDeleveryMode] = useState([]);
  const [toggleOnOff, setToggleOnOff] = useState(info?.toggleOnOff || false);
  const [providerType, setproviderType] = useState([]);

  useEffect(() => {
    if (info?.toggleOnOff !== undefined) {
      setToggleOnOff(info.toggleOnOff);
    }
  }, [info]);

  const fetchProviderType = async (id) => {
    const response = await GetProviderType(`?id=${profiledata._id}`);
    setproviderType(response?.data?.data || []);
  };
  const GetLanguage = async (id) => {
    const response = await FetchLanguage(`?id=${profiledata._id}`);
    // console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
  const DeliveryMode = async () => {
    try {
      const response = await GetDeliveryMode(`?id=${profiledata._id}`);
      // console.log(response?.data?.data, "GetDeliveryMode======");
      setDeleveryMode(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(Treatment,"Treatment=======")
  const TreatmentTypeslist = async () => {
    try {
      const response = await GetAgencyBooking(`?id=${profiledata?._id}`);
      // console.log(response?.data?.data, "GetAgencyBooking======");
      setTreatment(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM",
    libraries: ["places"],
  });
  const newData = {
    id: info._id,
    email: info.email,
    gender: info.gender,
    phone: info.phone,
    ServiceType: info?.ServiceType,
    experience: info.experience,
    phone: info.phone,
    gender: info.gender,
    DOB: moment(info?.DOB).format("YYYY-MM-DD"),
    myApproach: info?.myApproach,
    myFocus: info?.myFocus,
    communicationStyle: info?.communicationStyle,
    journeyToMentalHealthcare: info?.journeyToMentalHealthcare,
    GoalsForYou: info?.GoalsForYou,
    FirstSessionWithYou: info?.FirstSessionWithYou,
    info: info.info,
    address: {
      street: info?.address?.street || "",
      city: info?.address?.city || "",
      state: info?.address?.state || "",
      country: info?.address?.country || "",
      zipCode: info?.address?.zipCode || "",
    },
  };
  useEffect(() => {
    console.log(info, "===info");
    console.log(
      info?.LanguagesId?.map((e) => e._id),
      // "===Mapped IDs"
    );

    const defaultVal = info?.LanguagesId || [];

    setSelectedValues(defaultVal);
  }, []);
  useEffect(() => {
    console.log("Updated default values:", {
      selectedValues,
    });
  }, [selectedValues]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...newData,
    },
  });

  useEffect(() => {
    reset({
      ...newData,
    });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const options = [
    { _id: "0-1", name: "0-1 (New or Entry Level)" },
    { _id: "2-5", name: "2-5 (Early Career)" },
    { _id: "6-10", name: "6-10 (Experienced)" },
    { _id: "11-15", name: "11-15 (Advanced)" },
    { _id: "16-20+", name: "16-20+ (Expert or Senior Level)" },
  ];

  const handleChange = (e) => {
    reset({
      ...newData,
      phone: e,
    });
  };

  const Specialization = async () => {
    try {
      const resposne = await GetCategory();
      setSpecial(resposne.data.data);
    } catch (error) {
      console.log(error, "===>>>>error");
    }
  };

  useEffect(() => {
    Specialization();
    TreatmentTypeslist();
    GetLanguage();
    DeliveryMode();
    fetchProviderType();
  }, []);

  // const onSubmit = (data) => {
  //   console.log(data);
  //   dataToSave(data);
  // };
  const handleToggleChange = () => {
    const newToggleState = !toggleOnOff; // Determine the new toggle state
    setToggleOnOff(newToggleState); // Update the state
    onSubmit({ toggleOnOff: newToggleState }); // Pass the new state
  };
  const onSubmit = (data) => {
    const updatedData = { ...data, id: info?._id }; // Include only necessary fields
    dataToSave(updatedData);
  };
  const dataToSave = async (data) => {
    console.log(data, "onsubmit=====");
    try {
      const response = await updateAgencyDoctor(data);
      console.log(response?.data?.data, "hello------reposne");
      ShowToast(response.data.message, "success");
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(info._id,"hiiiii------")
  const getDteails = async () => {
    const response = await getDetailsAgencyDoctor(info._id);
    console.log(response.data.data, "===response>>>>>");
    setInfo(response.data.data);
  };
  // useEffect(()=>{
  //   getDteails()
  // },[])
  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;

    // Function to get address component by type
    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };

    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue(
      "address.state",
      getAddressComponent("administrative_area_level_1")
    );
    setValue("address.city", getAddressComponent("locality"));

    // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names

    // Declare fullStreet and build it
    let fullStreet = "";

    // Priority handling: check if it's an establishment (business), premise, or standard street address
    if (establishment) {
      fullStreet = establishment; // Use business name if available
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`; // Standard street address
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }

    // Fallback: if no fullStreet found, use locality, state, country
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent(
        "administrative_area_level_1"
      )}, ${getAddressComponent("country")}`;
    }

    // Set the full street address in the form
    setValue("address.street", fullStreet);

    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };
  if (!isLoaded) return <div>Loading...</div>;
  const handlelanguage = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("LanguagesId", ids, { shouldValidate: true });
  };
  const matchedTreatments = Treatment.filter((treatment) =>
    info?.ServiceType.includes(treatment._id)
  );
  const matcheddeleveryMode = deleveryMode.filter((treatment) =>
    info?.DeliveryMode.includes(treatment._id)
  );
  const matchedprovidertype = providerType.filter((treatment) =>
    info?.ProviderType.includes(treatment._id)
  );
  // deleveryMode
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="doctor-details-container">
            <div className="Doctor_Details_txtt">Doctor Details</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: 20,
              }}
            >
              <div className="doctor-header">
                <img
                  className="doctor-image"
                  src={
                    info?.image
                      ? info?.image
                      : require("../../assest/Images/doctorimg.png")
                  }
                  alt="Doctor"
                />
                <div className="doctor-info">
                  <h3 style={{ textAlign: "left" }}>
                    Dr. {info?.firstName + info?.lastName}
                  </h3>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: 17,
                        fontWeight: "700",
                      }}
                    >
                      Service Type -
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        fontSize: 17,
                        marginLeft: 5,
                      }}
                    >
                      {matchedTreatments.slice(0, 2).map((treatment, index) => (
                        <span key={treatment._id}>
                          {treatment.name}
                          {index !== matchedTreatments.slice(0, 2).length - 1 &&
                            " , "}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: 17,
                        fontWeight: "700",
                      }}
                    >
                      Delivery Mode -
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        fontSize: 17,
                        marginLeft: 5,
                      }}
                    >
                      {matcheddeleveryMode
                        .slice(0, 2)
                        .map((treatment, index) => (
                          <span key={treatment._id}>
                            {treatment.name}
                            {index !==
                              matcheddeleveryMode.slice(0, 2).length - 1 &&
                              " , "}
                          </span>
                        ))}
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: 17,
                        fontWeight: "700",
                      }}
                    >
                      License Type -
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        fontSize: 17,
                        marginLeft: 5,
                      }}
                    >
                      {matchedprovidertype
                        .slice(0, 2)
                        .map((treatment, index) => (
                          <span key={treatment._id}>
                            {treatment.name}
                            {index !==
                              matchedprovidertype.slice(0, 2).length - 1 &&
                              " , "}
                          </span>
                        ))}
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: 17,
                        fontWeight: "700",
                      }}
                    >
                      License In: -
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        fontSize: 17,
                        marginLeft: 5,
                      }}
                    >
                      {info?.stateOfPractice?.map((state, index) => (
                        <span key={index}>
                          {state.substring(0, 2).toUpperCase()}
                          {index < info.stateOfPractice.length - 1 && ", "}
                        </span>
                      ))}
                    </div>
                  </div>
                  <p
                    style={{ textAlign: "left", marginBottom: 5, marginTop: 5 }}
                  >
                    {info.categoryId.name}
                  </p>
                  {/* <p style={{ textAlign: 'left', marginBottom: 5, marginTop: 5 }}>Sr. Psychiatrist</p> */}

                  <div className="rating">{/* <span>⭐⭐⭐⭐☆</span> */}</div>
                  {/* <p style={{ textAlign: 'left', marginTop: 5 }}>(0 Reviews)</p> */}
                </div>
              </div>
              <div>
                <div style={{ fontSize: 17 }}>Phone: {info?.phone}</div>
                <div style={{ fontSize: 17 }}>
                  Affiliated:{" Not Available"}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: 17 }}>Accepting Online Referral:</div>
                  <div
                    onClick={handleToggleChange}
                    style={{
                      marginLeft: "10px",
                      width: "50px",
                      height: "25px",
                      borderRadius: "25px",
                      backgroundColor: toggleOnOff ? "green" : "grey",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "2px",
                        left: toggleOnOff ? "26px" : "2px",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        transition: "all 0.3s ease",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-container" style={{ paddingRight: 20 }}>
              <button
                className={`tab-button ${activeTab === "info" ? "active" : ""}`}
                onClick={() => handleTabClick("info")}
              >
                Basic Information
              </button>
              <button
                className={`tab-button ${
                  activeTab === "credentials" ? "active" : ""
                }`}
                onClick={() => handleTabClick("credentials")}
              >
                Credentials
              </button>
              {/* <button
                className={`tab-button ${
                  activeTab === "specialties" ? "active" : ""
                }`}
                onClick={() => handleTabClick("specialties")}
              >
                Specialties and Conditions Treated
              </button> */}
              <button
                className={`tab-button ${
                  activeTab === "treatment" ? "active" : ""
                }`}
                onClick={() => handleTabClick("treatment")}
              >
                Specialties and Treatments
              </button>
              <button
                className={`tab-button ${
                  activeTab === "PaymentAccepted" ? "active" : ""
                }`}
                onClick={() => handleTabClick("PaymentAccepted")}
              >
                Payment Accepted
              </button>
              <button
                className={`tab-button ${
                  activeTab === "Practicelocations" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Practicelocations")}
              >
                Practice Locations
              </button>
              <button
                className={`tab-button ${
                  activeTab === "PayRate" ? "active" : ""
                }`}
                onClick={() => handleTabClick("PayRate")}
              >
                Pay Rate
              </button>
              <button
                className={`tab-button ${
                  activeTab === "calendar" ? "active" : ""
                }`}
                onClick={() => handleTabClick("calendar")}
              >
                Calendar
              </button>
            </div>

            <div className="tab-content">
              {activeTab === "info" && (
                <div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="doctor-form"
                  >
                    <div className="input-row">
                      <Inputbox
                        label="Email Address"
                        type="text"
                        placeholder="docto123@gmail.com"
                        name="email"
                        register={register}
                        disabled
                      />
                      <div style={{ width: "40px" }} />
                      <DropDownManuCustom
                        label="Gender"
                        placeholder="Select option"
                        options={[
                          { _id: "Male", name: "Male" },
                          { _id: "Female", name: "Female" },
                          { _id: "Other", name: "Other" },
                        ]}
                        name="gender"
                        dropdownicon
                        register={register}
                        id="_id"
                        clearErrors={clearErrors}
                        defaultValue={info?.gender}
                        // onSelect={(selectedOption) => console.log(selectedOption)} // Handle selected option
                      />
                    </div>
                    <div className="input-row">
                      <Inputbox
                        style={{ width: "100%", height: 43 }}
                        label="Phone Number"
                        type="text"
                        placeholder="9878654523210"
                        name="phone"
                        value={info?.phone}
                        onChange={handleChange}
                      />
                      <div style={{ width: "40px" }} />
                      <DropDownManuCustom
                        label="Experience (Years)"
                        placeholder="Select option"
                        options={options}
                        name="experience"
                        dropdownicon
                        register={register}
                        id="_id"
                        clearErrors={clearErrors}
                        defaultValue={info?.experience}
                      />
                    </div>
                    <div className="input-row">
                      {/* <div style={{ width: "100%" }}>
                        <DropDownManu
                          label="Gender"
                          placeholder="Select option"
                          options={[
                            { name: "Male" },
                            { name: "Female" },
                            { name: "Other" },
                          ]}
                          name="gender"
                          dropdownicon
                          register={register}
                        />
                      </div> */}
                      {/* <div style={{ width: "40px" }} /> */}
                      <div style={{ width: "100%", marginTop: -20 }}>
                        <Inputbox
                          label="Date of Birth"
                          type="date"
                          name="DOB"
                          register={register}
                          style={{
                            borderColor: errors.DOB ? "red" : "initial",
                            height: 40,
                          }}
                        />
                      </div>
                    </div>
                    {/*  */}

                    <div className="input-row">
                      <div style={{ width: "100%" }}>
                        <Autocomplete
                          onLoad={(autocompleteInstance) =>
                            setAutocomplete(autocompleteInstance)
                          }
                          onPlaceChanged={handlePlaceSelect}
                        >
                          <Inputbox
                            label="Street"
                            type="text"
                            placeholder="Enter Address"
                            name="address.street"
                            register={register}
                          />
                        </Autocomplete>
                      </div>
                      <div style={{ width: "40px" }} />
                      <div style={{ width: "100%" }}>
                        <Inputbox
                          label="City"
                          type="text"
                          placeholder="Enter City"
                          name="address.city"
                          register={register}
                        />
                         {errors.address?.city && (
                    <div className="errorMessage">
                      {errors.address.city.message}
                    </div>
                  )}
                      </div>
                     
                    </div>

                    <div className="input-row">
                      <div style={{ width: "100%" }}>
                        <Inputbox
                          label="State"
                          type="text"
                          placeholder="Enter State"
                          name="address.state"
                          register={register}
                        />
                        {errors.address?.state && (
                    <div className="errorMessage">
                      {errors.address.state.message}
                    </div>
                  )}
                      </div> 
                      <div style={{ width: "40px" }} />
                      <div style={{ width: "100%" }}>
                        <Inputbox
                          label="Country"
                          type="text"
                          placeholder="Enter Country"
                          name="address.country"
                          register={register}
                        />
                         {errors.address?.country && (
                    <div className="errorMessage">
                      {errors.address.country.message}
                    </div>
                  )}
                      </div>
                    </div>

                    {/*  */}
                    <div className="input-row">
                      <Inputbox
                        label="Zip Code"
                        type="text"
                        placeholder="Enter Zip Code"
                        name="address.zipCode"
                        register={register}
                      />
                      
                    </div>
                    {errors.address?.zipCode && (
                  <div className="errorMessage">
                    {errors.address.zipCode.message}
                  </div>
                )}
                    {/*  */}
                    <div className="input-row">
                      <div style={{ width: "100%" }}>
                        {/* <DropDownManuCustom
                          label="Language"
                          placeholder="Select language"
                          options={[
                            { _id: "english", name: "English" },
                            { _id: "spanish", name: "Spanish" },
                          ]}
                          name="language"
                          register={register}
                          clearErrors={clearErrors}
                          defaultValue={info?.language}
                          // Pass trigger here
                          style={{
                            borderColor: errors.language ? "red" : "initial",
                          }}
                        /> */}
                        <DropDownManuCustomMulti
                          label="Language"
                          placeholder="Select option"
                          options={data}
                          name="LanguagesId"
                          register={register}
                          id="_id"
                          clearErrors={clearErrors}
                          // onSelect={(value) => setValue("language", value)}
                          onSelect={handlelanguage}
                          defaultValue={selectedValues}
                        />
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      {/* <Inputbox
                          label="Short Biography"
                          placeholder="Enter Short Biography"
                          name="info"
                          register={register}
                          style={{
                            borderColor: errors.info ? "red" : "initial",
                          }}
                        /> */}
                      <label>Short Biography</label>
                      <textarea
                        style={{ height: 100, maxHeight: 150, minHeight: 100 }}
                        placeholder="Enter Short Biography"
                        name="info"
                        {...register("info", { required: true })}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <label>My Approach</label>
                      {/* <textarea 
                      style={{height:100,maxHeight:150,minHeight:100}}
                      placeholder=""
                      name="myApproach"
                     
                      {...register("myApproach", { required: true })}
                      /> */}
                      <textarea
                        style={{ height: 100, maxHeight: 150, minHeight: 100 }}
                        placeholder=""
                        name="myApproach"
                        // register={}
                        {...register("myApproach", { required: false })}
                      />
                    </div>
                    <div style={{ width: "100%", marginTop: 15 }}>
                      <label>My Focus</label>
                      <textarea
                        style={{ height: 100, maxHeight: 150, minHeight: 100 }}
                        placeholder=""
                        name="myFocus"
                        {...register("myFocus", { required: false })}
                      />
                    </div>
                    <div style={{ width: "100%", marginTop: 15 }}>
                      <label>My Communication Style</label>
                      <textarea
                        style={{ height: 100, maxHeight: 150, minHeight: 100 }}
                        placeholder=""
                        name="communicationStyle"
                        {...register("communicationStyle", { required: false })}
                      />
                    </div>
                    <div style={{ width: "100%", marginTop: 15 }}>
                      <label>My Journey to Mental Healthcare</label>
                      <textarea
                        style={{ height: 100, maxHeight: 150, minHeight: 100 }}
                        placeholder=""
                        name="journeyToMentalHealthcare"
                        {...register("journeyToMentalHealthcare", {
                          required: false,
                        })}
                      />
                    </div>

                    <div style={{ width: "100%", marginTop: 15 }}>
                      <label>My Goals for You</label>
                      <textarea
                        style={{ height: 100, maxHeight: 150, minHeight: 100 }}
                        placeholder=""
                        name="GoalsForYou"
                        {...register("GoalsForYou", { required: false })}
                      />
                    </div>
                    <div style={{ width: "100%", marginTop: 15 }}>
                      <label>My First Session with You</label>
                      <textarea
                        style={{ height: 100, maxHeight: 150, minHeight: 100 }}
                        placeholder=""
                        name="FirstSessionWithYou"
                        {...register("FirstSessionWithYou", { required: false })}
                      />
                    </div>
                    {/* <div style={{ width: "100%", marginTop: 15 }}>
                      <div class="responsive-form">
                        <div style={{ width: "70%" }}>
                          <div class="form-row">
                            <label for="npi" class="form-label">
                              NPI:
                            </label>
                            <span id="npi" class="form-value">
                              {info?.NPI || "Not Available"}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="email" class="form-label">
                              Email Address:
                            </label>
                            <span id="email" class="form-value">
                              {info.email}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="phone" class="form-label">
                              Phone Number:
                            </label>
                            <span id="phone" class="form-value">
                              {info.phone}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="gender" class="form-label">
                              Gender:
                            </label>
                            <span id="gender" class="form-value">
                              {info.gender}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="experience" class="form-label">
                              Experience (Years):
                            </label>
                            <span id="experience" class="form-value">
                              {info.experience}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="dob" class="form-label">
                              Date of Birth:
                            </label>
                            <span id="dob" class="form-value">
                              {moment(info?.DOB).format("YYYY-MM-DD")}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="street" class="form-label">
                              Street:
                            </label>
                            <span id="street" class="form-value">
                              {info.address?.street}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="city" class="form-label">
                              City:
                            </label>
                            <span id="city" class="form-value">
                              {info.address?.city}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="state" class="form-label">
                              State:
                            </label>
                            <span id="state" class="form-value">
                              {info.address?.state}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="zip" class="form-label">
                              Zip Code:
                            </label>
                            <span id="zip" class="form-value">
                              {info.address?.zipCode}
                            </span>
                          </div>
                          <div class="form-row">
                            <label for="country" class="form-label">
                              Country:
                            </label>
                            <span id="country" class="form-value">
                              {info.address?.country}
                            </span>
                          </div>
            
                          <div class="form-row">
                            <label for="language" class="form-label">
                              Language:
                            </label>
                            <span id="language" class="form-value">
                              {info?.language}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 30,
                      }}
                    >
                      <div className="cancelButton">Cancel</div>
                      <button
                        type="submit"
                        className="savebuttonn"
                        style={{ width: 340 }}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {activeTab === "credentials" && (
                <Credentialdata info={info} getDteails={getDteails} />
              )}
              {activeTab === "specialties" && (
                <Specialtiesdata
                  info={info}
                  special={special}
                  getDteails={getDteails}
                />
              )}
              {activeTab === "treatment" && (
                <TreatmentMethods
                  info={info}
                  special={special}
                  // getDteails={getDteails}
                />
              )}
              {activeTab === "calendar" && <Calendarcontant info={info} />}
              {activeTab === "PaymentAccepted" && (
                <PaymentAccepted info={info} getDteails={getDteails} />
              )}
              {activeTab === "Practicelocations" && (
                <Practicelocations info={info} getDteails={getDteails} />
              )}
              {activeTab === "PayRate" && (
                <PayRate info={info} getDteails={getDteails} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDetailsclinic;
