import React from 'react'

function PatinetSubscriptionPlan() {
  return (
    <>
    <div id="subscription-section" className='container_payment'>
      <h2>Subscription Plans</h2>
      <div className="enroll-item" >
      
        <div className="plan-name" style={{textAlign:'left'}}>Basic Plan</div>
        <div className="plan-description" style={{textAlign:'left'}}>
          Bi-weekly one-on-one therapy sessions, 24/7 crisis support, wellness
          programs.
     
        <div className="plan-price" style={{textAlign:'left'}}>$149/month</div>
        <button className="button_payment" onclick="enrollInPlan('Basic Plan')">
          Enroll Now
        </button>
        <button className="button_payment" onclick="showPlanDetails('Basic Plan')">
          View Details
        </button>
        </div>
      </div>
      <div className="enroll-item" >
      
      <div className="plan-name" style={{textAlign:'left'}}>Advanced Plan      </div>
      <div className="plan-description" style={{textAlign:'left'}}>
      Everything in Basic plus access to exclusive webinars and fitness programs.

   
      <div className="plan-price" style={{textAlign:'left'}}>$199/month</div>
      <button className="button_payment" onclick="enrollInPlan('Advanced Plan')">
        Enroll Now
      </button>
      <button className="button_payment" onclick="showPlanDetails('Advanced Plan')">
        View Details
      </button>
      </div>
    </div>
    <div className="enroll-item" >
      
      <div className="plan-name" style={{textAlign:'left'}}>Ultimate Plan</div>
      <div className="plan-description" style={{textAlign:'left'}}>
      Everything in Advanced plus access to Lifestyle Medicine.
   
      <div className="plan-price" style={{textAlign:'left'}}>$299/month</div>
      <button className="button_payment" onclick="enrollInPlan('Ultimate Plan')">
        Enroll Now
      </button>
      <button className="button_payment" onclick="showPlanDetails('Ultimate Plan')">
        View Details
      </button>
      </div>
    </div>
    </div>

    <div id="plan-details-modal" className="modal">
      <div className="modal-close" onclick="closeModal()">
        X
      </div>
      <h3 id="plan-title">Plan Details</h3>
      <p id="plan-description">Plan description goes here.</p>
    </div>
    <button className="submit-btn">Submit Payment</button>
    <>
    {/* <div className="container_subscription">
      <h1>Available Plans</h1>
      <div className="plan-card_subscription">
        <div className="plan-title_subscription">Basic Plan</div>
        <div className="plan-specialty_subscription">Specialty: Psychiatry</div>
        <div className="plan-details_subscription">
          <span><strong>Fee:</strong> $149</span>
          <span><strong>Frequency:</strong> Biweekly</span>
          <span><strong>Status:</strong> Active</span>
        </div>
        <button
          className="button_subscription details-button_subscription"
        >
          View Details
        </button>
        <button className="button_subscription enroll-button_subscription">Enroll Now</button>
      </div>
      <div className="plan-card_subscription">
        <div className="plan-title_subscription">Advanced Plan        </div>
        <div className="plan-specialty_subscription">Specialty: Psychiatry</div>
        <div className="plan-details_subscription">
          <span><strong>Fee:</strong> $199</span>
          <span><strong>Frequency:</strong> Monthly</span>
          <span><strong>Status:</strong> Active</span>
        </div>
        <button
          className="button_subscription details-button_subscription"
        >
          View Details
        </button>
        <button className="button_subscription enroll-button_subscription">Enroll Now</button>
      </div>
      <div className="plan-card_subscription">
        <div className="plan-title_subscription">Ultimate Plan</div>
        <div className="plan-specialty_subscription">Specialty: Psychiatry</div>
        <div className="plan-details_subscription">
          <span><strong>Fee:</strong> $299</span>
          <span><strong>Frequency:</strong> Annually</span>
          <span><strong>Status:</strong> Active</span>
        </div>
        <button
          className="button_subscription details-button_subscription"
        >
          View Details
        </button>
        <button className="button_subscription enroll-button_subscription">Enroll Now</button>
      </div>

</div> */}
  {/* Modal Structure */}
  {/* <div className="modal_subscription" id="detailsModal">
    <div className="modal-content_subscription">
      <h2 id="modalTitle" />
      <table>
        <tbody>
          <tr>
            <th>Category</th>
            <th>Details</th>
          </tr>
          <tr>
            <td>Specialty</td>
            <td id="modalSpecialty" />
          </tr>
          <tr>
            <td>Fee</td>
            <td id="modalFee" />
          </tr>
          <tr>
            <td>Frequency</td>
            <td id="modalFrequency" />
          </tr>
          <tr>
            <td>Status</td>
            <td id="modalStatus" />
          </tr>
          <tr>
            <td>Benefits</td>
            <td id="modalBenefits" />
          </tr>
        </tbody>
      </table>
      <table id="itemDetailsTable">
        <tbody>
          <tr>
            <th>Item</th>
            <th>Count</th>
          </tr>
        </tbody>
      </table>
      <button className="close-button" onclick="closeModal()">
        Close
      </button>
    </div>
  </div> */}
</>

  </>
  
  )
}

export default PatinetSubscriptionPlan