import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  ArchivedMeasures,
  GetMeasuresdata,
  MeasuresSubmit,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  focusArea: Yup.string().required("Focus Area is required"),
  measureType: Yup.string().required("Measure Type is required"),
  result: Yup.number()
    .typeError("Result must be a number")
    .required("Result is required")
    .min(0, "Result must be at least 0")

});
const MeasuresTabScreen = ({ info }) => {
  const [ismeasuresmodal, setismeasuresmodal] = useState(false);
  const [Measuredata, setMeasuredata] = useState([]);
  const [activeTab, setActiveTab] = useState("Active");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const newTab = tab === "Active" ? false : true;
    GetMeasures(newTab);
  };
  const openmeasuresModal = () => {
    setismeasuresmodal(true);
  };
  const ClosemeasuresModal = () => {
    setismeasuresmodal(false);
    reset();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    data.patientId = info?.patientId?._id;
    data.agencyId = info?.agencyId?._id;

    try {
      const respo = await MeasuresSubmit(data);
      const newTab = activeTab === "Active" ? false : true;
      GetMeasures(newTab);
      reset();
      ShowToast(respo?.data?.message);
      ClosemeasuresModal();
    } catch (error) {
      console.log(error);
    }
  };
  const GetMeasures = async (archived = false) => {
    try {
      const respo = await GetMeasuresdata(`?patientId=${info?.patientId?._id}&agencyId=${info?.agencyId?._id}&archived=${archived}`);
      // console.log(respo?.data?.data?.data, "respo-=======")
      setMeasuredata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleArchivedemeasures = async (id,data) => {
    try {
      const formdata ={
        archived:data
      }
      const respo = await ArchivedMeasures(id,formdata);
      const newTab = activeTab === "Active" ? false : true;
      GetMeasures(newTab);
      ShowToast(`Measures ${data ? "Archived" : "Restored"} Successfully`);
      // ShowToast(respo?.data?.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetMeasures();
  }, []);

  return (
    <div className="container_NotesTabScreen">
      <h1> Measures</h1>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
        <button
          className="add-button_NotesTabScreen"
          onClick={openmeasuresModal}
        >
          Add Measures
        </button>
      </div>

      {ismeasuresmodal && (
        <div className="modal_AddAllergy" id="addMeasuresModal">
          <h2>Add Measures</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group_NotesTabScreen">
              <label htmlFor="focusArea">Focus Area</label>
              <select
                id="focusArea"
                {...register("focusArea")}
                className={errors.focusArea ? "error-border" : ""}
              >
                <option value="">Select focus area</option>
                <option value="Suicidality">Suicidality</option>
                <option value="Mental Health">Mental Health</option>
                <option value="Substance Use">Substance Use</option>
                <option value="Lifestyle and Well-Being">
                  Lifestyle and Well-Being
                </option>
                <option value="Mood Tracking">Mood Tracking</option>
                <option value="Behavioral Wellness">Behavioral Wellness</option>
                <option value="Cognitive and Neurological">
                  Cognitive and Neurological
                </option>
                <option value="Burnout">Burnout</option>
                <option value="Social and Relational">
                  Social and Relational
                </option>
                <option value="Forensic and Risk">Forensic and Risk</option>
              </select>
              {errors.focusArea && (
                <p className="error-text">{errors.focusArea.message}</p>
              )}
            </div>

            <div className="form-group_NotesTabScreen">
              <label htmlFor="measureType">Measure Type</label>
              <select
                id="measureType"
                {...register("measureType")}
                className={errors.measureType ? "error-border" : ""}
              >
                <option value="">Select measure type</option>
                <option value="measure1"> measure type 1</option>
              </select>
              {errors.measureType && (
                <p className="error-text">{errors.measureType.message}</p>
              )}
            </div>

            <div className="form-group_NotesTabScreen">
              <label htmlFor="result">Result</label>
              <input
                type="number"
                id="result"
                placeholder="Automatically come from Test Score (e.g., score)"
                {...register("result")}
                className={errors.result ? "error-border" : ""}
              />
              {errors.result && (
                <p className="error-text">{errors.result.message}</p>
              )}
            </div>

            <div className="modal-footer_NotesTabScreen">
              <button
                type="button"
                className="cancel-button_NotesTabScreen"
                onClick={ClosemeasuresModal}
              >
                Cancel
              </button>
              <button type="submit" className="save-button_NotesTabScreen" style={{ marginLeft: 10 }}>
                Log Measures
              </button>
            </div>
          </form>
        </div>
      )}
     
      {/* <h2 style={{ textAlign: 'left', marginTop: 15, fontSize: 25 }}>Measures</h2> */}
       <div className="tabs-container__three" style={{ display: 'flex', width: '100%', justifyContent: 'left' }}>
        <button
          className={`tab-button__two ${activeTab === "Active" ? "active-tab__two" : ""}`}
          onClick={() => handleTabClick("Active")}
        >
          Active
        </button>
        <button
          className={`tab-button__two ${activeTab === "Archived" ? "active-tab__two" : ""}`}
          onClick={() => handleTabClick("Archived")}
        >
          Archived
        </button>
      </div>
      <div className="notes_style">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Measure Type</th>
              <th>Linked Appointment</th>
              <th>Author</th>
              <th>Signature Status</th>
              <th>Result</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody id="noteTableBody">
            {Measuredata.length > 0 ? (
              Measuredata.map((note) => (
                <tr key={note._id}>
                  <td>{new Date(note.createdAt).toLocaleDateString()}</td>
                  <td>{note.measureType}</td>
                  <td>{note.linkedAppointment || "None"}</td>
                  <td>
                    {note.agencyId?.firstName} {note.agencyId?.lastName}
                  </td>
                  <td>{note.status}</td>
                  <td>{note.result}</td>
                  <td>
                    <button>View</button>
                    <button style={{ marginLeft: 7 }}>Export</button>
                    <button
                      style={{ marginLeft: 7 }}
                      onClick={() => HandleArchivedemeasures(note._id,!note.archived)}
                    >
                      {note.archived ? "Restore" : "Archive"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  No Measure Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MeasuresTabScreen;
