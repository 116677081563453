import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Inputbox from "../../../Components/Inputbox";
import {
  DropDownManuCustom,
  DropDownManuCustomMulti,
} from "../../../Components/DropDownButton";
import { FetchLanguage, GetAgencyBooking, GetPaymentSources, PatientUpdate } from "../../../../api/helper";
import { setUserDetails } from "../../../../redux/cookiesReducer";
import { ShowToast } from "../../../../helpers/ToastService";
import { Store } from "../../../../redux";

const validationSchema = Yup.object().shape({
    IdentifiesAs: Yup.string().required("Identifies As is required"),
    preferredName:Yup.string().required("Preferred Name is required"),
    PrimaryServiceLocation: Yup.string().required(
      "Primary Service Location is required"
    ),
    // companyName: Yup.string().required("Group/Clinic Name is required"),
    firstName: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),
      MiddleInitial: Yup.string().required( "Middle Initial is required"),
    PreferredPronouns: Yup.string().required("Preferred Name is required"),
    DOB: Yup.date()
      .typeError("Date of Birth must be a valid date")
      .required("Date of Birth is required")
      .max(new Date(), "Date of Birth cannot be in the future"),
    gender: Yup.string().required("Gender is required"),
    AlternatePhoneNumber: Yup.string().required(
      "Alternate Phone Number is required"
    ),
    PreferredContactNumber: Yup.string().required(
      "Preferred Contact Number is required"
    ),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
      Race: Yup.string().required("Race is required"),
      Ethnicity: Yup.string().required("Ethnicity is required"),
  
    RequiresInterpreter: Yup.string().required(
      "Requires Interpreter is required"
    ),
    CurrentDiagnosis: Yup.string().required("Current Diagnosis is required"),
    Education: Yup.string().required("Education is required"),
    // PaymentSourcesId: Yup.string().required("Payment Type(s) is required"),
    SSN: Yup.string()
      .required("SSN is required")
      .matches(/^[0-9]+$/, "SSN must be digits")
      .min(4, "SSN must be 4 digits")
      .max(4, "SSN must be 4 digits"),
  });

function PateintInfo({ info,getprofile }) {
  const [Treatment, setTreatment] = useState([]);
  const [paymentSourcedata, setpaymentSource] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [defaultservice, setdefaultservice] = useState([])
  const [defaultpayment, setdefaultpayment] = useState()

const [data, setdata] = useState([]);
const GetLanguage = async () => {
    const agencyId = info?.agencyIds[0]?._id;
    if (agencyId) {
      const response = await FetchLanguage(`?id=${agencyId}`);
      setdata(response?.data?.data);
    }
  };
  
  const TreatmentTypeslist = async () => {
    const agencyId = info?.agencyIds[0]?._id;
    if (agencyId) {
      try {
        const response = await GetAgencyBooking(`?id=${agencyId}`);
        setTreatment(response?.data?.data || []);
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  const FetchPaymentSource = async () => {
    const agencyId = info?.agencyIds[0]?._id;
    if (agencyId) {
      try {
        const response = await GetPaymentSources(`?id=${agencyId}`);
        setpaymentSource(response?.data?.data || []);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(()=>{
    GetLanguage()
    TreatmentTypeslist()
    FetchPaymentSource()
  },[info])
  const handlelanguage = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("LanguagesId", ids, { shouldValidate: true });
  };
    const handleSelect = (selectedOptions) => {
    let ids = selectedOptions.map((e) => e?._id);
    setValue("ServiceType", ids, { shouldValidate: true });
  };

  useEffect(() => {
    console.log(
      info?.LanguagesId?.map((e) => e._id),
      // "===Mapped IDs"
    );
    const defaultVal =  info?.LanguagesId|| [];
    const defaultserv =info?.ServiceType|| [];
    const defalutpay =info?.PaymentSourcesId?._id|| [];
    setSelectedValues(defaultVal);
    setdefaultservice(defaultserv)
    setdefaultpayment(defalutpay)
  }, [info]);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (info) {
      setValue("uniqueId", info?.uniqueId || "");
      setValue("AlternatePhoneNumber", info?.AlternatePhoneNumber || "");
      setValue("CurrentDiagnosis", info?.CurrentDiagnosis || "");
      setValue("Education", info?.Education || "");
      setValue("Ethnicity", info?.Ethnicity || "");
      setValue("MiddleInitial", info?.MiddleInitial || "");
      setValue("PreferredContactNumber", info?.PreferredContactNumber || "");
      setValue("PreferredPronouns", info?.PreferredPronouns || "");
      setValue("PrimaryServiceLocation", info?.PrimaryServiceLocation || "");
      setValue("Race", info?.Race || "");
      setValue("RequiresInterpreter", info?.RequiresInterpreter || "");
      setValue("email", info?.email || "");
      setValue("lastName", info?.lastName  || "");
      setValue("PaymentSourcesId", info?.PaymentSourcesId?._id || "");
      setValue("phone", info?.phone || "");
      setValue("preferredName", info?.preferredName || "");
      setValue("ServiceType", info?.ServiceType || "");
      setValue("SSN", info?.SSN || "");
      setValue("IdentifiesAs", info?.IdentifiesAs || "");
      setValue("LanguagesId", info?.LanguagesId || "");
      setValue("ServiceType", info?.ServiceType || "");
      setValue("firstName", info?.firstName || "");
      setValue("lastName", info?.lastName || "");
      setValue("gender", info?.gender || "");
    //   setValue("companyName", info?.agencyId?.companyName || "");
      setValue(
        "DOB",
        info?.DOB ? info?.DOB.split("T")[0] : ""
      ); 
    }
  }, [info, setValue]);

  const onSubmit = async (data) => {
    data.id = info?._id;
    // return
    try {
        const response = await PatientUpdate(data);
        getprofile()
        Store.dispatch(setUserDetails(response?.data?.data));
        ShowToast("Profile Update successfully", "success");
    } catch (error) {
        console.log(error)
    }
  };
  return (
    <div style={{ padding: 20 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-row">
          <Inputbox
            label="Client ID"
            type="text"
            placeholder=""
            name="uniqueId"
            register={register}
            disabled="true"
          />
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Primary Service Location"
              type="text"
              placeholder=""
              name="PrimaryServiceLocation"
              register={register}
            />
            {errors.PrimaryServiceLocation && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.PrimaryServiceLocation.message}
              </p>
            )}
          </div>

          <div style={{ width: "40px" }} />
          {/* <div style={{ width: "100%" }}>
            <Inputbox
              label="Group/Clinic Name"
              type="text"
              placeholder=""
              name="companyName"
              disabled
              register={register}
            />
            {errors.companyName && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.companyName.message}
              </p>
            )}
          </div> */}
   <div style={{ width: "100%", marginTop: 18 }}>
            <label>{"Group/Clinic Name"}</label>
            <div
              style={{
                border: "1px solid grey",
                width: "100%",
                height: 45,
                borderRadius: 5,
                paddingLeft: 10,
                alignItems: "center",
                display: "flex",
              }}
            >
              <div style={{ marginLeft: 5 }}>{info?.agencyIds[0]?.companyName || "Request not accepted "}</div>
            </div>
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="First Name"
              type="text"
              placeholder=""
              name="firstName"
              register={register}
            />
            {errors.firstName && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.firstName.message}
              </p>
            )}
          </div>
        </div>

        <div className="input-row">
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Last Name"
              type="text"
              placeholder=""
              name="lastName"
              register={register}
            />
            {errors.lastName && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.lastName.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Middle Initial"
              type="text"
              placeholder=""
              name="MiddleInitial"
              register={register}
            />
            {errors.MiddleInitial && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.MiddleInitial.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Preferred Name"
              type="text"
              placeholder=""
              name="preferredName"
              register={register}
            />
            {errors.preferredName && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.preferredName.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />

          <div style={{ width: "100%" }}>
            <Inputbox
              label="Date of Birth"
              type="date"
              placeholder=""
              name="DOB"
              register={register}
            />
            {errors.DOB && <p className="errorMessage">{errors.DOB.message}</p>}
          </div>
        </div>
        <div className="input-row">
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Sex"
              placeholder="Select option"
              options={[
                { _id: "Male", name: "Male" },
                { _id: "Female", name: "Female" },
                { _id: "Other", name: "Other" },
              ]}
              name="gender"
              dropdownicon
              register={register}
              defaultValue={info?.gender || info?.gender}
            />
            {errors.gender && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.gender.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Identifies As"
              placeholder="Select option"
              options={[
                { _id: "Female", name: "Female" },
                { _id: "Male", name: "Male" },
                { _id: "Non-Binary", name: "Non-Binary" },
                { _id: "Transgender Female", name: "Transgender Female" },
                { _id: "Transgender Male", name: "Transgender Male" },
                {
                  _id: "Genderqueer/Non-Conforming",
                  name: "Genderqueer/Non-Conforming",
                },
                { _id: "Two-Spirit", name: "Two-Spirit" },
                { _id: "Prefer Not to Say", name: "Prefer Not to Say" },
                {
                  _id: "Other (with an optional input field)",
                  name: "Other (with an optional input field)",
                },
              ]}
              name="IdentifiesAs"
              dropdownicon
              register={register}
              defaultValue={info?.IdentifiesAs}
            />
            {errors.IdentifiesAs && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.IdentifiesAs.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Preferred Pronouns"
              placeholder="Select option"
              options={[
                { _id: "She/Her", name: "She/Her" },
                { _id: "He/Him", name: "He/Him" },
                { _id: "They/Them", name: "They/Them" },
                { _id: "Ze/Zir", name: "Ze/Zir" },
                { _id: "Xe/Xem", name: "Xe/Xem" },
                { _id: "No Preference", name: "No Preference" },
                { _id: "Prefer Not to Say", name: "Prefer Not to Say" },
                {
                  _id: "Other (with an optional input field)",
                  name: "Other (with an optional input field)",
                },
              ]}
              name="PreferredPronouns"
              dropdownicon
              register={register}
              defaultValue={info?.PreferredPronouns}
            />
            {errors.PreferredPronouns && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.PreferredPronouns.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%", marginTop: 18 }}>
            <label>{"Phone"}</label>
            <div
              style={{
                border: "1px solid grey",
                width: "100%",
                height: 45,
                borderRadius: 5,
                paddingLeft: 10,
                alignItems: "center",
                display: "flex",
              }}
            >
              <div style={{ marginLeft: 5 }}>{info?.phone}</div>
            </div>
          </div>
        </div>
        <div className="input-row">
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Alternate Phone Number"
              type="text"
              placeholder=""
              name="AlternatePhoneNumber"
              register={register}
            />
            {errors.AlternatePhoneNumber && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.AlternatePhoneNumber.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Preferred Contact Number"
              type="text"
              placeholder=""
              name="PreferredContactNumber"
              register={register}
            />
            {errors.PreferredContactNumber && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.PreferredContactNumber.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Email"
              type="text"
              placeholder=""
              name="email"
              register={register}
              disabled
            />
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Race "
              placeholder="Select option"
              options={[
                {
                  _id: "American Indian or Alaska Native",
                  name: "American Indian or Alaska Native",
                },
                { _id: "Asian", name: "Asian" },
                {
                  _id: "Black or African American",
                  name: "Black or African American",
                },
                {
                  _id: "Native Hawaiian or Other Pacific Islander",
                  name: "Native Hawaiian or Other Pacific Islander",
                },
                { _id: "White", name: "White" },
                { _id: "Prefer Not to Say", name: "Prefer Not to Say" },
                { _id: "Other", name: "Other" },
              ]}
              name="Race"
              dropdownicon
              register={register}
              defaultValue={info?.Race}
            />
            {errors.Race && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.Race.message}
              </p>
            )}
          </div>
        </div>
        <div className="input-row">
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Ethnicity "
              placeholder="Select option"
              options={[
                { _id: "Hispanic or Latino", name: "Hispanic or Latino" },
                {
                  _id: "Not Hispanic or Latino",
                  name: "Not Hispanic or Latino",
                },
                { _id: "Prefer Not to Say", name: "Prefer Not to Say" },
                {
                  _id: "Other (with an optional input field)",
                  name: "Other (with an optional input field)",
                },
              ]}
              name="Ethnicity"
              dropdownicon
              register={register}
              defaultValue={info?.Ethnicity}
            />
            {errors.Ethnicity && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.Ethnicity.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <DropDownManuCustomMulti
              label="Language"
              placeholder="Select option"
              options={data}
              name="LanguagesId"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              // onSelect={(value) => setValue("language", value)}
              onSelect={handlelanguage}
              defaultValue={selectedValues}
            />
      {errors.LanguagesId && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.LanguagesId.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Requires Interpreter "
              placeholder="Select option"
              options={[
                { _id: "Yes", name: "Yes" },
                { _id: "No", name: "No" },
                { _id: "Occasionally", name: "Occasionally" },
                { _id: "Prefer Not to Say", name: "Prefer Not to Say" },
              ]}
              name="RequiresInterpreter"
              dropdownicon
              register={register}
              defaultValue={info?.RequiresInterpreter}
            />
            {errors.RequiresInterpreter && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.RequiresInterpreter.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Current Diagnosis
    "
              type="text"
              placeholder=""
              register={register}
              name="CurrentDiagnosis"
            />
            {errors.CurrentDiagnosis && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.CurrentDiagnosis.message}
              </p>
            )}
          </div>
        </div>
        <div className="input-row">
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Education  "
              placeholder="Select option"
              options={[
                { _id: "No Formal Education", name: "No Formal Education" },
                { _id: "Elementary School", name: "Elementary School" },
                { _id: "Middle School", name: "Middle School" },
                {
                  _id: "High School Diploma/GED",
                  name: "High School Diploma/GED",
                },
                { _id: "Some College", name: "Some College" },
                { _id: "Bachelor's Degree", name: "Bachelor's Degree" },
                { _id: "Master's Degree", name: "Master's Degree" },
                {
                  _id: "Doctorate/Professional Degree",
                  name: "Doctorate/Professional Degree",
                },
                { _id: "Associate Degree", name: "Associate Degree" },
                { _id: "Other ", name: "Other " },
              ]}
              name="Education"
              dropdownicon
              register={register}
              defaultValue={info?.Education}
            />
            {errors.Education && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.Education.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <DropDownManuCustomMulti
              label="Service Type  "
              placeholder="Select option"
              options={Treatment}
              name="ServiceType"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={handleSelect}
              defaultValue={defaultservice}
            />
            {errors.ServiceType && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.ServiceType.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <DropDownManuCustom
              label="Payment Source"
              placeholder="Select option"
                options={paymentSourcedata}
              name="PaymentSourcesId"
              register={register}
              clearErrors={clearErrors}
              // onSelect={handlePaymentSourceChange}
              dropdownicon
              id="_id"
                defaultValue={defaultpayment}
            />
            {errors.PaymentSourcesId && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.PaymentSourcesId.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="SSN (Last Four Digits)"
              type="text"
              placeholder=""
              name="SSN"
              register={register}
            />
            {errors.SSN && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {" "}
                {errors.SSN.message}
              </p>
            )}
          </div>
        </div>

        <div className="client-history__buttons">
          <button className="client-history__button client-history__button--cancel">
            Cancel
          </button>
          <button
            type="submit"
            className="client-history__button client-history__button--save"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default PateintInfo;
