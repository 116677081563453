import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Deleteinsurance,
  GETinsurencedetails,
  Postinsurencedetails,
} from "../../../../../api/helper";
import { ShowToast } from "../../../../../helpers/ToastService";
import { useSelector } from "react-redux";

const InsuranceDetails = ({info}) => {
  const [relationship, setRelationship] = useState("self");
  const [insurancedata, setinsurancedata] = useState([])
  const [modalopen, setmodalopen] = useState(false)
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  // console.log(insurancedata,"profiledata-----")

  const validationSchema = Yup.object().shape({
    PlanType: Yup.string().required("Plan Type is required"),
    PlanName: Yup.string().required("Plan Name is required"),
    PolicyNumber: Yup.string().required("Policy Number is required"),
    PhoneNumber: Yup.string().required("Phone Number is required"),
  
    GroupNumber: Yup.string()
      .matches(/^\d+$/, "Group Number should contain only numbers")
      .notRequired(),
    CopayAmount: Yup.string()
      .matches(/^\$?\d+(\.\d{2})?$/, "Invalid amount (e.g., $0.00 or 0.00)")
      .notRequired(),
    DeductibleAmount: Yup.string()
      .matches(/^\$?\d+(\.\d{2})?$/, "Invalid amount (e.g., $0.00 or 0.00)")
      .notRequired(),
    PatientRelationship: Yup.string()
      .required("Relationship is required")
      .notOneOf(["Select Option"], "Please select a valid relationship"),
    // insured_name: Yup.string().when('relationship', {
    //     is: (value) => value !== 'self' && value !== '',
    //     then: Yup.string().required('Field is required'),
    //     otherwise: Yup.string().notRequired(),
    //   }),
    // insured_id: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Insured ID is required"),
    // }),
    // ZipCode: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Zip Code is required"),
    // }),
    // StreetAddress: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Street Address is required"),
    // }),
    // Apt: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Apt is required"),
    // }),
    // City: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("City is required"),
    // }),
    // dob: Yup.date().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.date().required("DOB is required"),
    // }),
    // ssn: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("SSN is required"),
    // }),
    // gender: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Gender is required"),
    // }),
    // Telephone: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Telephone is required"),
    // }),
    // state: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("State is required"),
    // }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    // console.log("Form Data:", data);
    data.agencyId = profiledata?._id
    data.patientId =info?.patientId?._id
    try {
      const response = await Postinsurencedetails(data);
      ShowToast(response?.data?.message);
      console.log(response?.data?.data);
      getinsurencedetails();
     setmodalopen(false)
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const getinsurencedetails = async () => {
    try {
      const response = await GETinsurencedetails(profiledata?._id);
      // console.log(response?.data?.data?.data, "hello=====");
      setinsurancedata(response?.data?.data?.data)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getinsurencedetails();
  }, []);
  const handleRemove = async(id) => {
    if (window.confirm("Are you sure you want to remove this insurance?")) {
      console.log("Removed insurance with ID:", id);
      try {
        const response = await Deleteinsurance(id)
        ShowToast(response?.data?.message);
        getinsurencedetails()
      } catch (error) {
        console.log(error)
      }
    
    }
  };

  return (
    <>
    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      {/* <div id="insuranceDetails" className="tabcontent">
        <h3>
          Insurance Details with Corresponding CMS 1500 Field Mapping/Reference
        </h3>
        <div className="insurance-grid-container">
          <div className="insurance-grid">
            <div className="input-group">
              <label htmlFor="PlanType">Plan Type [Box 1]*</label>
              <select id="PlanType" name="PlanType" {...register("PlanType")}>
                <option value="">Select Option</option>
                <option value="Medicare">Medicare</option>
                <option value="Medicaid">Medicaid</option>
                <option value="Tricare">Tricare</option>
                <option value="CHAMPVA">CHAMPVA</option>
                <option value="Group Health Plan">Group Health Plan</option>
                <option value="FECA Black Lung">FECA Black Lung</option>
                <option value="Other">Other</option>
              </select>
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.PlanType?.message}
              </p>
            </div>
            <div className="input-group">
              <label htmlFor="PlanName">Plan Name [Box 11c]*</label>
              <select id="PlanName" name="PlanName" {...register("PlanName")}>
                <option value="">Select Option</option>
                <option value="Choice Fund OA Plus">Choice Fund OA Plus</option>
                <option value="PPO Blue Shield">PPO Blue Shield</option>
                <option value="Kaiser Permanente">Kaiser Permanente</option>
              </select>
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.PlanName?.message}
              </p>
            </div>
            <div className="input-group">
              <label htmlFor="PolicyNumber">Policy Number [Box 1a]*</label>
              <input
                type="text"
                id="PolicyNumber"
                name="PolicyNumber"
                placeholder="U7541039301"
                {...register("PolicyNumber")}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.PolicyNumber?.message}
              </p>
            </div>
            <div className="input-group">
              <label htmlFor="PhoneNumber">Phone Number</label>
              <input
                type="text"
                id="PhoneNumber"
                name="PhoneNumber"
                placeholder="(800) 244-6224"
                {...register("PhoneNumber")}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.PhoneNumber?.message}
              </p>
            </div>
            <div className="input-group">
              <label htmlFor="GroupNumber">Group Number [Box 11]</label>
              <input
                type="text"
                id="GroupNumber"
                name="GroupNumber"
                placeholder={"3333059"}
                {...register("GroupNumber")}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.GroupNumber?.message}
              </p>
            </div>
            <div className="input-group">
              <label htmlFor="CopayAmount">Copay Amount</label>
              <input
                type="text"
                id="CopayAmount"
                name="CopayAmount"
                placeholder="$0.00"
                {...register("CopayAmount")}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.CopayAmount?.message}
              </p>
            </div>
            <div className="input-group">
              <label htmlFor="DeductibleAmount">Deductible Amount</label>
              <input
                type="text"
                id="DeductibleAmount"
                name="DeductibleAmount"
                placeholder="$0.00"
                {...register("DeductibleAmount")}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.DeductibleAmount?.message}
              </p>
            </div>
            <div className="input-group">
              <label htmlFor="PatientRelationship">
                Patient's Relationship [Box 6]
              </label>
              <select
                id="PatientRelationship"
                {...register("PatientRelationship")}
                onChange={(e) => setRelationship(e.target.value)}
              >
                <option value="">Select Option</option>
                <option value="self">Self</option>
                <option value="spouse">Spouse</option>
                <option value="child">Child</option>
                <option value="guardian">Guardian</option>
              </select>
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.PatientRelationship?.message}
              </p>
            </div>
          </div>

          <div
            id="policy-holder-section"
            className="policy-holder-section"
            style={{
              display:
                relationship !== "self" && relationship !== ""
                  ? "block"
                  : "none",
            }}
          >
            <h3>Policy Holder Information</h3>
            <div className="insurance-grid">
              <div className="input-group">
                <label htmlFor="insured_name">Insured Name [Box 4]</label>
                <input
                  type="text"
                  id="insured_name"
                  name="insured_name"
                  placeholder="Full Name"
                  {...register("insured_name")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.insured_name?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="insured_id">Insured ID Number [Box 1a]</label>
                <input
                  type="text"
                  id="insured_id"
                  name="insured_id"
                  placeholder="ID Number"
                  {...register("insured_id")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.insured_id?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="address">Street Address [Box 7]</label>
                <input
                  type="text"
                  id="StreetAddress"
                  name="StreetAddress"
                  placeholder="123 Main St"
                  {...register("StreetAddress")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.StreetAddress?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="Apt">Apt/Suite [Box 7]</label>
                <input
                  type="text"
                  id="Apt"
                  name="Apt"
                  placeholder="Apt 1B"
                  {...register("Apt")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.Apt?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="City">City [Box 7]</label>
                <input
                  type="text"
                  id="City"
                  name="City"
                  placeholder="City"
                  {...register("City")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.City?.message}
                </p>
              </div>

              <div className="input-group">
                <label htmlFor="State">State [Box 7]</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  placeholder="State"
                  {...register("state")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.state?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="ZipCode">Zip Code [Box 7]</label>
                <input
                  type="text"
                  id="ZipCode"
                  name="ZipCode"
                  placeholder="12345"
                  {...register("ZipCode")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.ZipCode?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="Telephone">Telephone [Box 5]</label>
                <input
                  type="text"
                  id="Telephone"
                  name="Telephone"
                  placeholder="(123) 456-7890"
                  {...register("Telephone")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.Telephone?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="ssn">SSN</label>
                <input
                  type="text"
                  id="ssn"
                  name="ssn"
                  placeholder="--- -- ----"
                  {...register("ssn")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.ssn?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="dob">Date of Birth [Box 3]</label>
                <input type="date" id="dob" name="dob" {...register("dob")} />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.dob?.message}
                </p>
              </div>
              <div className="input-group">
                <label htmlFor="gender">Gender [Box 3]</label>
                <select id="gender" name="gender" {...register("gender")}>
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.gender?.message}
                </p>
              </div>
            </div>
          </div>

          <div className="input-group">
            <button className="button" type="none">
              Check Eligibility
            </button>
          </div>
        </div>
      </div>
      <button className="submit-btn" type="submit">
        Submit Payment
      </button>
      </form>
      <div style={styles.container}>
      {insurancedata.map((item) => (
        <div key={item._id} style={styles.card}>
          <div style={styles.content}>
            <h3 style={styles.title}>Plan Name: {item.PlanName || "N/A"}</h3>
            <p style={styles.text}>Plan Type: {item.PlanType || "N/A"}</p>
            <p style={styles.text}>Policy Number: {item.PolicyNumber || "N/A"}</p>
            <p style={styles.text}>Copay Amount: {item.CopayAmount || "N/A"}</p>
            <p style={styles.text}>Deductible Amount: {item.DeductibleAmount || "N/A"}</p>
          </div>
          <button
            style={styles.removeButton}
            onClick={() => handleRemove(item._id)}
          >
            Remove
          </button>
        </div>
      ))}
    </div> */}
    <>
    <button className="add-button_insuranceDetails" onClick={() => setmodalopen(true)} >Add New Insurance</button>
    {modalopen &&(
    <div 
    style={{
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: "fixed",
      zIndex: 1000,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Add a transparent background
    }}>
    <div className="modal_insuranceDetails" >
    <div
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  position: "absolute",
                  right: 20,
                }}
                onClick={() => setmodalopen(false)}
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                  style={{ height: 20, width: 20 }}
                />
              </div>
      <h2>Insurance Details</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row_insuranceDetails">
          <div className="form-column_insuranceDetails">
            <label htmlFor="plan-type">Plan Type [Box 1]*</label>
            <select id="plan-type" required="" {...register("PlanType")}>
              <option value="">Select Option</option>
              <option value="HMO">HMO</option>
              <option value="PPO">PPO</option>
              <option value="EPO">EPO</option>
            </select>
            <p style={{ color: "red", textAlign: "left" }}>
                {errors.PlanType?.message}
              </p>
          </div>
          <div className="form-column_insuranceDetails">
            <label htmlFor="plan-name">Plan Name [Box 11c]*</label>
            <select id="plan-name" required=""{...register("PlanName")}>
              <option value="">Select Option</option>
              <option value="Blue Cross">Blue Cross</option>
              <option value="United Health">United Health</option>
            </select>
            <p style={{ color: "red", textAlign: "left" }}>
                {errors.PlanName?.message}
              </p>
          </div>
          <div className="form-column_insuranceDetails">
            <label htmlFor="policy-number">Policy Number [Box 1a]*</label>
            <input
              type="text"
              id="PolicyNumber"
              placeholder="Enter Policy Number"
              required=""
              {...register("PolicyNumber")}
            />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.PolicyNumber?.message}
              </p>
          </div>
        </div>
        <div className="form-row_insuranceDetails">
          <div className="form-column">
            <label htmlFor="phone-number">Phone Number</label>
            <input type="text" id="phone-number" placeholder="(000) 000-0000"    {...register("PhoneNumber")}/>
            <p style={{ color: "red", textAlign: "left" }}>
                {errors.PhoneNumber?.message}
              </p>
          </div>
          <div className="form-column_insuranceDetails">
            <label htmlFor="group-number">Group Number [Box 11]</label>
            <input
              type="text"
              id="group-number"
              placeholder="Enter Group Number"
              {...register("GroupNumber")}
            />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.GroupNumber?.message}
              </p>
          </div>
          <div className="form-column_insuranceDetails">
            <label htmlFor="copay-amount">Copay Amount</label>
            <input type="text" id="copay-amount" placeholder="$0.00"    {...register("CopayAmount")}/>
            <p style={{ color: "red", textAlign: "left" }}>
                {errors.CopayAmount?.message}
              </p>
          </div>
          <div className="form-column_insuranceDetails">
            <label htmlFor="deductible-amount">Deductible Amount</label>
            <input type="text" id="deductible-amount" placeholder="$0.00" {...register("DeductibleAmount")}/>
            <p style={{ color: "red", textAlign: "left" }}>
                {errors.DeductibleAmount?.message}
              </p>
          </div>
        </div>
        <div className="form-row_insuranceDetails">
          <div className="form-column"  style={{width:'100%',marginTop:10}}>
            <label htmlFor="relationship">Patient's Relationship [Box 6]</label>
            <select
              id="relationship"
              onchange="togglePolicyHolderInfo()"
              required=""
              style={{width:'100%'}}
              {...register("PatientRelationship")}
              onChange={(e) => setRelationship(e.target.value)}
            >
               <option value="">Select Option</option>
              <option value="Self">Self</option>
              <option value="Spouse">Spouse</option>
              <option value="Child">Child</option>
              <option value="Other">Other</option>
            </select>
            <p style={{ color: "red", textAlign: "left" }}>
                {errors.PatientRelationship?.message}
              </p>
          </div>
        </div>
        <div className="policy-holder_insuranceDetails" id="policy-holder-info"  style={{
              display:
                relationship !== "Self" && relationship !== ""
                  ? "block"
                  : "none",
            }}>
          <h3>Policy Holder Information</h3>
          <div className="form-row_insuranceDetails">
            <div className="form-column_insuranceDetails">
              <label htmlFor="insured-name">Insured Name [Box 4]</label>
              <input type="text" id="insured-name" placeholder="Full Name"      {...register("insured_name")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.insured_name?.message}
                </p>
            </div>
            <div className="form-column_insuranceDetails">
              <label htmlFor="insured-id">Insured ID Number [Box 1a]</label>
              <input type="text" id="insured-id" placeholder="ID Number"   {...register("insured_id")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.insured_id?.message}
                </p>
            </div>
            <div className="form-column_insuranceDetails">
              <label htmlFor="street-address">Street Address [Box 7]</label>
              <input type="text" id="street-address" placeholder="123 Main St"   {...register("StreetAddress")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.StreetAddress?.message}
                </p>
            </div>
          </div>
          <div className="form-row_insuranceDetails">
            <div className="form-column_insuranceDetails">
              <label htmlFor="city">City [Box 7]</label>
              <input type="text" id="city" placeholder="City"   {...register("City")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.City?.message}
                </p>
            </div>
            <div className="form-column_insuranceDetails">
              <label htmlFor="state">State [Box 7]</label>
              <input type="text" id="state" placeholder="State"    {...register("state")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.state?.message}
                </p>
            </div>
            <div className="form-column_insuranceDetails">
              <label htmlFor="zip-code">Zip Code [Box 7]</label>
              <input type="text" id="zip-code" placeholder={12345}   {...register("ZipCode")}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.ZipCode?.message}
                </p>
            </div>
            <div className="form-column_insuranceDetails" >
              <label htmlFor="dob">Date of Birth [Box 3]</label>
              <input type="date" id="dob"{...register("dob")}  />
              <p style={{ color: "red", textAlign: "left" }}>
                  {errors.dob?.message}
                </p>
            </div>
          </div>
        </div>
        <button type="submit" >
          Save
        </button>
        <button className="check-eligibility" onclick="checkEligibility()">
          Check Eligibility
        </button>
      </form>
    
    </div>
    </div>
    )}

  <div class="insurance-list">
        <h2>Insurance List</h2>
        <table>
            <thead>
                <tr>
                    <th>Plan Type</th>
                    <th>Plan Name</th>
                    <th>Policy Number</th>
                    <th>Phone Number</th>
                    <th>Group Number</th>
                    <th>Copay</th>
                    <th>Deductible</th>
                    <th>Relationship</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody id="insurance-table-body">
            {insurancedata.map((item) => (
    <tr key={item._id}>
      <td>{item.PlanType}</td>
      <td>{item.PlanName}</td>
      <td>{item.PolicyNumber}</td>
      <td>{item.PhoneNumber}</td>
      <td>{item.GroupNumber}</td>
      <td>{item.CopayAmount}</td>
      <td>{item.DeductibleAmount}</td>
      <td>{item.PatientRelationship}</td>
      <td>
        <button className="check-eligibility_insuranceDetails">Check Eligibility</button>
        <button style={{marginLeft:10}} onClick={() => handleRemove(item._id)}>Delete</button>
      </td>
    </tr>
  ))}
    
            </tbody>
        </table>
    </div>
</>

     {/* </form> */}
    </>
  );
};
const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    fontFamily: "'Arial', sans-serif",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
    // maxWidth: "400px",
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
    color: "#555",
    marginBottom: "5px",
  },
  removeButton: {
    backgroundColor: "#ff4d4f",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "10px 15px",
    cursor: "pointer",
    fontWeight: "bold",
  },
};
export default InsuranceDetails;
