import React, { useEffect, useState } from "react";
import { ProfileNavbar } from "../../Components/Navbar";
import PatientSidebar from "./PatientSidebar";
import { useSelector } from "react-redux";
import { Patientgetprofile } from "../../../api/helper";

const PatientProfile = () => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [userData, setUserData] = useState(null);
  const getprofile = async (data) => {
    try {
      const response = await Patientgetprofile();
      setUserData(response.data.data);
      // console.log(response.data.data,'==>>response.data.data')
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getprofile();
  }, []);

  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <ProfileNavbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 50, paddingRight: 30, flexDirection: "column" }}
        >
          <div className="flex-row">
            {profiledata?.image ? (
              <img
                src={profiledata?.image}
                style={{ height: 105, width: 105, borderRadius: 60 }}
              />
            ) : (
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                style={{ height: 105, width: 105, borderRadius: 60 }}
              />
            )}
            {/* <img src={profiledata?.image} style={{ height: 105, width: 105 }} /> */}
            <div style={{ marginLeft: 70 }}>
              <div className="Email_Address_txt">Full Name</div>
              <div className="clicnic-name">
                {profiledata?.firstName + " " + profiledata?.lastName}
              </div>

              <div className="Email_Address_txt">Unique ID</div>
              <span style={{ display: "flex" }}>
                (P-{profiledata?.uniqueId})
              </span>
            </div>
          </div>
          {/* <div style={{width:'100%',marginTop:20,alignItems:'flex-start',display:'flex'}}>
                    <p className='Contact_Information_hhh'>Contact Information :</p>
                    </div> */}
          <div style={{ paddingLeft: 5 }}>
            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Email Address</div>
                <div className="emaill">{profiledata?.email}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Phone Number</div>
                <div className="emaill">{profiledata?.phone}</div>
              </div>
            </div>

            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Gender</div>
                <div className="emaill">{profiledata?.gender}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">City</div>
                <div className="emaill">{profiledata?.address?.city}</div>
              </div>
            </div>

            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Country</div>
                <div className="emaill">{profiledata?.address?.country}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">State</div>
                <div className="emaill">{profiledata?.address?.state}</div>
              </div>
            </div>
            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Street</div>
                <div className="emaill">{profiledata?.address?.street}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">ZipCode</div>
                <div className="emaill">{profiledata?.address?.zipCode}</div>
              </div>
            </div>
            <div className="space-div">
              {/* <div style={{ width: "50%" }}>
                <div className="Email_Address_txt" >ZipCode</div>
                <div className="emaill">{profiledata?.address?.zipCode}</div>
              </div> */}
            </div>
            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Service Type</div>
                <div className="emaill">
                  {" "}
                  {userData?.TreatmentTypesId?.map((item, index) => (
                    <div key={index}>{item.name}</div>
                  ))}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Payment Source</div>
                <div className="emaill">{userData?.PaymentSourcesId?.name}</div>
              </div>
            </div>
            {profiledata?.paymentSource === "Insurance" && (
              <>
                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Policy/Member Number
                    </div>
                    <div className="emaill">{profiledata?.policyNo}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Group Number</div>
                    <div className="emaill">{profiledata?.groupNumber}</div>
                  </div>
                </div>
                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Subscription Name</div>
                    <div className="emaill">
                      {profiledata?.subscriptionName}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Insurance</div>
                    <div className="emaill">
                      {" "}
                      {userData?.insuranceId?.map((insurance, index) => (
                        <span key={index}>
                          {insurance.name}
                          {index < userData.insuranceId.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div style={{ width: "50%", marginTop: 30 }}>
              <div className="Email_Address_txt">
                Clinic Association Request
              </div>
              <div className="emaill">
                {profiledata?.agencyIds.length > 0
                  ? `${profiledata?.agencyIds[0].firstName} ${profiledata?.agencyIds[0].lastName}`
                  : "NA"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientProfile;
