import React from 'react'
import './Notes.css'

function CrisisInterventionNote({ setOpenModel }) {
  return (
    <div className="modal-container">
      {/* Modal Header */}
      <div className="modal-header">Crisis Intervention Note</div>
      {/* Patient Information */}
      <div className="section">
        <div className="section-header">Patient Information</div>
        <div className="section-content">
          <label htmlFor="patient-name">Patient Name</label>
          <input
            type="text"
            id="patient-name"
            defaultValue="John Doe"
            readOnly=""
          />
          <label htmlFor="dob">Date of Birth</label>
          <input type="date" id="dob" defaultValue="1985-06-15" readOnly="" />
          <label htmlFor="visit-date">Date of Visit</label>
          <input
            type="date"
            id="visit-date"
            defaultValue="2025-01-21"
            readOnly=""
          />
          <label htmlFor="clinician-name">Clinician Name</label>
          <input
            type="text"
            id="clinician-name"
            defaultValue="Dr. Jane Smith"
            readOnly=""
          />
        </div>
      </div>
      {/* Description of Crisis */}
      <div className="section">
        <div className="section-header">Description of Crisis</div>
        <textarea
          id="crisis-description"
          placeholder="Describe the crisis situation, including triggering events..."
          defaultValue={""}
        />
      </div>
      {/* Interventions Provided */}
      <div className="section">
        <div className="section-header">Interventions Provided</div>
        <textarea
          id="interventions"
          placeholder="Document interventions and support provided during the crisis..."
          defaultValue={""}
        />
      </div>
      {/* Patient Response */}
      <div className="section">
        <div className="section-header">Patient Response</div>
        <textarea
          id="patient-response"
          placeholder="Describe the patient's response to the interventions..."
          defaultValue={""}
        />
      </div>
      {/* Risk Assessment */}
      <div className="section">
        <div className="section-header">Risk Assessment</div>
        <textarea
          id="risk-assessment"
          placeholder="Evaluate risks such as suicidal or homicidal ideation..."
          defaultValue={""}
        />
      </div>
      {/* Follow-Up Plan */}
      <div className="section">
        <div className="section-header">Follow-Up Plan</div>
        <textarea
          id="follow-up-plan"
          placeholder="Outline the plan for follow-up care and additional support..."
          defaultValue={""}
        />
      </div>
      {/* Signature Confirmation */}
      <div className="section">
        <div className="section-header">Signature Confirmation</div>
        <div className="section-content">
          <label>
            <input type="checkbox" id="signature-confirmation" />
            By checking this box, I confirm that I have reviewed this document.
          </label>
        </div>
      </div>
      {/* Save Button */}

      <div className='divBtn'>
        <button className="button" onclick="saveCrisisNote()">
          Save Crisis Intervention Note
        </button>
        <button className="buttonBack" onClick={() => setOpenModel(null)}>
          Back
        </button>
      </div>


    </div>

  )
}

export default CrisisInterventionNote