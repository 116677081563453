// import React from 'react'

// const AddContract = () => {
//   return (
// <div>
//     hello
// </div>
//   )
// }

// export default AddContract
import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Inputbox from "../../Components/Inputbox";
import { DropDownManuCustom, DropDownManuCustomMulti } from "../../Components/DropDownButton";
import {
  AddInsuranceContract,
  AddVisitReason,
  DeleteInsuranceContract,
  DeleteVisitReason,
  EditVisitReason,
  FetchInsuranceContract,
  FetchVisitReason,
  GetInsurance,
  GetProviderType,
  Getserviceaddress,
  ImportCsvfile,
} from "../../../api/helper";
import Papa from "papaparse";
import { ShowToast } from "../../../helpers/ToastService";

const cptCodes = [
  {
    _id: "99201  -  Office or other outpatient visit for a new patient, evaluation and management",
    name: "99201  -  Office or other outpatient visit for a new patient, evaluation and management ",
    description:
      "Office or other outpatient visit for a new patient, evaluation and management",
  },
  {
    _id: "99202  -  Office or other outpatient visit for a new patient, typically 15-29 minutes",
    name: "99202  -  Office or other outpatient visit for a new patient, typically 15-29 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 15-29 minutes",
  },
  {
    _id: "99203  -  Office or other outpatient visit for a new patient, typically 30-44 minutes",
    name: "99203  -  Office or other outpatient visit for a new patient, typically 30-44 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 30-44 minutes",
  },
  {
    _id: "99204  -  Office or other outpatient visit for a new patient, typically 45-59 minutes",
    name: "99204  -  Office or other outpatient visit for a new patient, typically 45-59 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 45-59 minutes",
  },
  {
    _id: "90834  -  Psychotherapy, 45 minutes with patient",
    name: "90834  -  Psychotherapy, 45 minutes with patient",
    description: "Psychotherapy, 45 minutes with patient",
  },
  {
    _id: "90837  -  Psychotherapy, 60 minutes with patient",
    name: "90837  -  Psychotherapy, 60 minutes with patient",
    description: "Psychotherapy, 60 minutes with patient",
  },
];
const validationSchema = Yup.object().shape({
  // ProviderType: Yup.array()
  // .of(Yup.string().required("A Provider Type must be selected"))
  // .min(1, "At least one Provider Type is required")
  // .required("Provider Type is required"),
  ProviderType: Yup.array()
    .of(Yup.string().required("A Provider Type must be selected"))
    .min(1, "At least one Provider Type is required")
    .required("Provider Type is required"),
  // CPTCode: Yup.array()
  //   .of(Yup.string().required("A CPT Code must be selected"))
  //   .min(1, "At least one CPT Code is required")
  //   .required("CPT Code is required"),
  CPTCode:Yup.string().required("CPT Code is required"),
  minRate: Yup.string().required("Contracted Rate (Min) is required"),
  maxRate: Yup.string().required("Contracted Rate (Max) is required"),
  BillingRate: Yup.string().required("Billing Rate is required"),
  // BillingRate: Yup.string().when('isMultipleCPT', {
  //   is: 1,
  //   then: Yup.string().required("Billing Rate is required"),
  //   otherwise: Yup.string().nullable(),
  // }),
  // ContractedRate: Yup.string().when('isMultipleCPT', {
  //   is: 1,
  //   then: Yup.string().required("Contracted Rate is required"),
  //   otherwise: Yup.string().nullable(),
  // }),
  // BillingRate: Yup.string().required("Billing Rate is required"),

  ContractedRate: Yup.string().required("Contracted Rate is required"),
});
const AddContract = () => {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState([]);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const [providerType, setproviderType] = useState([]);
  const [insurance, setInsurance] = useState([]);
  const [activeInsurance, setActiveInsurance] = useState("");
  const [activeState, setActiveState] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [jsonData, setJsonData] = useState(null);
  const [isMultipleCPT, setIsMultipleCPT] = useState(0);
  const [addressData, setaddressData] = useState([])
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  console.log(isMultipleCPT, "isMultipleCPT========");
  // console.log(insurance?.adminInsurenceId?.PayerName,"insurance?.adminInsurenceId?.PayerName===")
  const Getinsurancedata = async () => {
    const response = await GetInsurance(`?id=${profileData?._id}`);
    setInsurance(response?.data?.data);
  };
  useEffect(() => {
    Getinsurancedata();
  }, []);
  // console.log(insurance, "insur=====");
  const handleTabClick = (label, type) => {
    if (type === "insurance") {
      setActiveInsurance(label);
    } else {
      setActiveState(label);
    }
  };
  const openModal = () => {
    setShowModal(true);
    setIsEditing(false); // Default to add mode
    reset(); // Clear form data
  };

  const closeModal = () => setShowModal(false);
  // console.log(csvData,"csvData======")
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const FetchAddresslist = async () => {
    const data ={
      id:profiledata?._id
    }
    try {
      const response = await Getserviceaddress(data);
      console.log(response?.data?.data?.data, "hello======");
      setaddressData(response?.data?.data?.data || [])
     
    } catch (error) {
      console.log(error);
    }
  };
  const GetVisitReason = async () => {
    const response = await FetchInsuranceContract(`?id=${profileData?._id}`);
    // console.log(
    //   response?.data?.data?.insuranceContracts,
    //   "response?.data?.data-====="
    // );
    setData(response?.data?.data?.insuranceContracts || []); // Assuming the data is in `insuranceContracts`
  };
  // console.log(data, "data======");
  const fetchProviderType = async (id) => {
    const response = await GetProviderType(`?id=${profileData?._id}`);
    setproviderType(response?.data?.data || []);
  };

  useEffect(() => {
    GetVisitReason();
    fetchProviderType();
    FetchAddresslist()
  }, []);

  const onSubmit = async (formData) => {
    if (activeInsurance?.adminInsurenceId?.PayerName === undefined) {
      ShowToast("Please Select Insurance Name");
      return;
    }
    if (activeState === "") {
      ShowToast("Please Select State Area");
      return;
    }
    formData.agencyId = profileData?._id;
    formData.Insurance = activeInsurance?.adminInsurenceId?.PayerName;
    formData.stateArea = activeState;
    // console.log(formData, "formData====");
    // closeModal()
    // return;
    try {
      // Add case
      const response = await AddInsuranceContract(formData);
      // console.log(response?.data?.data,"respo======")
      ShowToast(response?.data?.message);

      reset();
      setActiveInsurance("");
      setActiveState("");
      GetVisitReason();
      setShowModal(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleExportToCSV = () => {
    if (data?.length > 0) {
      // Format the data for CSV export
      const formattedData = data.map((row) => ({
        "Provider Type":
          row.ProviderType?.map((type) => type.name).join(", ") || "N/A",
        "CPT Code": row.CPTCode?.join("; ") || "N/A",
        "Maximum Completion Time": row.maxRate || "N/A",
        "Minimum Completion Time": row.minRate || "N/A",
        Insurance: row.Insurance || "N/A",
        "Billing Rate ($)": row.BillingRate || "N/A",
        "Contracted Rate ($)": row.ContractedRate || "N/A",
        "State Area": row.stateArea || "N/A",
      }));

      // Convert JSON to CSV using PapaParse
      const csv = Papa.unparse(formattedData);

      // Trigger file download
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "data_export.csv"; // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("No data to export!");
    }
  };

  // console.log(data,"data===>>>>>>>>>>")
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this Contract?"
    );
    if (!confirmed) return;

    try {
      const response = await DeleteInsuranceContract(`?id=${id}`);
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        GetVisitReason();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };

  // const handleCptcode = (selectedOptions) => {
  //   const ids = selectedOptions?.map((e) => e?._id);
  //   setValue("CPTCode", ids, { shouldValidate: true });
  //   setIsMultipleCPT(ids.length > 1);
  // };
  const handleCptcode = (selectedOptions) => {
    const ids = selectedOptions?.map((e) => e?._id);
    setValue("CPTCode", ids, { shouldValidate: true });

    setIsMultipleCPT(ids.length);
  };

  const handleSelectProviderType = (selectedOptions) => {
    console.log(selectedOptions, "selectedOptions=====ProviderType");
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("ProviderType", ids, { shouldValidate: true });
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          processCsvData(result.data); // Process the parsed CSV data
        },
        error: (err) => {
          console.error("Error parsing CSV:", err);
        },
      });

      // Reset the file input value
      event.target.value = null;
    }
  };

  const processCsvData = async (data) => {
    const transformedDataArray = data.map((row) => ({
      CPTCode: row["CPT Code"]
        ?.split(",")
        .map((item) => item.trim())
        .filter(Boolean),
      maxRate: row["Maximum Completion Time"] || "",
      minRate: row["Minimum Completion Time"] || "",
      Insurance: row["Insurance"] || "",
      BillingRate: row["Billing Rate ($)"] || "",
      ContractedRate: row["Contracted Rate ($)"] || "",
      agencyId: profileData?._id || "",
      stateArea: row["State Area"] || "",
    }));

    console.log(transformedDataArray, "hello---------json array==>>>>");
    setCsvData(data);
    setJsonData(transformedDataArray);

    try {
      const response = await ImportCsvfile(transformedDataArray);
      console.log(response?.data?.data, "respo======csv==>>>>>");
      ShowToast(response?.data?.message);
      setJsonData(null);
      setCsvData([]);
      GetVisitReason();
    } catch (error) {
      console.error("Error importing CSV data:", error);
    }
  };

  return (
    <div style={{ width: "100%", alignItems: "center", marginBottom: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Contract</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
            cursor: "pointer",
          }}
          onClick={openModal}
        >
          Add Contract
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "yellow" }}>
            <tr>
              <th>Provider Type</th>
              <th>CPT Code</th>
              <th>Minimum Completion Time</th>
              <th>Maximum Completion Time </th>
              <th>Billing Rate ($)</th>
              <th>Contracted Rate ($)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.length === 0 ? (
              <tr>
                <td
                  colSpan="5"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  No Contract Found
                </td>
              </tr>
            ) : (
              data.map((row) => (
                <tr key={row._id} style={tableRowStyle}>
                  <td style={tableCellStyle}>
                    {" "}
                    {row.ProviderType?.map((type, index) => (
                      <span key={type._id}>
                        {type.name}
                        {index < row.ProviderType.length - 1 && ", "}
                      </span>
                    )) || "N/A"}
                  </td>
                  <td style={tableCellStyle}>{row.CPTCode}</td>
                  <td style={tableCellStyle}>{row.minRate}</td>
                  <td style={tableCellStyle}>{row.maxRate}</td>
                  <td style={tableCellStyle}>{row.BillingRate}</td>
                  <td style={tableCellStyle}>{row.ContractedRate}</td>
                  <td style={tableCellStyle}>
                    <button
                      style={deleteButtonStyle}
                      onClick={() => handleDelete(row._id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <div>
          <label
            htmlFor="file-upload"
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#011434",
              color: "white",
              borderRadius: 10,
              textAlign: "center",
              marginBottom: 20,
              cursor: "pointer", // Add pointer cursor for a button-like feel
            }}
          >
            Import CSV
          </label>

          {/* Hidden input for file upload */}
          <input
            id="file-upload"
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
        </div>
        <div
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#011434",
            color: "white",
            borderRadius: 10,
          }}
          onClick={handleExportToCSV}
        >
          {" "}
          Export to CSV
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tabs-container_two">
              <div className="insurance-tabs-row">
                {insurance.map((insurance) => (
                  <button
                    key={insurance}
                    className={`insurance-tab ${
                      activeInsurance === insurance ? "insurance-active" : ""
                    }`}
                    onClick={() => handleTabClick(insurance, "insurance")}
                  >
                    {insurance?.adminInsurenceId?.PayerName}
                  </button>
                ))}
              </div>
            </div>
            <div className="state-tabs-row" style={{ marginTop: 10 }}>
              {addressData?.map((state) => 
                // console.log(state?.name,"addresss======")
              // return
              (
                <button
                  key={state}
                  className={`state-tab ${
                    activeState === state ? "state-active" : ""
                  }`}
                  onClick={() => handleTabClick(state, "state")}
                >
                  {state?.name}
                </button>
              )
              
              )}
            </div>
            <h1 style={{ textAlign: "center", marginTop: 10 }}>Add Contract</h1>
            <DropDownManuCustomMulti
              label="Provider Type"
              placeholder="Select option"
              options={providerType}
              name="ProviderType"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={handleSelectProviderType}
            />
            <p style={{ color: "red" }}>{errors.ProviderType?.message}</p>

            <DropDownManuCustom
              label="CPT Code"
              options={cptCodes}
              name="CPTCode"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              // onSelect={handleCptcode}
            />
            <p style={{ color: "red" }}>{errors.CPTCode?.message}</p>

           
           
           
              <>
               <Inputbox
              label="Minimum Completion Time"
              type="number"
              name="minRate"
              register={register}
              placeholder="Enter Duration in Minutes"
            />
            <p style={{ color: "red" }}>{errors.minRate?.message}</p>
            <Inputbox
              label="Maximum Completion Time"
              type="number"
              name="maxRate"
              register={register}
              placeholder="Enter Duration in Minutes"
            />
             <p style={{ color: "red" }}>{errors.maxRate?.message}</p>
                <Inputbox
                  label="Billing Rate ($)"
                  type="number"
                  name="BillingRate"
                  register={register}
                  placeholder="Enter Duration in Minutes"
                />
                <p style={{ color: "red" }}>{errors.BillingRate?.message}</p>
              
              </>
           
              <Inputbox
                  label="Contracted Rate ($)"
                  type="number"
                  name="ContractedRate"
                  register={register}
                  placeholder="Enter Duration in Minutes"
                />
                <p style={{ color: "red" }}>{errors.ContractedRate?.message}</p>
            <button type="submit" className="Save_buttonnn">
              {"Save"}
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddContract;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "78vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto",
};
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  fontWeight: "bold",
  backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};
const editButtonStyle = {
  backgroundColor: "darkblue",
  marginBottom: 3,
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
