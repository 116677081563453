import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PatientUpdate } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

const validationSchema = yup.object({
});

function PatientNotification({ info,getprofile }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  const [toggles, setToggles] = useState({
    emailNotifications: info?.Notifications?.emailNotifications || false,
    textMessage: info?.Notifications?.textMessage || false,
    therapyFollowUp: info?.Notifications?.therapyFollowUp || false,
    mentalHealthCheckIn: info?.Notifications?.mentalHealthCheckIn || false,
    selfCareReminder: info?.Notifications?.selfCareReminder || false,
    prescriptionReminder: info?.Notifications?.prescriptionReminder || false,
    labResults: info?.Notifications?.labResults || false,
    billingReminder: info?.Notifications?.billingReminder || false,
    lifestyleGoals: info?.Notifications?.lifestyleGoals || false,
    crisisIntervention: info?.Notifications?.crisisIntervention || false,
    groupTherapyReminder: info?.Notifications?.groupTherapyReminder || false,
    motivationalMessages: info?.Notifications?.motivationalMessages || false,
    appointmentPrep: info?.Notifications?.appointmentPrep || false,
    medicationReminder: info?.Notifications?.medicationReminder || false,
    behavioralHealthProgress: info?.Notifications?.behavioralHealthProgress || false,
    healthyEatingTips: info?.Notifications?.healthyEatingTips || false,
    triggerManagement: info?.Notifications?.triggerManagement || false,
    documentUpload: info?.Notifications?.documentUpload || false,
    carePlanUpdate: info?.Notifications?.carePlanUpdate || false,
    followUpReminder: info?.Notifications?.followUpReminder || false,
    appointmentFeedback: info?.Notifications?.appointmentFeedback || false,
    missedAppointmentFollowUp: info?.Notifications?.missedAppointmentFollowUp || false,
    stressManagement: info?.Notifications?.stressManagement || false,
    meditationBreathingAlerts: info?.Notifications?.meditationBreathingAlerts || false,
    positiveReinforcement: info?.Notifications?.positiveReinforcement || false,
    mindfulnessPractice: info?.Notifications?.mindfulnessPractice || false,
  });

  const handleToggle = (key) => {
    setToggles((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  // Submit handler
  const onSubmit = async (data) => {
    const newData = {
      Notifications: {
        ...toggles,
        ...data
      },
      id: info?._id
    }
    console.log(newData, '==>>newData')

    try {
      const response = await PatientUpdate(newData)
      // console.log(response?.data?.data, "data======resp[o=========")
      ShowToast(response?.data?.message)
      getprofile()
    } catch (error) {
      console.log(error, '==>>')
    }



  };

  return (
    <div className="notifications">
      <h2 className="notifications__title">Notifications</h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="notifications__section">
          <h3 className="notifications__subtitle">
            Where should your patient receive notifications?
          </h3>

          <div className="notifications__row">
            {/* Email Input */}
            <div className="notifications__input-group">
              <label className="notifications__label">Email</label>
              <input
                type="email"
                className="notifications__input"
                defaultValue={info?.Notifications?.email}
                {...register("email")}
              />
              {errors.email && <p className="error">{errors.email.message}</p>}
              <div className="notifications__toggle">
                <button
                  type="button"
                  className={`notifications__toggle-btn ${toggles.emailNotifications ? "notifications__toggle-btn--active" : ""
                    }`}
                  onClick={() => handleToggle("emailNotifications")}
                >
                  ON
                </button>
                <button
                  type="button"
                  className={`notifications__toggle-btn ${!toggles.emailNotifications ? "notifications__toggle-btn--active" : ""
                    }`}
                  onClick={() => handleToggle("emailNotifications")}
                >
                  OFF
                </button>
              </div>
            </div>

            {/* Phone Input */}
            <div className="notifications__input-group">
              <label className="notifications__label">Text Message</label>
              <input
                type="text"
                className="notifications__input"
                defaultValue={info?.Notifications?.text}
                {...register("text")}
              />
              {errors.phone && <p className="error">{errors.phone.message}</p>}
              <div className="notifications__toggle">
                <button
                  type="button"
                  className={`notifications__toggle-btn ${toggles.textMessage ? "notifications__toggle-btn--active" : ""
                    }`}
                  onClick={() => handleToggle("textMessage")}
                >
                  ON
                </button>
                <button
                  type="button"
                  className={`notifications__toggle-btn ${!toggles.textMessage ? "notifications__toggle-btn--active" : ""
                    }`}
                  onClick={() => handleToggle("textMessage")}
                >
                  OFF
                </button>
              </div>
            </div>
          </div>

          <p className="notifications__consent-text">
            By adding an email/phone number, you are asserting patient consent
            to communicate through those channels.
          </p>
        </div>

        {/* Notifications Options */}
        <div className="notifications__section">
          <h3 className="notifications__subtitle">
            Which notification(s) should your patient receive?
          </h3>

          {/* Notification Options */}
          <div className="notifications__options-grid">
            {[
              "therapyFollowUp",
              "mentalHealthCheckIn",
              "selfCareReminder",
              "prescriptionReminder",
              "labResults",
              "billingReminder",
              "lifestyleGoals",
              "crisisIntervention",
              "groupTherapyReminder",
              "motivationalMessages",
              "appointmentPrep",
              "medicationReminder",
              "behavioralHealthProgress",
              "healthyEatingTips",
              "triggerManagement",
              "documentUpload",
              "carePlanUpdate",
              "followUpReminder",
              "appointmentFeedback",
              "missedAppointmentFollowUp",
              "stressManagement",
              "meditationBreathingAlerts",
              "positiveReinforcement",
              "mindfulnessPractice",
            ].map((notificationKey, index) => (
              <div
                key={notificationKey}
                className="notifications__option"
                style={{ width: "33.33%" }} // 3 options per row
              >
                <label style={{ textAlign: "left", width: "100%" }}>
                  {notificationKey
                    .split(/(?=[A-Z])/)
                    .join(" ")
                    .toUpperCase()}
                </label>
                <div style={{ alignSelf: "flex-start" }}>
                  <button
                    type="button"
                    className={`notifications__toggle-btn ${toggles[notificationKey] ? "notifications__toggle-btn--active" : ""
                      }`}
                    onClick={() => handleToggle(notificationKey)}
                  >
                    ON
                  </button>
                  <button
                    type="button"
                    className={`notifications__toggle-btn ${!toggles[notificationKey] ? "notifications__toggle-btn--active" : ""
                      }`}
                    onClick={() => handleToggle(notificationKey)}
                  >
                    OFF
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Action Buttons */}
        <div className="client-history__buttons">
          <button
            type="button"
            className="client-history__button client-history__button--cancel"
            onClick={() => reset()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="client-history__button client-history__button--save"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default PatientNotification;
