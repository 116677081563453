import React, { useState } from 'react'

const MemberPlan = () => {
  const [activeTab, setActiveTab] = useState('biweekly');
  const [enrollModalData, setEnrollModalData] = useState(null);
  const [detailsModalData, setDetailsModalData] = useState(null);

  // Function to switch tabs
  const switchTab = (tab) => {
    setActiveTab(tab);
  };

  // Function to open the enrollment modal
  const openEnrollModal = (planName, price) => {
    console.log(planName,price,"hello==========")
    setEnrollModalData({ planName, price });
  };

  // Function to close the enrollment modal
  const closeEnrollModal = () => {
    setEnrollModalData(null);
  };

  // Function to open the details modal
  const openDetailsModal = (planName, price, frequency, services) => {
    setDetailsModalData({ planName, price, frequency, services });
  };

  // Function to close the details modal
  const closeDetailsModal = () => {
    setDetailsModalData(null);
  };
  return (
 <div>
<>
<div style={{width:'100%',alignItems:'center',justifyContent:'center',display:'flex',flexDirection:'column'}}>
<div className="tabs_MemberPlan">
        {['biweekly', 'monthly', 'biannually', 'annually'].map((tab) => (
          <button
            key={tab}
            className={`tab-button_MemberPlan ${activeTab === tab ? 'active' : ''}`}
            onClick={() => switchTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>
      </div>
      <div className={`plans-container_MemberPlan ${activeTab === 'biweekly' ? 'active' : ''}`}>
    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Medical Care</div>
      <div className="plan-frequency_MemberPlan">Charged biweekly</div>
      <div className="plan-price_MemberPlan">$95 / month</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas_MemberPlan fa-user-md" />1 Doctor Consultation per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-truck" />
          Free Medication Delivery
        </li>
        <li>
          <i className="fas_MemberPlan fa-headset" />
          24/7 Support
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          onClick={() => openEnrollModal('Medical Care', '$95')}
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"
          onClick={() =>
            openDetailsModal('Medical Care', '$95', 'Biweekly', [
              { service: 'Doctor Consultation', unit: '1' },
              { service: 'Medication Delivery', unit: 'Unlimited' },
              { service: '24/7 Support', unit: 'Unlimited' },
            ])
          }
        >
          View Details
        </button>
      </div>
    </div>
  </div>
  <div className={`plans-container_MemberPlan ${activeTab === 'monthly' ? 'active' : ''}`}>
 
    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Therapy</div>
      <div className="plan-frequency_MemberPlan">Charged monthly</div>
      <div className="plan-price_MemberPlan">$245 / month</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas_MemberPlan fa-user-md" />1 Doctor Consultation per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-comments" />2 Therapy Sessions per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-headset" />
          24/7 Support
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          // onclick="openEnrollModal('Therapy', '$245')"
          onClick={() => openEnrollModal('Therapy', '$245')}
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"
        
          onClick={() =>
            openDetailsModal('Therapy', '$245', 'Monthly', [
              {service: 'Doctor Consultation', unit: '1'},
              {service: 'Therapy Sessions', unit: '2'},
              {service: '24/7 Support', unit: 'Unlimited'}
          ])
          }
        >
          View Details
        </button>
      </div>
    </div>
    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Psychiatry</div>
      <div className="plan-frequency_MemberPlan">Charged monthly</div>
      <div className="plan-price_MemberPlan">$545 / month</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas_MemberPlan fa-user-md" />1 Doctor Consultation per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-comments" />2 Therapy Sessions per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-headset" />
          24/7 Support
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          // onclick="openEnrollModal('Therapy', '$345')"
          onClick={() => openEnrollModal('Therapy', '$345')}
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"
       
          onClick={() =>
            openDetailsModal('Therapy', '$345', 'Monthly', [
              {service: 'Doctor Consultation', unit: '1'},
              {service: 'Therapy Sessions', unit: '2'},
              {service: '24/7 Support', unit: 'Unlimited'}
          ])
          }
        >
          View Details
        </button>
      </div>
    </div>
    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Psychaitry + Therapy</div>
      <div className="plan-frequency_MemberPlan">Charged monthly</div>
      <div className="plan-price_MemberPlan">$445 / month</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas_MemberPlan fa-user-md" />1 Doctor Consultation per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-comments" />2 Therapy Sessions per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-headset" />
          24/7 Support
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          // onclick="openEnrollModal('Therapy', '$445')"
          onClick={() => openEnrollModal('Therapy', '$445')}
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"
        
          onClick={() =>
            openDetailsModal('Therapy', '$345', 'Monthly', [
              {service: 'Doctor Consultation', unit: '1'},
              {service: 'Therapy Sessions', unit: '2'},
              {service: '24/7 Support', unit: 'Unlimited'}
          ])
          }
        >
          View Details
        </button>
      </div>
    </div>
  </div>
  <div className={`plans-container_MemberPlan ${activeTab === 'biannually' ? 'active' : ''}`}>
 
    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Psychiatry + Therapy</div>
      <div className="plan-frequency_MemberPlan">Charged biannually</div>
      <div className="plan-price_MemberPlan">$360 / month</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas fa-user-md_MemberPlan" />1 Doctor Consultation per Month
        </li>
        <li>
          <i className="fas fa-comments_MemberPlan" />3 Therapy Sessions per Month
        </li>
        <li>
          <i className="fas_MemberPlan fa-truck" />
          Free Medication Delivery
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          // onclick="openEnrollModal('Psychiatry + Therapy', '$360')"
          onClick={() => openEnrollModal('Psychiatry + Therapy', '$360')}
         
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"
      
          onClick={() =>
            openDetailsModal('Psychiatry + Therapy', '$360', 'Biannually', [
              {service: 'Doctor Consultation', unit: '1'},
              {service: 'Therapy Sessions', unit: '3'},
              {service: 'Medication Delivery', unit: 'Unlimited'}
          ])
          }
        >
          View Details
        </button>
      </div>
    </div>
  </div>
  <div className={`plans-container_MemberPlan ${activeTab === 'annually' ? 'active' : ''}`}>

    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Therapy</div>
      <div className="plan-frequency_MemberPlan">Charged annually</div>
      <div className="plan-price_MemberPlan">$499 / year</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas_MemberPlan fa-user-md" />
          12 Therapy Sessions
        </li>
        <li>
          <i className="fas_MemberPlan fa-heartbeat" />
          Comprehensive Wellness Programs
        </li>
        <li>
          <i className="fas_MemberPlan fa-headset" />
          Priority Support
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          // onclick="openEnrollModal('Comprehensive Care', '$499')"
          onClick={() => openEnrollModal('Comprehensive Care', '$499')}
         
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"
   
          onClick={() =>
            openDetailsModal('Comprehensive Care', '$499', 'Annually', [
              {service: 'Therapy Sessions', unit: 'Unlimited'},
              {service: 'Wellness Programs', unit: '1'},
              {service: 'Priority Support', unit: 'Unlimited'}
          ])
          }
        >
          View Details
        </button>
      </div>
    </div>
    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Psychiatry</div>
      <div className="plan-frequency_MemberPlan">Charged annually</div>
      <div className="plan-price_MemberPlan">$799 / year</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas_MemberPlan fa-user-md" />
          Unlimited Therapy Sessions
        </li>
        <li>
          <i className="fas_MemberPlan fa-heartbeat" />
          Comprehensive Wellness Programs
        </li>
        <li>
          <i className="fas_MemberPlan fa-headset" />
          Priority Support
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          // onclick="openEnrollModal('Comprehensive Care', '$599')"
          onClick={() => openEnrollModal('Comprehensive Care', '$599')}
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"
     
          onClick={() =>
            openDetailsModal('Comprehensive Care', '$599', 'Annually', [
              {service: 'Therapy Sessions', unit: 'Unlimited'},
              {service: 'Wellness Programs', unit: '1'},
              {service: 'Priority Support', unit: 'Unlimited'}
          ])
          }
        >
          View Details
        </button>
      </div>
    </div>
    <div className="plan-card_MemberPlan">
      <div className="plan-title_MemberPlan">Comprehensive Care</div>
      <div className="plan-frequency_MemberPlan">Charged annually</div>
      <div className="plan-price_MemberPlan">$799 / year</div>
      <ul className="features_MemberPlan">
        <li>
          <i className="fas_MemberPlan fa-user-md" />
          Unlimited Therapy Sessions
        </li>
        <li>
          <i className="fas_MemberPlan fa-heartbeat" />
          Comprehensive Wellness Programs
        </li>
        <li>
          <i className="fas_MemberPlan fa-headset" />
          Priority Support
        </li>
      </ul>
      <div className="cta-buttons_MemberPlan">
        <button
          className="cta-button_MemberPlan"
          // onclick="openEnrollModal('Comprehensive Care', '$799')"
          onClick={() => openEnrollModal('Comprehensive Care', '$799')}
        >
          Enroll
        </button>
        <button
          className="cta-button_MemberPlan details-button"

     onClick={() =>
            openDetailsModal('Comprehensive Care', '$799', 'Annually', [
              {service: 'Therapy Sessions', unit: 'Unlimited'},
              {service: 'Wellness Programs', unit: '1'},
              {service: 'Priority Support', unit: 'Unlimited'}
          ])
          }
        >
          View Details
        </button>
      </div>
    </div>
  </div>
  {/* Enrollment Modal */}
  {enrollModalData && (
        <div className="modal_MemberPlan">
          <div className="modal-content_MemberPlan">
            <h2>Enroll in Plan</h2>
            <p>
              <strong>Plan:</strong> {enrollModalData.planName}
            </p>
            <p>
              <strong>Price:</strong> {enrollModalData.price}
            </p>
            <div className="payment-options_MemberPlan">
              <div></div>
            <input type="radio" name="payment-method" value="card1" /> 
              <label>
              Card ending in 1234
              </label>
              <label>
                <input type="radio" name="payment-method" value="card2" /> Card ending in 5678
              </label>
              <label>
                <input type="radio" name="payment-method" value="new" id="new-card-option" /> Add
                New Card
              </label>
            </div>
            <div id="card-element_MemberPlan" className="element_MemberPlan"style={{ display: 'none' }} />
            <button className="cta-button_MemberPlan">Confirm Enrollment</button>
            <button className="close-button_MemberPlan" onClick={closeEnrollModal}>
              Cancel
            </button>
          </div>
        </div>
      )}

  {/* Details Modal */}
  {detailsModalData && (
        <div className="modal_MemberPlan">
          <div className="modal-content_MemberPlan">
            <h2>{detailsModalData.planName}</h2>
            <p>
              <strong>Price:</strong> {detailsModalData.price}
            </p>
            <p>
              <strong>Frequency:</strong> {detailsModalData.frequency}
            </p>
            <table>
              <thead>
                <tr>
                  <th>Services</th>
                  <th>Units</th>
                </tr>
              </thead>
              <tbody>
                {detailsModalData.services.map((service, index) => (
                  <tr key={index}>
                    <td>{service.service}</td>
                    <td>{service.unit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="close-button_MemberPlan" onClick={closeDetailsModal}>
              Close
            </button>
          </div>
        </div>
      )}
</>


 </div>
  )
}

export default MemberPlan