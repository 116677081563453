import React, { useState } from "react";
import InsuranceDetails from "./InsuranceScreens/InsuranceDetails";
import ConditionsScreen from "./InsuranceScreens/ConditionsScreen";
import AuthorizationsScreen from "./InsuranceScreens/AuthorizationsScreen";
import OtherInfo from "./InsuranceScreens/OtherInfo";
import EligibilityChecks from "./InsuranceScreens/EligibilityChecks";

function PatientInsurence({info,getDteails}) {
  const [activeTab, setActiveTab] = useState("insuranceDetails");

  const openTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {/* Insurance Details Section with Tabs */}
      <div
        id="insurance-section"
        //  style={{ display: "none" }}
      >
        <h2>Insurance Details</h2>
        <div>
          <div className="tabs">
            <button
              className={`tablinks ${
                activeTab === "insuranceDetails" ? "active" : ""
              }`}
              onClick={() => openTab("insuranceDetails")}
            >
              Insurance Details
            </button>
            <button
              className={`tablinks ${
                activeTab === "conditions" ? "active" : ""
              }`}
              onClick={() => openTab("conditions")}
            >
              Conditions
            </button>
            <button
              className={`tablinks ${
                activeTab === "authorizations" ? "active" : ""
              }`}
              onClick={() => openTab("authorizations")}
            >
              Authorizations
            </button>
            <button
              className={`tablinks ${
                activeTab === "otherInfo" ? "active" : ""
              }`}
              onClick={() => openTab("otherInfo")}
            >
              Other Info
            </button>
            <button
              className={`tablinks ${
                activeTab === "EligibilityChecks" ? "active" : ""
              }`}
              onClick={() => openTab("EligibilityChecks")}
            >
              Eligibility Checks History
            </button>
          </div>

          <div
            className="tabcontent"
            style={{
              display: activeTab === "insuranceDetails" ? "block" : "none",
            }}
          >
            <InsuranceDetails info={info} />
          </div>

          <div
            className="tabcontent"
            style={{ display: activeTab === "conditions" ? "block" : "none" }}
          >
            <ConditionsScreen info={info}/>
          </div>

          <div
            className="tabcontent"
            style={{
              display: activeTab === "authorizations" ? "block" : "none",
            }}
          >
            {/* Content for Authorizations */}
            <AuthorizationsScreen  info={info}/>
          </div>
          <div
            className="tabcontent"
            style={{ display: activeTab === "otherInfo" ? "block" : "none" }}
          >
            <OtherInfo />
          </div>
          <div
            className="tabcontent"
            style={{
              display: activeTab === "EligibilityChecks" ? "block" : "none",
            }}
          >
            <EligibilityChecks />
          </div>
        </div>
      </div>
   
    </>
  );
}

export default PatientInsurence;
