import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AgencySideBar from "../Components/AgencySideBar";
import { Navtopbar } from "../../Components/Navbar";

const Planpreview = () => {
  const location = useLocation();
  const { plan } = location.state || {};
  const navigate = useNavigate();

  console.log("Plan data:", plan);
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navtopbar
          onClick={() => {
            navigate("/MyProfileAgency");
          }}
          title="Settings"
        />

        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, flexDirection: "column" }}
        >
          <h1 style={{ textAlign: "left" }}>Agency Plan Preview</h1>
          <div className="space-div">
       
          <div style={{ width: '50%' }}>
          <div className="Email_Address_txt">Plan Name</div>
          <div className="emaill">{plan.PlanName}</div>
        </div>
        <div style={{ width: '50%' }}>
          <div className="Email_Address_txt">PlanType</div>
          <div className="emaill">{plan.planType}</div>
        </div>
       
      </div>
      
      <div className="space-div">
        <div style={{ width: '50%' }}>
          <div className="Email_Address_txt">Benefits</div>
          <div className="emaill">{plan.Benefits}</div>
        </div>
        <div style={{ width: '50%' }}>
          <div className="Email_Address_txt">Categories</div>
          <div className="emaill">
            {plan.categoryId.map((category) => category.name).join(', ')}
          </div>
        </div> 

      </div>

      <div className="space-div">
        <div style={{ width: '50%' }}>
          <div className="Email_Address_txt">Status</div>
          <div className="emaill">{plan.Status}</div>
        </div>
        <div style={{ width: '50%' }}>
          <div className="Email_Address_txt">Fee</div>
          <div className="emaill">${plan.fee}</div>
        </div>
      </div>

      <div className="space-div">
      <div style={{ width: '50%' }}>
          <div className="Email_Address_txt">Add On Services</div>
          <div className="emaill">{plan.AddOnServices}</div>
        </div>

      </div>
      <div className="space-div">
      <div style={{ width: '50%' }}>
  <div className="Email_Address_txt">Individual Items</div>
  <div className="emaill">
    {plan.AddIndividualItems && plan.AddIndividualItems.length > 0 ? (
      plan.AddIndividualItems.map((individualItem, index) => (
        <div key={individualItem._id} style={{ marginBottom: '10px' }}>
          <div>Item: {individualItem.item}</div>
          <div>Count: {individualItem.count}</div>
        </div>
      ))
    ) : (
      <div>No Individual Items Available</div>
    )}
  </div>
  </div>
</div>
        </div>
      </div>
    </div>
  );
};

export default Planpreview;
