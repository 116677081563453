import React, { useEffect, useState } from "react";
import Inputbox from "../../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AgencySidePaitentEMERcontact, AgencySidePaitentEMERcontactUpdate, Deletecontact, Fetchemergencycontact,  } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  contactName: Yup.string().required("Contact Name is required"),

  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits") 
    .required("Phone number is required"),

  relation: Yup.string().required("relation is required"),

  email: Yup.string()
    .email("Invalid email address") 
    .required("email is required"),
});

const EmergencyContacts = ({ info, getDteails }) => {
  const [ismodal, setismodal] = useState(false);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
const [contactdata, setcontactdata] = useState()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    data.id = info?._id; // Add the id from 'info'

    const formattedData = {
        contactName: data.contactName,
        phoneNumber: data.phoneNumber,
        relation: data.relation,
        email: data.email,
        patientId:info?.patientId?._id,
        agencyId:profileData?._id
    };
    try {
      const response = await AgencySidePaitentEMERcontact(formattedData);
      ShowToast(response?.data?.message);
      reset()
      setismodal(false)
      getEmergencycontact();
    } catch (error) {
    }
  };
  const getEmergencycontact=async()=>{
    try {
      const Respo = await Fetchemergencycontact(profileData?._id,info?.patientId?._id)
      setcontactdata(Respo?.data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    getEmergencycontact()
  },[])
  const handleRemove = async(id) => {
    const confirmed = window.confirm("Are you sure you want to delete this Contact?");
   if (!confirmed) {
     return;
   }
 const data ={
  contactId :id,
  agencyId:profileData?._id,
  patientId:info?.patientId?._id
 }
 try {
  const respo = await Deletecontact(data)
  ShowToast(respo?.data?.message);
  getEmergencycontact()
} catch (error) {
  console.log(error)
 }
  };

  const UpdateConatct=async(contactId, currentIsPrimary)=>{
    const isPrimary = !currentIsPrimary;
    const data ={
      contactId,
      agencyId:profileData?._id,
      patientId:info?.patientId?._id,
      isPrimary
    }
    // console.log(data,"datat====update=====")
    // return
    try {
      const respo =await AgencySidePaitentEMERcontactUpdate(data)
      // console.log(respo?.data,'respo====')
      // ShowToast(respo?.data?.message)
      getEmergencycontact()
      getDteails()
    } catch (error) {
      console.log(error)
    } 
   }

  return (
    <>
      <div style={{ padding: 20 }}>
        <h2 className="notifications__title">Emergency Contacts</h2>
        <button
          className="add-member-button"
          onClick={() => {
            setismodal(true);
          }}
        >
          Add Contact
        </button>

        <table className="table" id="member-table">
          <thead>
            <tr>
              <th>Contact Name</th>
              <th>Phone Number</th>
              <th>Relation</th>
              <th>Email</th>
              <th>Action</th>
              <th>Primary</th>
            </tr>
          </thead>
          {contactdata && contactdata.length > 0 ? contactdata?.map((contact) => (
          <tr key={contact._id}>
            <td>{contact.contactName}</td>
            <td>{contact.phoneNumber}</td>
            <td>{contact.relation}</td>
            <td>{contact.email}</td>
            <td>
            <button
              className="btn"
              style={{backgroundColor:'red'}}
              onClick={() => handleRemove(contact._id)}
            >
              Remove
            </button>
          </td>
          <td><input type="checkbox"
           checked={contact?.isPrimary}
           onChange={() => UpdateConatct(contact._id, contact.isPrimary)}
          /></td>
          </tr>
          
        )):
        (
          <p style={{textAlign:'left',marginTop:10}}>No Contact Found</p>
        )
        }
        </table>
        {ismodal && (
          <div className="modal_twooo" id="add-member-modal">
            <h2>Add Emergency Contact</h2>
            <div id="facility" className="tab-content">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="facility-search-facility">Contact Name</label>
                  <input
                    type="search"
                    id="facility-search-facility"
                    placeholder="Enter Contact Name"
                    {...register("contactName")}
                  />
                  {errors.contactName && (
                    <p className="error-message">
                      {errors.contactName.message}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="from-date-facility">Phone Number</label>
                  <input
                    type="number"
                    id="from-date-facility"
                      placeholder="Enter Phone Number"
                    {...register("phoneNumber")}
                  />
                  {errors.phoneNumber && (
                    <p className="error-message">
                      {errors.phoneNumber.message}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="to-date-facility">Relation</label>
                  <input
                    type="text"
                    id="to-date-facility"
                        placeholder="Enter Relation"
                    {...register("relation")}
                  />
                  {errors.relation && (
                    <p className="error-message">{errors.relation.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="to-date-facility">Email</label>
                  <input
                    type="text"
                    id="to-date-facility"
                        placeholder="Enter Email"
                    {...register("email")}
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email.message}</p>
                  )}
                </div>
                <div className="form-buttons">
                  <button
                    className="cancel"
                    type="button"
                    onClick={() => {
                      setismodal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="save" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EmergencyContacts;
