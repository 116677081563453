import React from 'react'

const OtherInfo = () => {
  return (
    <div id="otherInfo" className="tabcontent">
    <h3>Other Info</h3>
    <p>Additional information goes here.</p>
</div>
  )
}

export default OtherInfo