import React from 'react'
import './Notes.css'

function DischargeSummary({setOpenModel}) {
  return (
    <div className="modal-container">
      {/* Modal Header */}
      <div className="modal-header">Discharge Summary</div>
      {/* Patient Information */}
      <div className="section">
        <div className="section-header">Patient Information</div>
        <div className="section-content">
          <label htmlFor="patient-name">Patient Name</label>
          <input
            type="text"
            id="patient-name"
            defaultValue="John Doe"
            readOnly=""
          />
          <label htmlFor="dob">Date of Birth</label>
          <input type="date" id="dob" defaultValue="1985-06-15" readOnly="" />
          <label htmlFor="admission-date">Admission Date</label>
          <input
            type="date"
            id="admission-date"
            defaultValue="2025-01-01"
            readOnly=""
          />
          <label htmlFor="discharge-date">Discharge Date</label>
          <input
            type="date"
            id="discharge-date"
            defaultValue="2025-01-21"
            readOnly=""
          />
        </div>
      </div>
      {/* Reason for Admission */}
      <div className="section">
        <div className="section-header">Reason for Admission</div>
        <textarea
          id="reason-for-admission"
          placeholder="Brief description of the reason for admission..."
          defaultValue={""}
        />
      </div>
      {/* Summary of Treatment */}
      <div className="section">
        <div className="section-header">Summary of Treatment</div>
        <textarea
          id="treatment-summary"
          placeholder="Summary of treatment provided during the stay..."
          defaultValue={""}
        />
      </div>
      {/* Progress and Outcomes */}
      <div className="section">
        <div className="section-header">Progress and Outcomes</div>
        <textarea
          id="progress-outcomes"
          placeholder="Details of the patient's progress and outcomes..."
          defaultValue={""}
        />
      </div>
      {/* Medications at Discharge */}
      <div className="section">
        <div className="section-header">Medications at Discharge</div>
        <textarea
          id="medications"
          placeholder="List of medications prescribed at discharge..."
          defaultValue={""}
        />
      </div>
      {/* Follow-Up Instructions */}
      <div className="section">
        <div className="section-header">Follow-Up Instructions</div>
        <textarea
          id="follow-up"
          placeholder="Instructions for follow-up care and appointments..."
          defaultValue={""}
        />
      </div>
      {/* Signature Confirmation */}
      <div className="section">
        <div className="section-header">Signature Confirmation</div>
        <div className="section-content">
          <label>
            <input type="checkbox" id="signature-confirmation" />
            By checking this box, I confirm that I have reviewed this discharge
            summary.
          </label>
        </div>
      </div>
      {/* Save Button */}

      <div className='divBtn'>

        <button className="button" onclick="saveDischargeSummary()">
          Save Discharge Summary
        </button>
        <button className="buttonBack" onClick={() => setOpenModel(null)}>
          Back
        </button>
      </div>


    </div>

  )
}

export default DischargeSummary