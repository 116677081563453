import React from 'react'
import './Notes.css'

function MedicationManagementNote({ setOpenModel }) {
  return (
    <div className="modal-container">
      {/* Modal Header */}
      <div className="modal-header">Medication Management Note</div>
      {/* Patient Information */}
      <div className="section">
        <div className="section-header">Patient Information</div>
        <div className="section-content">
          <label htmlFor="patient-name">Patient Name</label>
          <input
            type="text"
            id="patient-name"
            defaultValue="John Doe"
            readOnly=""
          />
          <label htmlFor="dob">Date of Birth</label>
          <input type="date" id="dob" defaultValue="1985-06-15" readOnly="" />
          <label htmlFor="visit-date">Date of Visit</label>
          <input
            type="date"
            id="visit-date"
            defaultValue="2025-01-21"
            readOnly=""
          />
          <label htmlFor="clinician-name">Clinician Name</label>
          <input
            type="text"
            id="clinician-name"
            defaultValue="Dr. Jane Smith"
            readOnly=""
          />
        </div>
      </div>
      {/* Current Medications */}
      <div className="section">
        <div className="section-header">Current Medications</div>
        <textarea
          id="current-medications"
          placeholder="List current medications and dosages..."
          defaultValue={""}
        />
      </div>
      {/* Medication Changes */}
      <div className="section">
        <div className="section-header">Medication Changes</div>
        <textarea
          id="medication-changes"
          placeholder="Document any medication adjustments or new prescriptions..."
          defaultValue={""}
        />
      </div>
      {/* Adverse Effects */}
      <div className="section">
        <div className="section-header">Adverse Effects</div>
        <textarea
          id="adverse-effects"
          placeholder="Note any side effects reported by the patient..."
          defaultValue={""}
        />
      </div>
      {/* Patient Compliance */}
      <div className="section">
        <div className="section-header">Patient Compliance</div>
        <textarea
          id="patient-compliance"
          placeholder="Describe the patient's adherence to the prescribed regimen..."
          defaultValue={""}
        />
      </div>
      {/* Plan for Future Adjustments */}
      <div className="section">
        <div className="section-header">Plan for Future Adjustments</div>
        <textarea
          id="future-adjustments"
          placeholder="Outline any plans for changes to the medication regimen..."
          defaultValue={""}
        />
      </div>
      {/* Signature Confirmation */}
      <div className="section">
        <div className="section-header">Signature Confirmation</div>
        <div className="section-content">
          <label>
            <input type="checkbox" id="signature-confirmation" />
            By checking this box, I confirm that I have reviewed this document.
          </label>
        </div>
      </div>
      {/* Save Button */}

      <div className='divBtn'>
        <button className="button" onclick="saveNote()">
          Save Note
        </button>

        <button className="buttonBack" onClick={() => setOpenModel(null)}>
          Back
        </button>
      </div>


    </div>

  )
}

export default MedicationManagementNote