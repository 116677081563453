import React, { useEffect, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DropDownManuCustom } from "../../Components/DropDownButton";
import {  AddProviderType, AddServiceBooking, DeleteProviderType, Deleteserviceaddress, DeleteServiceBooking, GetPlaceservice, GetProviderType, GetServiceBooking } from "../../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";
import { Box, Modal } from "@mui/material";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
});
function BookingServices() {
    const [showModal, setShowModal] = useState(false);
    const [addressData, setaddressData] = useState([])
    const profiledata = useSelector((state) => state?.cookies?.userDetails);

    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
   
    const onSubmit = async (data) => {
        data.agencyId = profiledata?._id; 
        try {
            const response = await AddServiceBooking(data);
            ShowToast(response?.data?.message);
            reset();
            FetchDatalist()
            setShowModal(false)
        } catch (error) {
            console.log(error);
        }

    };
    const handleDelete = async (id) => {
        console.log("Deleted ID:", id);

        const confirmed = window.confirm("Are you sure you want to delete ?");
        if (!confirmed) {
            return;
        }
       
        try {
            const response = await DeleteServiceBooking(`?id=${id}`);
            if (response?.data?.success) {
                ShowToast(response?.data?.message);
                FetchDatalist()
            } else {
                ShowToast("Error: Unable to delete address.");
            }
        } catch (error) {
            console.error("Delete operation failed:", error);
            ShowToast("An error occurred while deleting the address.");
        }
    };

  
    const FetchDatalist = async () => {
        try {
            const response = await GetServiceBooking(`?id=${profiledata?._id}`);
            console.log(response?.data?.data, "GetServiceBooking======");
            setaddressData(response?.data?.data || [])

        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        FetchDatalist()
    }, []);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "90vh",
        overflowY: "auto", // Set a fixed height for the modal
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 4,
        outline: "none",
        // Enable vertical scrolling
    };
    return (
        <div

            style={{
                width: "100%",
                alignItems: "center",
                justifyItems: "center",
                marginBottom: 20,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: '100%'
                }}
            >
                <h1>Booking Services</h1>
                <div
                    style={{
                        backgroundColor: "#bc9c22",
                        color: "white",
                        padding: 10,
                        borderRadius: 6,
                    }}
                    onClick={openModal}
                >
                    Add Booking Services
                </div>
            </div>


            <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr>
                            <th >Name</th>

                            <th >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addressData.length === 0 ? (
                            <tr>
                                <td colSpan="11" style={{ textAlign: "center", padding: "20px" }}>
                                    No Service found.
                                </td>
                            </tr>
                        ) : (
                            addressData.map((row, index) => (
                                <tr key={index} style={tableRowStyle}>
                                    <td style={tableCellStyle}>{row.name}</td>

                                    <td style={tableCellStyle}>
                                        <button onClick={() => handleDelete(row._id)} style={deleteButtonStyle}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <Modal open={showModal} onClose={closeModal} aria-labelledby="modal-title">

                <Box sx={modalStyle}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{
                            width: "100%",
                            alignItems: "center",
                            justifyItems: "center",
                            marginBottom: 20,
                        }}
                    >
                        <h1 style={{ textAlign: 'left' }}>New Booking Service</h1>
                        <div
                            style={{

                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Inputbox
                                label="Name"
                                type="text"
                                placeholder="Enter Name"
                                name="name"
                                register={register}
                            />
                            <p style={{ color: "red", textAlign: "left" }}>
                                {errors.name?.message}
                            </p>

                            <button
                                type="submit"
                                style={{ marginBottom: 50, alignSelf: "center" }}
                                className="Save_buttonnn"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

const tableHeaderStyle = {
    padding: "10px",
    borderBottom: "2px solid #ddd",
    textAlign: "left",
    fontWeight: "bold",
    backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
    padding: "8px",
    borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
    borderBottom: "1px solid #ddd",
};

const deleteButtonStyle = {
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    cursor: "pointer",
};


export default BookingServices