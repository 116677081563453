import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updateNotes, Getnotesdata, NotesSubmit } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import SOAPPsychiatricNote from "./notes/SOAPPsychiatricNote";
import MedicationManagementNote from "./notes/MedicationManagementNote";
import CrisisInterventionNote from "./notes/CrisisInterventionNote";
import DischargeSummary from "./notes/DischargeSummary";
import InitialPsychiatricIntakeNote from "./notes/InitialPsychiatricIntakeNote";

const validationSchema = Yup.object().shape({
  noteType: Yup.string().required("Please select a note type."),
  linkedAppointment: Yup.string().required(
    "Please select a linked appointment."
  ),

});
const NotesTabScreen = ({ info }) => {
  const [ismodal, setIsModal] = useState(false);
  const [Notesdata, setNotesdata] = useState([]);
  const [activeTab, setActiveTab] = useState("Active");
  const [noteType, setNoteType] = useState('');
  const [openModel, setOpenModel] = useState(null)

  const model = [
    "Progress Note", "Assessment Note", "Psychotherapy Note", "Treatment Plan Note",
    "Follow-Up Note", "Behavioral Analysis Note", "Cognitive Behavioral Therapy (CBT) Note", "Group Therapy Note",
    "Psychoeducation Note", "Couples/Family Therapy Note",
    "Trauma-Informed Care Note", "Substance Use Note", "Child/Adolescent Therapy Note",
    "Geriatric Care Note", "Cultural Competence Note",
    "Case Management Note", "Compliance Note", "Billing Note", "Referral Note", "Appointment Summary Note",
    "Symptom Tracking Note", "Incident Report Note", "Health Coaching Note", "Monitoring Note",
    "General Observation Note", "Client Communication Note", "Team Collaboration Note"
  ]

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const newTab = tab === "Active" ? false : true;
    Getnotes(newTab);
  };

  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    setIsModal(false);
    reset();
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data) => {
    // console.log("Form Data:", data);
    data.patientId = info?.patientId?._id;
    data.agencyId = info?.agencyId?._id;
    data.linkedAppointment = data.linkedAppointment === "None" ? null : data.linkedAppointment;
    try {
      const respo = await NotesSubmit(data);
      const newTab = activeTab === "Active" ? false : true;
      Getnotes(newTab);
      reset();
      ShowToast(respo?.data?.message);
      CloseModal();
    } catch (error) {
      console.log(error);
    }
  };
  const Getnotes = async (activeTab = false) => {
    try {
      const respo = await Getnotesdata(
        `?patientId=${info?.patientId?._id}&agencyId=${info?.agencyId?._id}&archived=${activeTab}`
      );
      setNotesdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleArchiveNotes = async (id, data) => {
    try {
      const formdata = {
        archived: data,
      };
      const respo = await updateNotes(id, formdata);
      const newTab = activeTab === "Active" ? false : true;
      Getnotes(newTab);
      ShowToast(`Note ${data ? "Archived" : "Restored"} Successfully`);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Getnotes();
  }, []);


  return (
    <>
      {openModel == "Medication Management Note" && <MedicationManagementNote setOpenModel={setOpenModel} />}
      {openModel == "SOAP Note" && <SOAPPsychiatricNote setOpenModel={setOpenModel} />}
      {openModel === 'Crisis Intervention Note' && <CrisisInterventionNote setOpenModel={setOpenModel} />}
      {openModel === 'Discharge Summary' && <DischargeSummary setOpenModel={setOpenModel} />}
      {openModel === 'Initial Psychiatric Intake Note' && <InitialPsychiatricIntakeNote setOpenModel={setOpenModel} />}



      {model.includes(openModel) && <div className="container_NotesTabScreen">
        <h2> {openModel} Screen not Availabel</h2>
        <button
          className="add-button_NotesTabScreen"
          style={{backgroundColor:"#e74c3c"}}
          onClick={() => setOpenModel(null)}
        >
          Back to note
        </button>
      </div>}


      {openModel === null &&
        <div className="container_NotesTabScreen">
          <h1>Notes</h1>
          {/* <div style={{ display: "flex" }}> */}
          <button
            className="add-button_NotesTabScreen"
            style={{ float: "right" }}
            onClick={openModal}
          >
            Add Note
          </button>
          {/* </div> */}
          {ismodal && (
            <div className="modal_AddAllergy" id="addNoteModal">
              <h2>Add Note</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group_NotesTabScreen">
                  <label htmlFor="noteType">Note Type</label>
                  <select id="noteType" {...register("noteType")}>
                    <option value="">Select note type</option>
                    <optgroup label="Clinical Notes">
                      <option value="Progress Note">Progress Note</option>
                      <option value="SOAP Note">SOAP Note</option>
                      <option value="Assessment Note">Assessment Note</option>
                      <option value="Initial Psychiatric Intake Note">
                        Initial Psychiatric Intake Note
                      </option>
                      <option value="Psychotherapy Note">Psychotherapy Note</option>
                      <option value="Discharge Summary">Discharge Summary</option>
                      <option value="Treatment Plan Note">
                        Treatment Plan Note
                      </option>
                      <option value="Crisis Intervention Note">
                        Crisis Intervention Note
                      </option>
                      <option value="Follow-Up Note">Follow-Up Note</option>
                      <option value="Medication Management Note">
                        Medication Management Note
                      </option>
                    </optgroup>
                    <optgroup label="Therapeutic Notes">
                      <option value="Behavioral Analysis Note">
                        Behavioral Analysis Note
                      </option>
                      <option value="Cognitive Behavioral Therapy (CBT) Note">
                        Cognitive Behavioral Therapy (CBT) Note
                      </option>
                      <option value="Group Therapy Note">Group Therapy Note</option>
                      <option value="Psychoeducation Note">
                        Psychoeducation Note
                      </option>
                      <option value="Couples/Family Therapy Note">
                        Couples/Family Therapy Note
                      </option>
                    </optgroup>
                    <optgroup label="Specialized Notes">
                      <option value="Trauma-Informed Care Note">
                        Trauma-Informed Care Note
                      </option>
                      <option value="Substance Use Note">Substance Use Note</option>
                      <option value="Child/Adolescent Therapy Note">
                        Child/Adolescent Therapy Note
                      </option>
                      <option value="Geriatric Care Note">
                        Geriatric Care Note
                      </option>
                      <option value="Cultural Competence Note">
                        Cultural Competence Note
                      </option>
                    </optgroup>
                    <optgroup label="Operational Notes">
                      <option value="Case Management Note">
                        Case Management Note
                      </option>
                      <option value="Compliance Note">Compliance Note</option>
                      <option value="Billing Note">Billing Note</option>
                      <option value="Referral Note">Referral Note</option>
                      <option value="Appointment Summary Note">
                        Appointment Summary Note
                      </option>
                    </optgroup>
                    <optgroup label="Tracking and Observation Notes">
                      <option value="Symptom Tracking Note">
                        Symptom Tracking Note
                      </option>
                      <option value="Incident Report Note">
                        Incident Report Note
                      </option>
                      <option value="Health Coaching Note">
                        Health Coaching Note
                      </option>
                      <option value="Monitoring Note">Monitoring Note</option>
                    </optgroup>
                    <optgroup label="General Notes">
                      <option value="General Observation Note">
                        General Observation Note
                      </option>
                      <option value="Client Communication Note">
                        Client Communication Note
                      </option>
                      <option value="Team Collaboration Note">
                        Team Collaboration Note
                      </option>
                    </optgroup>
                  </select>
                  {errors.noteType && (
                    <p className="error-message">{errors.noteType.message}</p>
                  )}
                </div>

                <div className="form-group_NotesTabScreen">
                  <label htmlFor="linkedAppointment">Linked Appointment</label>
                  <select id="linkedAppointment" {...register("linkedAppointment")}>
                    <option value="">Select an appointment</option>
                    <option value="None">None</option>
                  </select>
                  {errors.linkedAppointment && (
                    <p className="error-message">
                      {errors.linkedAppointment.message}
                    </p>
                  )}
                </div>


                <div className="modal-footer_NotesTabScreen">
                </div>
                <div className="modal-footer_NotesTabScreen">
                  <button
                    type="button"
                    className="cancel-button_NotesTabScreen"
                    onClick={CloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="save-button_NotesTabScreen"
                    style={{ marginLeft: 10 }}
                  >
                    Create Note
                  </button>
                </div>
              </form>
            </div>
          )}

          {/* <h2  style={{textAlign:'left',marginTop:15,fontSize:25}}>Notes</h2> */}

          <div
            className="tabs-container__three"
            style={{ display: "flex", width: "100%", justifyContent: "left" }}
          >
            <button
              className={`tab-button__two ${activeTab === "Active" ? "active-tab__two" : ""
                }`}
              onClick={() => handleTabClick("Active")}
            >
              Active
            </button>
            <button
              className={`tab-button__two ${activeTab === "Archived" ? "active-tab__two" : ""
                }`}
              onClick={() => handleTabClick("Archived")}
            >
              Archived
            </button>
          </div>

          <div className="notes_style">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Note Type</th>
                  <th>Linked Appointment</th>
                  <th>Author</th>
                  <th>Signature Status</th>
                  {/* <th>Content</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody id="noteTableBody">
                {Notesdata.length > 0 ? (
                  Notesdata.map((note) => (
                    <tr key={note._id}>
                      <td>{new Date(note.createdAt).toLocaleDateString()}</td>
                      <td>{note.noteType}</td>
                      <td>{note.linkedAppointment || "None"}</td>
                      <td>
                        {note.agencyId?.firstName} {note.agencyId?.lastName}
                      </td>
                      <td>{note.status}</td>
                      {/* <td>{note.content}</td> */}
                      <td>
                        <button onClick={() => setOpenModel(note.noteType)} >View</button>
                        <button style={{ marginLeft: 7 }}>Export</button>
                        <button
                          style={{ marginLeft: 7 }}
                          onClick={() => {
                            HandleArchiveNotes(note._id, !note.archived);
                          }}
                        >
                          {note.archived ? "Restore" : "Archive"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      No Notes Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      }



    </>


  );
};

export default NotesTabScreen;
