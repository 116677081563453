import React, { useState } from "react";

const AddAllergy = () => {
    const [ismodal, setIsModal] = useState(false);
    const openModal = () => {
        setIsModal(true); 
      };
      const CloseModal = () => {
        setIsModal(false); 
      };
  return (
    <div className="container_AddAllergy">
      <h1>Allergies</h1>
      <button className="add-button_AddAllergy"  onClick={() => openModal()}>
        Add Allergy
      </button>
      {ismodal && (
        <div className="modal_AddAllergy" id="addAllergyModal">
          <h2>Add Allergy</h2>
          <form onsubmit="saveAllergy(event)">
            <div className="form-row_AddAllergy">
              <div className="form-group_AddAllergy">
                <label htmlFor="allergy">Allergy</label>
                <input
                  type="text"
                  id="allergy"
                  placeholder="Search or enter allergy"
                  list="allergyList"
                  required=""
                />
                <datalist id="allergyList">
                  <option value="Peanuts"></option>
                  <option value="Shellfish"></option>
                  <option value="Pollen"></option>
                  <option value="Dust"></option>
                  <option value="Latex"></option>
                </datalist>
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="code">Code</label>
                <input type="text" id="code" placeholder="Enter code" />
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="recorded-date">Recorded Date</label>
                <input type="date" id="recorded-date" required="" />
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="clinical-status">Clinical Status</label>
                <select id="clinical-status" required="">
                  <option value="">Select clinical status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="verification-status">Verification Status</label>
                <select id="verification-status" required="">
                  <option value="">Select verification status</option>
                  <option value="Unconfirmed">Unconfirmed</option>
                  <option value="Confirmed">Confirmed</option>
                </select>
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="criticality">Criticality</label>
                <select id="criticality" required="">
                  <option value="">Select criticality</option>
                  <option value="Low">Low</option>
                  <option value="High">High</option>
                </select>
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="reaction-date">Last Reaction Date</label>
                <input type="date" id="reaction-date" />
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="reaction-kind">Reaction Kind</label>
                <input
                  type="text"
                  id="reaction-kind"
                  placeholder="Enter reaction kind"
                />
              </div>
              <div className="form-group_AddAllergy">
                <label htmlFor="reaction-severity">Reaction Severity</label>
                <select id="reaction-severity">
                  <option value="">Select severity</option>
                  <option value="Mild">Mild</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Severe">Severe</option>
                </select>
              </div>
              <div
                className="form-group_AddAllergy"
                style={{ flex: "1 1 100%" }}
              >
                <label htmlFor="management-measures">Management Measures</label>
                <textarea
                  id="management-measures"
                  placeholder="Enter management measures"
                  rows={2}
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="modal-footer_AddAllergy">
              <button className="cancel-button_AddAllergy" onClick={CloseModal}>
                Cancel
              </button>
              <button  className="save-button_AddAllergy" onClick={CloseModal}>
                Save
              </button>
            </div>
          </form>
        </div>
      )}
      <div className="allergy-list_AddAllergy">
        <h2>Allergy List</h2>
        <table>
          <thead>
            <tr>
              <th>Allergy</th>
              <th>Code</th>
              <th>Recorded Date</th>
              <th>Clinical Status</th>
              <th>Verification Status</th>
              <th>Criticality</th>
              <th>Last Reaction Date</th>
              <th>Reaction Kind</th>
              <th>Reaction Severity</th>
              <th>Management Measures</th>
            </tr>
          </thead>
          <tbody id="allergyTableBody">
            {/* Allergy rows will be added here dynamically */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddAllergy;
