import React, { useState } from "react";
import PatientSidebar from "./PatientSidebar";
import Navbar, { Navtopbar } from "../../Components/Navbar";
import Inputbox from "../../Components/Inputbox";
import DropDownButton from "../../Components/DropDownButton";
import { useNavigate } from "react-router-dom";
import SelectorBox from "./SelectorBox";

const PrivatePayPatient = () => {
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("workingHours");
  const handlenotification = () => {
    setActiveTab("SubscriptionPlan");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/SubscriptionPlan");
  };

  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <Navtopbar title={"Private Pay"} />
        <div
          className="dashboard-cards1"
          style={{ paddingLeft: 50, paddingRight: 50 }}
        >
          <div className="Private_display_div">
            <div className="Private_div_text">
              <p className="Private_Enter_text">Enter your card details</p>
              <div className="Private_display_div">
                <p className="Private_By_text">
                  By continuing you agree to our
                </p>
                <p className="Private_By_text" style={{ color: "#BC9C22" }}>
                  Terms & Conditions
                </p>
              </div>
            </div>
            <img
              className="Payment_Visa_image"
              src={require("../../assest/Images/Visa.png")}
            />
            <img
              className="Payment_Visa_image"
              src={require("../../assest/Images/Mastercard.png")}
            />
          </div>

          <div
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "25%",
              alignSelf: "flex-start",
              alignContent: "flex-start",
            }}
          >
            <SelectorBox />
          </div>

          <div className="space-div" style={{ width: "100%" }}>
            <div className="input-row" style={{ width: "100%", gap: "6%" }}>
              <Inputbox
                label="Card Holder Name"
                type="text"
                placeholder="Enter Name"
                name="firstName"
                style={{ backgroundColor: "#D9D9D94D" }}
              />
              <Inputbox
                type="text"
                label="Card Number"
                placeholder="**** **** **** 1234"
                name="lastName"
                style={{ backgroundColor: "#D9D9D94D" }}
                //   value={formData.lastName}
                //   onChange={handleChange}
              />
            </div>
          </div>
          <div className="space-div1" style={{ width: "100%" }}>
            <div className="input-row" style={{ width: "100%", gap: "6%" }}>
              <DropDownButton
                label="Exp Month"
                placeholder="12"
                dropdownicon
                //   style={{ width: "47%" }}
                divstyle={{ backgroundColor: "#D9D9D94D" }}
                options={[
                  "Benefit Management Inc.",
                  "Benefits Administration Services",
                  "Assurant (Cigna)",
                ]}
              />
              <DropDownButton
                label="Exp Year"
                placeholder="2026"
                dropdownicon
                //   style={{ width: "47%" }}
                divstyle={{ backgroundColor: "#D9D9D94D" }}
                options={[
                  "Benefit Management Inc.",
                  "Benefits Administration Services",
                  "Assurant (Cigna)",
                ]}
              />
              <Inputbox
                label="CVV"
                type="text"
                placeholder="123"
                name="firstName"
                style={{ backgroundColor: "#D9D9D94D" }}
              />
            </div>
          </div>

          <div className="div_display_button">
            <div style={{ marginTop: 20 }} className="inactive-tab12">
              Cancel
            </div>
            <div
              style={{ marginTop: 20 }}
              // className={
              // activeTab === "SubscriptionPlan" ? "active-tab12" : "inactive-tab12"
              // }
              // onClick={handlenotification}
              className="inactive-tab12"
              onClick={handleSignUp}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivatePayPatient;
